export default class Account {
  constructor(email, org, serial, password, start_at, end_at, info, entrance, lecture, venue, login, area, tab) {
    this.email = email;
    this.org = org;
    this.serial = serial;
    this.password = password;
    this.start_at = start_at;
    this.end_at = end_at;
    this.permission_info = {
      info: info,
      entrance: entrance,
      lecture: lecture,
      venue: venue,
      login: login,
      area: area,
      tab: tab,
    };
  }
}
