import axios from 'axios'
import router from '@/router'
import TokenService from './token.service'
// import Vue from 'vue'

// for multiple requests
let isRefreshing = false;
// 失敗執行序
let failedQueue = [];

// 序列化執行序
const processQueue = (error) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  })

  failedQueue = [];
}

// token換發機制
const setup = (store) => {

  // 全局設定 AJAX Response 攔截器 (interceptor)
  axios.interceptors.response.use(function (response) {
    return response
  }, async function (error) {

    // 請求沒有響應
    if (!error.response) {
      if (error.code === 'ECONNABORTED' && error.message && error.message.indexOf('timeout') !== -1) {
        // 連線逾時
        alert('網路連線逾時，請稍候再試')
      } else {
        // 連不上線
        alert('網路連線不穩定，請稍候再試')
      }
    }

    // 根據不同錯誤狀態 回應
    switch (error.response.status) {
      case 401:
        {
          // 若有多請求
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject })
            }).then(() => {
              return store.dispatch(error.response.config.actionUrl, error.response.config.actionPayload);
            }).catch(err => {
              return Promise.reject(err);
            })
          }

          isRefreshing = true;

          const refreshTokeUrl = `${process.env.VUE_APP_API}/api/manage/account/refresh-token`
          // 當不是 refresh token 作業發生 401 才需要更新 access token 並重發
          // 如果是就略過此刷新 access token 作業，直接不處理(因為 catch 已經攔截處理更新失敗的情況了)
          if (error.config.url !== refreshTokeUrl) {

            // const originalRequest = error.config
            const parameter = { refreshToken: JSON.parse(localStorage.getItem('user')).refreshToken }

            return new Promise(function (resolve, reject) {

              // 依據 refresh_token 刷新 access_token 並重發 request
              axios.post(refreshTokeUrl, parameter)
                // 更新 access_token 成功
                .then((response) => {

                  // 刷新 storage (其他呼叫 api 的地方都會從此處取得新 access_token)
                  updateToken(response)

                  // 重送 request (with new access_token)
                  processQueue(null, error.response.config.actionUrl);

                  // 承諾已重新發送
                  resolve(store.dispatch(error.response.config.actionUrl, error.response.config.actionPayload))
                  // return axios(originalRequest)
                })
                // 更新 access_token 失敗 (refresh_token 過期無效)
                .catch((err) => {
                  // 清空token
                  TokenService.removeUser()
                  // 跳至登入頁
                  router.push('/login')

                  processQueue(err, null)
                  reject(err)
                  // return Promise.reject(error)
                })
                .finally(() => {
                  isRefreshing = false
                })
            })
          }
        }
        break;

      case 404:
        {
          alert('該請求不存在')
        }
        break;
      
      case 419:
        {
          alert('短詩間內過多的請求，請稍後在嘗試')
        }
        break;

      case 500:
        {
          alert('refreah token 過期')
        }
        break;

      default:
        {
          // alert('default')
        }
    }


    return Promise.reject(error)
  })
}

function updateToken(response){
  let user = JSON.parse(localStorage.getItem('user'))
  user.accessToken = response.data.accessToken
  user.refreshToken = response.data.refreshToken

  TokenService.setUser(user)
}

export default setup;