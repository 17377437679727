import Vue from 'vue'
import VueRouter from 'vue-router'
import Other from '../views/Other.vue'
import Exhibitions from '../views/Exhibitions.vue'
import Exhibition from '../views/Exhibition.vue'
import Entrance from '../views/Entrance.vue'
import Profile from '../views/Profile.vue'
import Accounts from '../views/Accounts.vue'
import Account from '../views/Account.vue'
import Members from '../views/Members.vue'
import Menu from '../views/Menu.vue'
import Login from '../views/Login.vue'
import Lecture from '../views/Lecture.vue'
import Venue from '../views/Venue.vue'
import Area from '../views/Area.vue'
import ExhibitionLogin from '../views/ExhibitionLogin.vue'
import FbMessage from '../views/FbMessage.vue'
import Statistics from '../views/Statistics.vue'
import Outside from '../views/Outside.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      template: 'basic-layout',
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      template: 'basic-layout',
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      name: "會員中心",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "會員中心",
          disabled: true,
          href: "/profile",
        }
      ]
    }
  },
  {
    path: '/exhibition',
    name: 'Exhibitions',
    component: Exhibitions,
    meta: {
      name: "展會管理",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "展會管理",
          disabled: true,
          href: "#/exhibition",
        }
      ]
    }
  },
  {
    path: '/account',
    name: 'Accounts',
    component: Accounts,
    meta: {
      name: "帳號管理",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "帳號管理",
          disabled: true,
          href: "#/account",
        }
      ]
    }
  },
  {
    path: '/account/:id',
    name: 'Account',
    component: Account,
    meta: {
      name: "帳號資訊",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "帳號管理",
          disabled: false,
          href: "#/account",
        },
        {
          text: "帳號資訊",
          disabled: true,
          href: "#/account/:id",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id',
    name: 'Exhibition',
    component: Exhibition,
    meta: {
      name: "展會資訊",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "展會管理",
          disabled: false,
          href: "#/exhibition",
        },
        {
          text: "展會資訊",
          disabled: true,
          href: "#/exhibition/:id",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id/entrance',
    name: 'Entrance',
    component: Entrance,
    meta: {
      name: "入口大廳",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "入口大廳",
          disabled: true,
          href: "#/exhibition/:id/entrance",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id/lecture',
    name: 'Lecture',
    component: Lecture,
    meta: {
      name: "演講廳",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "演講廳",
          disabled: true,
          href: "#/exhibition/:id/lecture",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id/venue',
    name: 'Venue',
    component: Venue,
    meta: {
      name: "分會場",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "分會場",
          disabled: true,
          href: "#/exhibition/:id/venue",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id/area',
    name: 'Area',
    component: Area,
    meta: {
      name: "展示區",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "展示區",
          disabled: true,
          href: "#/exhibition/:id/area",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id/menu',
    name: 'Menu',
    component: Menu,
    meta: {
      name: "選單",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "選單",
          disabled: true,
          href: "#/exhibition/:id/menu",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id/login',
    name: 'ExhibitionLogin',
    component: ExhibitionLogin,
    meta: {
      name: "登入畫面",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "登入畫面",
          disabled: true,
          href: "#/exhibition/:id/login",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id/outside',
    name: 'Outside',
    component: Outside,
    meta: {
      name: "非會期畫面",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "非會期畫面",
          disabled: true,
          href: "#/exhibition/:id/outside",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id/fbMessage',
    name: 'FbMessage',
    component: FbMessage,
    meta: {
      name: "FBMessage設定",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "FBMessage設定",
          disabled: true,
          href: "#/exhibition/:id/fbMessage",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id/member',
    name: 'Member',
    component: Members,
    meta: {
      name: "名單管理",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "名單管理",
          disabled: true,
          href: "#/exhibition/:id/member",
        }
      ]
    }
  },
  {
    path: '/exhibition/:id/statistics',
    name: 'Statistics',
    component: Statistics,
    meta: {
      name: "數據分析",
      template: 'basic-layout',
      requiresAuth: true,
      breadcrumb: [
        {
          text: "數據分析",
          disabled: true,
          href: "#/exhibition/:id/statistics",
        }
      ]
    }
  },
  {
    path: '*',
    name: 'Other',
    component: Other
  },
]

const router = new VueRouter({
  routes
})

// 頁面驗證規則
router.beforeEach((to, from, next) => {
  // 如果所在頁面 再登入頁 且 有token =
  if(to.name == 'Login' && localStorage.getItem('user') !== null){
    // 跳轉至首頁
    next(`/${process.env.VUE_APP_HOME_ROUTE}`)
  }
  // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
  if (to.matched.some(record => {

    return record.meta.requiresAuth;
  })) {
    // 如果沒有 token 
    if(localStorage.getItem('user') == null){
      // 回登入頁
      next('/login')
    }
    
    next(); // 往下繼續執行

  } else {
    next(); // 往下繼續執行
  }
});


export default router
