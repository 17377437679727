<template>
  <component v-bind:is="layout"></component>
</template>

<script>
import EmptyLayout from "./components/layouts/empty";
import BasicLayout from "./components/layouts/basic";

export default {
  name: "App",
  components: {
    "empty-layout": EmptyLayout,
    "basic-layout": BasicLayout,
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/bootstrap-main/scss/bootstrap-utilities.scss";
@import "./assets/scss/all.scss";

</style>
