var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"buttonEntrancePage",attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8 justify-center"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"w-25 mx-auto"},[_c('div',{staticClass:"image-square pt-29"},[_c('img',{staticClass:"object-fit-cover cursor-pointer",attrs:{"src":_vm.data_.forum.cta.hover ? _vm.data_.forum.cta.imageActive : _vm.data_.forum.cta.image,"alt":_vm.data_.forum.cta.name},on:{"mouseover":function($event){_vm.data_.forum.cta.hover = true},"mouseleave":function($event){_vm.data_.forum.cta.hover = false}}})])]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v("建議尺寸600 x 168像素，PNG格式")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('validation-provider',{attrs:{"name":"按鈕樣式","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"按鈕樣式","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'basic';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 按鈕樣式 ")]},proxy:true}],null,true),model:{value:(_vm.data_.forum.cta.image),callback:function ($$v) {_vm.$set(_vm.data_.forum.cta, "image", $$v)},expression:"data_.forum.cta.image"}})]}}])}),_c('validation-provider',{attrs:{"name":"按鈕樣式(滑入)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"按鈕樣式(滑入)","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'hover';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 按鈕樣式(滑入) ")]},proxy:true}],null,true),model:{value:(_vm.data_.forum.cta.imageActive),callback:function ($$v) {_vm.$set(_vm.data_.forum.cta, "imageActive", $$v)},expression:"data_.forum.cta.imageActive"}})]}}])}),_c('v-select',{attrs:{"items":_vm.linkType,"item-text":"name","item-value":"value","label":"連結","outlined":"","dense":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),(_vm.type == 'order')?_c('validation-provider',{attrs:{"name":"自訂連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"自訂連結","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 自訂連結 ")]},proxy:true}],null,true),model:{value:(_vm.data_.forum.cta.url),callback:function ($$v) {_vm.$set(_vm.data_.forum.cta, "url", $$v)},expression:"data_.forum.cta.url"}})]}}],null,false,1698761791)}):_vm._e()],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.resetData}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cached ")]),_vm._v(" 復原 ")],1),_c('v-btn',{attrs:{"target":"_blank","href":_vm.queck_token}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-card-search ")]),_vm._v(" 預覽 ")],1),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 儲存 ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }