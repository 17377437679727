<template>
  <div class="Venue">
    <v-alert
      prominent
      v-if="user.permission_info && !user.permission_info.venue"
      type="warning"
    >您的權限不足</v-alert>
    <v-card left v-else>
      <v-tabs v-model="tab" class="border-bottom px-3">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Video :data="data" v-if="data.background" @update="update"></Video>
        </v-tab-item>
        <v-tab-item>
          <Banner :data="data" v-if="data.background" @update="update"></Banner>
        </v-tab-item>
        <v-tab-item>
          <Point :data="data" v-if="data.background" @update="update"></Point>
        </v-tab-item>
        <v-tab-item>
          <Background :data="data" v-if="data.background" @update="update"></Background>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <MediaModal :dialog.sync="$store.state.mediaModal"></MediaModal>
  </div>
</template>

<script>
import MediaModal from "@/components/modals/media.vue";
import Video from "@/components/pages/venue/video.vue";
import Banner from "@/components/pages/venue/banner.vue";
import Point from "@/components/pages/venue/point.vue";
import Background from "@/components/pages/venue/background.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "Venue",
  components: {
    MediaModal,
    Video,
    Banner,
    Point,
    Background
  },
  data() {
    return {
      tab: null,
      items: ["影片", "Banner", "集點", "背景"],
      id: this.$route.params.id,
    };
  },
  methods: {
    update() {
      this.getVenue({ id: this.id });
    },
    ...mapActions("venue", ["getVenue"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    this.getVenue({ id: this.id });
  },
  computed: {
    ...mapState({
      data: (state) => state.venue.data,
      user: (state) => state.user.data,
    }),
  },
};
</script>