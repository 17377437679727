<template>
  <div class="carouselCreateModal">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 新增輪播 </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <v-col cols="12 text-center">
                  <img class="mw-100 h-auto w-50" :src="slider.poster" alt="" v-if="slider.poster"/>
                </v-col>
                <v-col cols="12">
                  <!-- <v-select
                    v-model="slider.type"
                    :items="type"
                    item-text="name"
                    item-value="value"
                    label="內容類型"
                    outlined
                    dense
                  ></v-select> -->
                  <div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="圖片連結"
                      rules="required"
                    >
                      <v-text-field
                        v-model="slider.poster"
                        :error-messages="errors"
                        type="text"
                        placeholder="圖片連結"
                        outlined
                        dense
                        clearable
                        append-icon="mdi-folder-multiple-image"
                        @click:append="SET_MEDIA_MODAL(true);selected = 'slider'"
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          圖片連結
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div v-if="slider.type == 'video'">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Youtube連結"
                      rules="required"
                    >
                      <v-text-field
                        v-model="slider.url"
                        :error-messages="errors"
                        type="text"
                        placeholder="影片連結"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          影片連結
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div v-if="slider.type == 'iframe'">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Youtube連結"
                      rules="required"
                    >
                      <v-text-field
                        v-model="slider.url"
                        :error-messages="errors"
                        type="text"
                        placeholder="https://www.youtube.com/embed/*"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          Youtube連結
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div>
                    <video
                      class="w-50 d-block mx-auto"
                      v-if="slider.type === 'video'"
                      :src="slider.url"
                      :poster="slider.poster"
                      controls
                      loop
                      playsinline
                      muted
                    />
                    <v-img
                      class="w-50 d-block mx-auto"
                      v-else-if="slider.type === 'image'"
                      :src="slider.url"
                    />
                    <iframe
                      class="w-50 d-block mx-auto"
                      v-else-if="slider.type === 'iframe'"
                      :src="slider.url"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="validateForm"
            color="primary"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            <v-icon left dark> mdi-content-save </v-icon>
            新增
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "carouselCreateModal",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      type: [
        {
          name: "圖片",
          value: "image",
        },
        {
          name: "影片",
          value: "video",
        },
        {
          name: "Youtube影片",
          value: "iframe",
        },
      ],
      slider: {
        type: "image",
        name: "",
        url: "",
        poster: "",
      },
      selected: ''
    };
  },
  methods: {
    async validateForm() {
      const valid = await this.$refs.observer.validate();

      if (valid) {
        this.$emit("createData", this.slider);
        this.isDialog = false;
        this.resetData()
      }
    },
    resetData() {
      this.slider = {
        type: "image",
        name: "",
        url: "",
        poster: "",
      }
      // 清空驗證錯誤訊息
      this.$refs.observer.reset()
    },
    ...mapMutations(["SET_MEDIA_MODAL"]),
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  watch: {
    "this.slider.type": () => {
      this.slider.url = "";
    },
    "$store.state.media.selected": function () {
      switch(this.selected){
        case 'slider': {
          this.slider.poster = this.$store.state.media.selected.link;
        }
      }
    },
  },
};
</script>
