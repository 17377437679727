<template>
  <div class="stallAreaCreateModal">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 新增攤位 </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <v-col cols="12" class="py-0">
                  <div
                    class="mx-auto mb-4 w-50 position-relative"
                    v-if="area.stall_img"
                  >
                    <p class="mb-0">桌機</p>
                    <div class="image-square pt-67">
                      <img
                        class="object-fit-cover"
                        :src="area.stall_img"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    class="mx-auto mb-4 w-50 position-relative"
                    v-if="area.logo"
                  >
                    <p class="mb-0">手機</p>
                    <div class="image-square pt-67">
                      <img class="object-fit-cover" :src="area.logo" alt="" />
                    </div>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="攤位名稱"
                    rules="required"
                  >
                    <v-text-field
                      v-model="area.name"
                      :error-messages="errors"
                      type="text"
                      placeholder="攤位名稱"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        攤位名稱
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <v-textarea
                    v-model="area.text"
                    clearable
                    dense
                    placeholder="攤位描述"
                    label="攤位描述"
                    auto-grow
                    outlined
                    clear-icon="mdi-close-circle"
                    rows="4"
                  ></v-textarea>

                  <!-- <validation-provider v-slot="{ errors }" name="攤位描述">
                    <v-text-field
                      v-model="area.text"
                      :error-messages="errors"
                      type="text"
                      placeholder="攤位描述"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label> 攤位描述 </template>
                    </v-text-field>
                  </validation-provider> -->

                  <validation-provider v-slot="{ errors }" name="攤位連結">
                    <v-text-field
                      v-model="area.url"
                      :error-messages="errors"
                      type="text"
                      placeholder="攤位連結"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label> 攤位連結 </template>
                    </v-text-field>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="攤位圖片連結"
                    rules="required"
                  >
                    <v-text-field
                      v-model="area.stall_img"
                      :error-messages="errors"
                      type="text"
                      placeholder="攤位圖片連結"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'stall';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        攤位圖片連結
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="攤位圖片(手機)連結"
                    rules="required"
                  >
                    <v-text-field
                      v-model="area.logo"
                      :error-messages="errors"
                      type="text"
                      placeholder="攤位圖片(手機)連結"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'logo';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        攤位圖片(手機)連結
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="validateForm"
            color="primary"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            <v-icon left dark> mdi-content-save </v-icon>
            新增
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "stallAreaCreateModal",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      area: {
        name: "",
        text: "",
        url: "",
        stall_img: "",
        logo: "",
      },
    };
  },
  methods: {
    async validateForm() {
      const valid = await this.$refs.observer.validate();

      if (valid) {
        this.$emit("createData", this.area);
        this.isDialog = false;
        this.resetData()
      }
    },
    resetData() {
      this.area = {
        name: "",
        text: "",
        url: "",
        stall_img: "",
        logo: "",
      }
      // 清空驗證錯誤訊息
      this.$refs.observer.reset()
    },
    ...mapMutations(["SET_MEDIA_MODAL"]),
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  watch: {
    "$store.state.media.selected": function() {
      switch(this.selected){
        case 'stall': {
          this.area.stall_img = this.$store.state.media.selected.link;
          return;
        }
        case 'logo': {
          this.area.logo = this.$store.state.media.selected.link;
          return;
        }
      }
    }
  },
};
</script>

<style scoped>
.pt-67 {
  padding-top: 67%;
}
</style>