<template>
  <v-snackbar :timeout="2000" :vertical="true" v-model="snackbar.show">
    <template v-for="(item, key) in this.$store.state.snackbar.text">
      <p :key="key" class="mb-0">
        <v-icon small class="mr-2">mdi-alert-circle-outline</v-icon>{{ item }}<br>
      </p>
    </template>
    <template v-slot:action="{ attrs }">
      <v-btn color="primary" text v-bind="attrs" @click="close()">
        知道了
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "snackbar",
  methods: {
    close() {
      this.$store.dispatch('setSnackbar',{show: false , text: ''})
    }
  },
  computed: {
    ...mapGetters(['snackbar'])
  }
}
</script>