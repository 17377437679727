var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lectureMarqueeCreateModal"},[_c('v-dialog',{attrs:{"max-width":"560","scrollable":""},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 新增跑馬燈 ")]),_c('v-divider'),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"跑馬燈文字","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"跑馬燈文字","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 跑馬燈文字 ")]},proxy:true}],null,true),model:{value:(_vm.marquee.text),callback:function ($$v) {_vm.$set(_vm.marquee, "text", $$v)},expression:"marquee.text"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"連結","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"連結","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 連結 ")]},proxy:true}],null,true),model:{value:(_vm.marquee.url),callback:function ($$v) {_vm.$set(_vm.marquee, "url", $$v)},expression:"marquee.url"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-dialog',{ref:"strDatePickerDialog",attrs:{"return-value":_vm.strDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.strDate=$event},"update:return-value":function($event){_vm.strDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"開始日期","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","error-messages":errors,"readonly":"","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 開始日期 ")]},proxy:true}],null,true),model:{value:(_vm.strDate),callback:function ($$v) {_vm.strDate=$$v},expression:"strDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.strDateModal),callback:function ($$v) {_vm.strDateModal=$$v},expression:"strDateModal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.strDate),callback:function ($$v) {_vm.strDate=$$v},expression:"strDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.strDateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.strDatePickerDialog.save(_vm.strDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"開始時間","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"開始時間","type":"time","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.strTime),callback:function ($$v) {_vm.strTime=$$v},expression:"strTime"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"結束時間","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"結束時間","type":"time","min":"05:00","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 新增 ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }