<template>
  <div class="FbMessage">
    <v-card left>
      <v-card-text>
        <p>展會網址：{{data_.frontend_url}}</p>
        <validation-observer ref="observer">
          <v-form @submit.prevent="validateForm">
            <v-row class="py-8 justify-center">
              <v-col cols="12" md="7" class="text-center">
                <validation-provider
                  v-slot="{ errors }"
                  name="語法"
                  rules=""
                >
                  <v-textarea
                    class="js-textarea"
                    outlined
                    rows="15"
                    name="語法"
                    label="語法"
                    auto-grow
                    :error-messages="errors"
                    v-model="data_.asset"
                  >
                    <template v-slot:label>
                      <v-icon x-small color="error" class="align-middle"
                        >mdi-asterisk</v-icon
                      >
                      語法
                    </template>
                  </v-textarea>
                </validation-provider>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="resetData">
          <v-icon left dark> mdi-cached </v-icon>
          復原
        </v-btn>
        <v-btn target="_blank" :href="queck_token">
          <v-icon left dark> mdi-card-search </v-icon>
          預覽
        </v-btn>
        <v-btn
          @click="validateForm"
          color="primary"
          :loading="this.$store.state.partialLoding"
          :disabled="this.$store.state.partialLoding"
        >
          <v-icon left dark> mdi-content-save </v-icon>
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "FbMessage",
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      data_: {},
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    resetData() {
      this.getExhibition({ id: this.id })
    },
    update() {
      const vm = this
      let parameter = {
        id: this.id,
        data: this.data_
      }
      
      vm.updateExhibition(parameter).then(() => {
        vm.resetData();
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
    ...mapActions("exhibition", ["getExhibition"]),
    ...mapActions("updateExhibition", ["updateExhibition"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    this.getExhibition({ id: this.id })
  },
  computed: {
    ...mapState({
      queck_token: (state) => {
        return state.exhibition.data.custom_backend
          ? `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`
          : "";
      },
      data: (state) => state.exhibition.data
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
  }
};
</script>