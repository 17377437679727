<template>
  <div class="membersDeleteModal">
    <v-dialog v-model="isDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> 確定刪除名單嗎？ </v-card-title>
        <v-card-text>
          {{ item.email }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="isDialog = false"> 取消 </v-btn>

          <v-btn
            color="error"
            @click="deleteSubmit"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            刪除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "membersDeleteModal",
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  props: {
    dialog: Boolean,
    item: {},
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  methods: {
    deleteSubmit() {
      this.deleteMember({ id: this.id, member: this.item.id }).then(() => {
        this.successTodo();
      });
    },
    successTodo() {
      this.isDialog = false;
      this.$emit("updateData");
      this.SET_SUCCESS_SNACKBAR({
        show: true,
        text: `成功刪除 ${this.item.email}`,
      });
    },
    ...mapActions("deleteMember", ["deleteMember"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
};
</script>