var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lectureScreenUpdateModal"},[_c('v-dialog',{attrs:{"max-width":"560","scrollable":""},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 編輯影片 ")]),_c('v-divider'),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[(_vm.screen.url)?_c('div',{staticClass:"pt-4"},[(_vm.screen.type === 'video')?_c('video',{staticClass:"w-50 d-block mx-auto",attrs:{"src":_vm.screen.url,"poster":_vm.screen.poster,"controls":"","loop":"","playsinline":"","muted":""},domProps:{"muted":true}}):(_vm.screen.type === 'iframe')?_c('iframe',{staticClass:"w-50 d-block mx-auto",attrs:{"src":_vm._f("youtubeUrl")(_vm.screen.url),"allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e()]):_vm._e(),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('div',[_c('validation-provider',{attrs:{"name":"影片名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"影片名稱","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 影片名稱 ")]},proxy:true}],null,true),model:{value:(_vm.screen.name),callback:function ($$v) {_vm.$set(_vm.screen, "name", $$v)},expression:"screen.name"}})]}}])})],1),_c('v-select',{attrs:{"items":_vm.type,"item-text":"name","item-value":"value","label":"內容類型","outlined":"","dense":""},model:{value:(_vm.screen.type),callback:function ($$v) {_vm.$set(_vm.screen, "type", $$v)},expression:"screen.type"}}),_c('div',[_c('validation-provider',{attrs:{"name":"縮圖連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"縮圖連結","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                        _vm.selected = 'poster';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 縮圖連結 ")]},proxy:true}],null,true),model:{value:(_vm.screen.poster),callback:function ($$v) {_vm.$set(_vm.screen, "poster", $$v)},expression:"screen.poster"}})]}}])})],1),(_vm.screen.type == 'video')?_c('div',[_c('validation-provider',{attrs:{"name":"Youtube連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"影片連結","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 影片連結 ")]},proxy:true}],null,true),model:{value:(_vm.screen.url),callback:function ($$v) {_vm.$set(_vm.screen, "url", $$v)},expression:"screen.url"}})]}}],null,false,170040137)})],1):_vm._e(),(_vm.screen.type == 'iframe')?_c('div',[_c('validation-provider',{attrs:{"name":"Youtube連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"https://www.youtube.com/embed/*","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" Youtube連結 ")]},proxy:true}],null,true),model:{value:(_vm.screen.url),callback:function ($$v) {_vm.$set(_vm.screen, "url", $$v)},expression:"screen.url"}})]}}],null,false,628212828)}),_c('v-switch',{staticClass:"mt-0 ms-2",attrs:{"inset":"","label":'自動播放'},model:{value:(_vm.youtubeAutoPlay),callback:function ($$v) {_vm.youtubeAutoPlay=$$v},expression:"youtubeAutoPlay"}})],1):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-dialog',{ref:"strDatePickerDialog",attrs:{"return-value":_vm.strDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.strDate=$event},"update:return-value":function($event){_vm.strDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"開始日期","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","error-messages":errors,"readonly":"","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 開始日期 ")]},proxy:true}],null,true),model:{value:(_vm.strDate),callback:function ($$v) {_vm.strDate=$$v},expression:"strDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.strDateModal),callback:function ($$v) {_vm.strDateModal=$$v},expression:"strDateModal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.strDate),callback:function ($$v) {_vm.strDate=$$v},expression:"strDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.strDateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.strDatePickerDialog.save(_vm.strDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"開始時間","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"開始時間","type":"time","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.strTime),callback:function ($$v) {_vm.strTime=$$v},expression:"strTime"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"結束時間","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"結束時間","type":"time","min":"05:00","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 儲存 ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }