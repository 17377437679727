<template>
  <div class="categoryVenueUpdateModal">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 編輯主題 </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="pt-8">
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="主題名稱"
                    rules="required"
                  >
                    <v-text-field
                      v-model="video.theme_title"
                      :error-messages="errors"
                      type="text"
                      placeholder="主題名稱"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        主題名稱
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="validateForm"
            color="primary"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            <v-icon left dark> mdi-content-save </v-icon>
            儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "categoryVenueUpdateModal",
  props: {
    dialog: Boolean,
    data: {},
    id: {}
  },
  data() {
    return {
      video: {
        theme_title: "",
        video_data: [],
      },
    };
  },
  methods: {
    async validateForm() {
      const valid = await this.$refs.observer.validate();

      if (valid) {
        this.$emit("updateData", this.id, this.video);
        this.isDialog = false;
        this.clearData()
      }
    },
    clearData() {
      this.video.theme_title = ''
      this.video.video_data = []
      // 清空驗證錯誤訊息
      this.$refs.observer.reset()
    }
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  watch: {
    data() {
      this.video = JSON.parse(JSON.stringify(this.data));
    },
  }
};
</script>