<template>
  <v-app>
    <v-main class="">
      <router-view></router-view>
    </v-main>
    <Footer></Footer>
    <snackbar></snackbar>
  </v-app>
</template>

<script>
import Footer from "@/components/layouts/footers/basic.vue";
import snackbar from "@/components/snackbars/basic.vue"

export default {
  name: "Empty-layout",
  components: {
    Footer,
    snackbar
  }
};
</script>