<template>
  <v-navigation-drawer permanent app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">線上展會管理平台</v-list-item-title>
        <!-- <v-list-item-subtitle> user@gmail.com </v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list>
      <v-list-item-group mandatory color="primary">
        <template v-for="item in (this.$store.state.user.data.permission == 99 ? items : itemsBasic)">
          <v-list-item :to="item.link" :key="item.title">
            <v-list-item-icon>
              <v-icon v-text="item.action"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          action: "mdi-format-list-bulleted",
          title: "展會管理",
          link: "/exhibition",
        },
        {
          action: "mdi-account-details",
          title: "帳號管理",
          link: "/account",
        },
        {
          action: "mdi-account",
          title: "會員中心",
          link: "/profile",
        },
      ],
      itemsBasic: [
        {
          action: "mdi-format-list-bulleted",
          title: "展會管理",
          link: "/exhibition",
        },
        {
          action: "mdi-account",
          title: "會員中心",
          link: "/profile",
        },
      ],
    }
  },
};
</script>
