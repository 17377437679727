<template>
  <div class="videoVenuePlayerModal">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <video
          v-if="video.video_type == 'video'"
          :src="video.video_url"
          controls
          loop
          playsinline
          muted
        />
        <iframe
          v-if="video.video_type == 'iframe'"
          :src="video.video_url"
          class="od-video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "videoVenuePlayerModal",
  props: {
    dialog: Boolean,
    video: {},
  },
  methods: {},
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  mounted() {},
  watch: {
    // isDialog: function() {
    //   if(this.isDialog == false){
    //     this.video = ''
    //   }
    // }
  },
};
</script>

<style scoped>
.od-video{
  min-height: 300px;
}
.od-btn{
  top: 20px;
  right: 0
}
</style>