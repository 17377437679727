var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"messageEntrancePage"},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8 justify-center"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"mx-auto d-flex od_message"},[_c('img',{staticClass:"mw-100 h-auto w-50",attrs:{"src":_vm.data_.greeting.image,"alt":""}}),_c('div',{staticClass:"od_message__box"},[_c('span',{staticClass:"od_message__text"},[_vm._v(" "+_vm._s(_vm.data_.greeting.wording)+" ")])])]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v("建議尺寸500 x 320 像素，PNG格式")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('validation-provider',{attrs:{"name":"圖片","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"圖片","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'message';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 圖片 ")]},proxy:true}],null,true),model:{value:(_vm.data_.greeting.image),callback:function ($$v) {_vm.$set(_vm.data_.greeting, "image", $$v)},expression:"data_.greeting.image"}})]}}])}),_c('div',{staticClass:"text-right"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" 插入參數 ")])]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){$event.stopPropagation();return _vm.addParameter(item.name)}}},[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('validation-provider',{attrs:{"name":"問候語","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-textarea',{staticClass:"js-textarea",attrs:{"outlined":"","rows":"3","maxlength":"25","counter":"25","name":"問候語","label":"問候語","auto-grow":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 問候語 ")]},proxy:true}],null,true),model:{value:(_vm.data_.greeting.wording),callback:function ($$v) {_vm.$set(_vm.data_.greeting, "wording", $$v)},expression:"data_.greeting.wording"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.resetData}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cached ")]),_vm._v(" 復原 ")],1),_c('v-btn',{attrs:{"target":"_blank","href":_vm.queck_token}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-card-search ")]),_vm._v(" 預覽 ")],1),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 儲存 ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }