import Axios from 'axios'

export default {
  namespaced: true,
  strict: true,
  state: {
    user: {}
  },
  getters: {
  },
  actions: {
    updateUser(context, payload = null) {
      let api = `${process.env.VUE_APP_API}/api/manage/account`
      const config = {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem('user')).accessToken,
        },
        actionUrl: 'updateUser/updateUser',
        actionPayload: payload
      }

      const parameter = payload.data;

      context.commit('SET_PARTIAL_LOADING', true, { root: true })

      return new Promise((resolve, reject) => {
        Axios.post(api, parameter, config)
          .then((response) => {
            context.commit('SET_PARTIAL_LOADING', false, { root: true })
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status !== 401) {
              if (error.response.status == 400) {
                context.commit('SET_SNACKBAR', {
                  show: true,
                  text: error.response.data
                }, { root: true })
              }

              context.commit('SET_PARTIAL_LOADING', false, { root: true })
            }
            reject(error);
          });
      });
    },
  },
  mutations: {
  },
  modules: {
  }
}