<template>
  <div class="">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 建立帳號 </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="組織"
                    rules="required|max:20"
                  >
                    <v-text-field
                      v-model="data.org"
                      :error-messages="errors"
                      type="text"
                      placeholder="組織"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        組織
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider v-slot="{ errors }" name="統一編號">
                    <v-text-field
                      v-model="data.serial"
                      :error-messages="errors"
                      type="text"
                      placeholder="統一編號"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label> 統一編號 </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="帳號"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data.email"
                      :error-messages="errors"
                      type=""
                      placeholder="帳號"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        帳號
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="密碼"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data.password"
                      :error-messages="errors"
                      type="password"
                      placeholder="密碼"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        密碼
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="2" class="py-0 pt-2">啟用日期</v-col>
                <v-col cols="5" class="py-0">
                  <v-dialog
                    ref="strDatePickerDialog"
                    v-model="strDateModal"
                    :return-value.sync="data.start_at"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="開始"
                        rules="required"
                      >
                        <v-text-field
                          v-model="data.start_at"
                          append-icon="mdi-calendar"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            開始
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker v-model="data.start_at" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="strDateModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.strDatePickerDialog.save(data.start_at)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="5" class="py-0">
                  <v-dialog
                    ref="endDatePickerDialog"
                    v-model="endDateModal"
                    :return-value.sync="data.end_at"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="結束"
                        rules="required"
                      >
                        <v-text-field
                          v-model="data.end_at"
                          append-icon="mdi-calendar"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            結束
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="data.end_at"
                      scrollable
                      :min="data.start_at"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endDateModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endDatePickerDialog.save(data.end_at)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <!-- <v-col cols="6" class="py-0">
                  <v-switch
                    class="px-2"
                    v-model="data.permission_info.info"
                    inset
                    :label="`資訊管理： ${data.permission_info.info ? '啟用' : '停用'}`"
                  >
                  </v-switch>
                </v-col> -->
                <v-col cols="6" class="py-0">
                  <v-switch
                    class="px-2"
                    v-model="data.permission_info.entrance"
                    inset
                    :label="`入口大廳管理： ${
                      data.permission_info.entrance ? '啟用' : '停用'
                    }`"
                  >
                  </v-switch>
                </v-col>

                <v-col cols="6" class="py-0">
                  <v-switch
                    class="px-2"
                    v-model="data.permission_info.lecture"
                    inset
                    :label="`演講廳管理： ${
                      data.permission_info.lecture ? '啟用' : '停用'
                    }`"
                  >
                  </v-switch>
                </v-col>

                <v-col cols="6" class="py-0">
                  <v-switch
                    class="px-2"
                    v-model="data.permission_info.tab"
                    inset
                    :label="`選單管理： ${
                      data.permission_info.tab ? '啟用' : '停用'
                    }`"
                  >
                  </v-switch>
                </v-col>

                <v-col cols="6" class="py-0">
                  <v-switch
                    class="px-2"
                    v-model="data.permission_info.venue"
                    inset
                    :label="`分會場管理： ${
                      data.permission_info.venue ? '啟用' : '停用'
                    }`"
                  >
                  </v-switch>
                </v-col>

                <v-col cols="6" class="py-0">
                  <v-switch
                    class="px-2"
                    v-model="data.permission_info.login"
                    inset
                    :label="`登入畫面管理： ${
                      data.permission_info.login ? '啟用' : '停用'
                    }`"
                  >
                  </v-switch>
                </v-col>

                <v-col cols="6" class="py-0">
                  <v-switch
                    class="px-2"
                    v-model="data.permission_info.area"
                    inset
                    :label="`展示區管理： ${
                      data.permission_info.area ? '啟用' : '停用'
                    }`"
                  >
                  </v-switch>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="isDialog = false"> 取消 </v-btn>
          <v-btn
            color="primary"
            @click="validateForm"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            建立
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import AccountModel from "@/models/account";

export default {
  name: "accountsCreateModal",
  data() {
    return {
      data: new AccountModel(
        "",
        "",
        "",
        "",
        "",
        "",
        true,
        false,
        false,
        false,
        false,
        false,
        false
      ),
      strDateModal: false,
      endDateModal: false,
    };
  },
  props: {
    dialog: Boolean,
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.createAccount(vm.data).then((response) => {
          vm.successTodo(response);
        });
      }
    },
    successTodo(res) {
      this.isDialog = false;
      this.SET_SUCCESS_SNACKBAR({
        show: true,
        text: `成功創建帳號 ${res.data.org}`,
      });
      this.$emit("updateData");
      this.resetInput();
    },
    resetInput() {
      (this.data = new AccountModel(
        "",
        "",
        "",
        "",
        "",
        "",
        true,
        false,
        false,
        false,
        false,
        false,
        false
      )),
        this.$refs.observer.reset();
    },
    ...mapActions("createAccount", ["createAccount"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
};
</script>