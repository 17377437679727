var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"videoVenueCreateModal"},[_c('v-dialog',{attrs:{"max-width":"560","scrollable":""},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 新增影片至"+_vm._s(_vm.category.theme_title)+" ")]),_c('v-divider'),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(_vm.video.video_data.video_url || _vm.youtube)?_c('div',{staticClass:"py-4"},[(_vm.video.video_data.video_type === 'video')?_c('video',{staticClass:"w-50 d-block mx-auto",attrs:{"src":_vm.video.video_data.video_url,"controls":"","loop":"","autoplay":"","playsinline":"","muted":""},domProps:{"muted":true}}):(_vm.video.video_data.video_type === 'iframe')?_c('iframe',{staticClass:"w-50 d-block mx-auto",attrs:{"src":_vm._f("youtubeUrl")(_vm.youtube),"allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e()]):_vm._e(),_c('validation-provider',{attrs:{"name":"影片名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"影片名稱","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 影片名稱 ")]},proxy:true}],null,true),model:{value:(_vm.video.video_data.video_title),callback:function ($$v) {_vm.$set(_vm.video.video_data, "video_title", $$v)},expression:"video.video_data.video_title"}})]}}])}),_c('v-select',{attrs:{"items":_vm.type,"item-text":"name","item-value":"value","label":"內容類型","outlined":"","dense":""},model:{value:(_vm.video.video_data.video_type),callback:function ($$v) {_vm.$set(_vm.video.video_data, "video_type", $$v)},expression:"video.video_data.video_type"}}),(_vm.video.video_data.video_type == 'video')?_c('div',[_c('validation-provider',{attrs:{"name":"影片連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"影片連結","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 影片連結 ")]},proxy:true}],null,true),model:{value:(_vm.video.video_data.video_url),callback:function ($$v) {_vm.$set(_vm.video.video_data, "video_url", $$v)},expression:"video.video_data.video_url"}})]}}],null,false,3853919690)})],1):_vm._e(),(_vm.video.video_data.video_type == 'iframe')?_c('div',[_c('validation-provider',{attrs:{"name":"Youtube連結key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"Youtube連結key","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" Youtube連結key ")]},proxy:true}],null,true),model:{value:(_vm.youtube),callback:function ($$v) {_vm.youtube=$$v},expression:"youtube"}})]}}],null,false,1102041169)}),_c('v-switch',{staticClass:"mt-0 ms-2",attrs:{"inset":"","label":'自動播放'},model:{value:(_vm.youtubeAutoPlay),callback:function ($$v) {_vm.youtubeAutoPlay=$$v},expression:"youtubeAutoPlay"}})],1):_vm._e(),_c('validation-provider',{attrs:{"name":"縮圖連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"縮圖連結","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                      _vm.selected = 'video';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 縮圖連結 ")]},proxy:true}],null,true),model:{value:(_vm.video.video_data.video_banner),callback:function ($$v) {_vm.$set(_vm.video.video_data, "video_banner", $$v)},expression:"video.video_data.video_banner"}})]}}])}),_c('validation-provider',{attrs:{"name":"影片敘述"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"影片敘述","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 影片敘述 ")]},proxy:true}],null,true),model:{value:(_vm.video.video_data.video_description),callback:function ($$v) {_vm.$set(_vm.video.video_data, "video_description", $$v)},expression:"video.video_data.video_description"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 新增 ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }