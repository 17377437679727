<template>
  <div class="Accounts">
    <v-card class="rounded-lg">
      <div class="pa-3">
        <v-row>
          <v-col cols="6">
            <v-text-field
              type="text"
              dense
              outlined
              clearable
              append-icon="mdi-magnify"
              v-model="search"
              @click:append="searchAction(false)"
              @keyup.enter="searchAction(false)"
              @click:clear="searchAction(true)"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="text-end">
            <v-btn color="primary" @click.stop="accountsCreateModal = true">
              <v-icon left dark> mdi-plus </v-icon>
              建立帳號
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="pa-3">
        <v-simple-table fixed-header max-height="600px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left od-th" @click="setSort('org')">
                  公司名稱
                  <v-icon small v-if="sort == 'org'" :class="{ reverse: isReverse }">mdi-chevron-down</v-icon>
                </th>
                <th class="text-left od-th" @click="setSort('email')">
                  帳號
                  <v-icon small v-if="sort == 'email'" :class="{ reverse: isReverse }">mdi-chevron-down</v-icon>
                </th>
                <th class="text-left">啟用狀態</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.org }}</td>
                <td>{{ item.email }}</td>
                <td>
                  <v-chip color="success" small v-if="item.status">啟用中</v-chip>
                  <v-chip small v-else>未啟用</v-chip>
                </td>
                <td class="text-end">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item :to="`/account/${item.id}`">
                        <v-list-item-icon>
                          <v-icon>mdi-magnify</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>詳細資訊</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click.stop="activeItem = item; accountsDeleteModal = true">
                        <v-list-item-icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>刪除</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

      </div>
    </v-card>
    <div class="text-center py-4">
      <v-row justify="center">
        <v-col cols="8">
          <v-pagination
            circle
            v-model="isPage"
            class="my-4"
            :length="pageLength"
            @next="isPage + 1 ? isPage + 1 : 1"
            @previous="isPage - 1 ? isPage - 1 : 1"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
    <AccountsCreateModal :dialog.sync="accountsCreateModal" @updateData="updateData"></AccountsCreateModal>
    <AccountsDeleteModal :dialog.sync="accountsDeleteModal" :item="activeItem" @updateData="updateData"></AccountsDeleteModal>
  </div>
</template>

<script>
import AccountsCreateModal from "@/components/modals/accounts/create.vue";
import AccountsDeleteModal from "@/components/modals/accounts/delete.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Accounts",
  components: {
    AccountsCreateModal,
    AccountsDeleteModal
  },
  data() {
    return {
      accountsCreateModal: false,
      accountsDeleteModal: false,
      sort: "",
      search: "",
      isPage: 1,
      isReverse: false,
      activeItem: {}
    };
  },
  methods: {
    searchAction(isClear) {

      if(isClear){
        this.search = ''
      }
      this.getAccounts({sort: this.sort, isReverse: this.isReverse, search: this.search})
    },
    setSort(mode) {
      const vm = this

      // 判斷是否第一次排序
      if (!vm.sort) {
        vm.sort = mode;
      } else {
        if (vm.sort == mode) {
          vm.isReverse = !vm.isReverse;
        } else {
          vm.sort = mode;
        }
      }
    },
    updateData() {
      this.getAccounts({sort: this.sort, isReverse: this.isReverse, search: this.search})
    },
    ...mapActions("accounts", ["getAccounts"]),
    ...mapMutations("accounts", ["SET_PAGE"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    this.getAccounts({
      sort: this.sort,
      isReverse: this.isReverse,
      search: this.search,
    });
  },
  computed: {
    ...mapState({
      data: (state) => state.accounts.data.items,
      page: (state) => state.accounts.data.page,
      pageLength: (state) => state.accounts.data.last_page,
    }),
  },
  watch: {
    isPage() {
      this.SET_PAGE(this.isPage);
      this.getAccounts({
        sort: this.sort,
        isReverse: this.isReverse,
        search: this.search,
      });
    },
    sort() {
      this.getAccounts({
        sort: this.sort,
        isReverse: this.isReverse,
        search: this.search,
      });
    },
    isReverse() {
      this.getAccounts({
        sort: this.sort,
        isReverse: this.isReverse,
        search: this.search,
      });
    },
  },
};
</script>
