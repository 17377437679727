<template>
  <v-snackbar :timeout="2000" color="success" v-model="successSnackbar.show">
    <v-icon small class="mr-2">mdi-check</v-icon>{{ this.$store.state.successSnackbar.text }}
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "successSnackbar",
  computed: {
    ...mapGetters(['successSnackbar'])
  },
};
</script>