<template>
  <v-card flat class="mainScreenEntrancePage">
    <v-card-text>
      <div class="py-8">
        <video
          class="w-50 d-block mx-auto"
          v-if="data_.forum.type === 'video'"
          :src="data_.forum.url"
          :poster="data_.forum.poster"
          controls
          loop
          playsinline
          muted
        />
        <v-img
          class="w-50 d-block mx-auto"
          v-else-if="data_.forum.type === 'image'"
          :src="data_.forum.poster"
        />
        <iframe
          class="w-50 d-block mx-auto h-300"
          v-else-if="data_.forum.type === 'iframe'"
          :src="youtube | youtubeUrl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="py-0">
              <v-select
                v-model="data_.forum.type"
                :items="type"
                item-text="name"
                item-value="value"
                label="內容類型"
                outlined
                dense
              ></v-select>
            </v-col>
            <div class="w-100"></div>
            <v-col cols="12" md="7" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                :name="
                  data_.forum.type == 'image' ? '圖片連結' : '預覽圖片連結'
                "
                rules="required"
              >
                <v-text-field
                  v-model="data_.forum.poster"
                  :error-messages="errors"
                  type="text"
                  :placeholder="
                    data_.forum.type == 'image' ? '圖片連結' : '預覽圖片連結'
                  "
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'main';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    {{
                      data_.forum.type == "image" ? "圖片連結" : "預覽圖片連結"
                    }}
                  </template>
                </v-text-field>
              </validation-provider>

              <v-select
                v-if="data_.forum.type == 'image'"
                v-model="linkType"
                :items="linkTypeData"
                item-text="name"
                item-value="value"
                label="連結"
                outlined
                dense
              ></v-select>

              <validation-provider
                v-slot="{ errors }"
                name="自訂連結"
                rules="required"
                v-if="linkType == 'order' && data_.forum.type == 'image'"
              >
                <v-text-field
                  v-model="data_.forum.url"
                  :error-messages="errors"
                  type="text"
                  placeholder="自訂連結"
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    自訂連結
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              md="7"
              class="py-0"
              v-if="data_.forum.type == 'iframe'"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Youtube連結key"
                rules="required"
              >
                <v-text-field
                  v-model="youtube"
                  :error-messages="errors"
                  type="text"
                  placeholder="Youtube連結key"
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    Youtube連結key
                  </template>
                </v-text-field>
              </validation-provider>
              <v-switch
                class="mt-0 ms-2"
                v-model="youtubeAutoPlay"
                inset
                :label="'自動播放'"
              ></v-switch>
            </v-col>

            <v-col
              cols="12"
              md="7"
              class="py-0"
              v-if="data_.forum.type == 'video'"
            >
              <validation-provider
                v-slot="{ errors }"
                name="影片連結"
                rules="required"
              >
                <v-text-field
                  v-model="data_.forum.url"
                  :error-messages="errors"
                  type="text"
                  placeholder="影片連結"
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    影片連結
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>

      <v-btn target="_blank" :href="queck_token" v-if="queck_token">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LinkTypeModel from "@/models/linkType.js";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "mainScreenEntrancePage",
  props: {
    data: {},
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      linkTypeData: LinkTypeModel,
      linkType: "/lecture",
      youtube: "",
      youtubeAutoPlay: false,
      selected: "",
      type: [
        {
          name: "圖片",
          value: "image",
        },
        {
          name: "影片",
          value: "video",
        },
        {
          name: "Youtube影片",
          value: "iframe",
        },
      ],
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    resetData() {
      this.$emit("update");
    },
    transformData() {
      if (this.linkType == "order") {
        this.data_.forum.external = true;
      } else {
        this.data_.forum.external = false;
        this.data_.forum.url = this.linkType;
      }

      if (this.data_.forum.type == "iframe") {
        this.data_.forum.url = this.$options.filters.youtubeUrl(this.youtube,this.youtubeAutoPlay);
      }

      return this.data_;
    },
    youtubeHasAutoPlay(url) {
      let gate = url.indexOf("autoplay") > 0 ? true : false
      return gate
    },
    initView() {
      if (this.data_.forum.external) {
        this.linkType = "order";
      } else {
        this.linkType = this.data_.forum.url;
        this.data_.forum.url = "";
      }

      if (this.data_.forum.type == "iframe") {
        this.youtube = this.$options.filters.youtubeKey(this.data_.forum.url);
      }

      this.youtubeAutoPlay = this.youtubeHasAutoPlay(this.data_.forum.url)
    },
    update() {
      const vm = this;
      let data = this.transformData();

      vm.updateEntrance({ id: vm.id, data: data }).then(() => {
        this.resetData();
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    ...mapActions("updateEntrance", ["updateEntrance"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR", "SET_MEDIA_MODAL"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => {
        return state.exhibition.data.custom_backend
          ? `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`
          : "";
      },
    }),
  },
  mounted() {
    this.initView();
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
      this.initView();
    },
    "$store.state.media.selected": function () {
      switch (this.selected) {
        case "main": {
          this.data_.forum.poster = this.$store.state.media.selected.link;
        }
      }
    },
  },
};
</script>

<style scoped>
.h-300{
  height: 300px !important;
}
</style>