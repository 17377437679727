<template>
  <v-card flat class="pointLecturePage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <v-card>
                <v-card-title class="text--body justify-space-between py-0">
                  分會場
                  <v-switch
                    v-model="data_.point_show"
                    inset
                    :label="'顯示'"
                  ></v-switch>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-center">
                  <div class="mb-4">
                    <p>未觀看圖示連結</p>
                    <div class="w-25 mx-auto">
                      <div class="image-square pt-29">
                        <img
                          class="object-fit-cover"
                          :src="data_.point_img_off"
                          alt=""
                        />
                      </div>
                    </div>
                    <p class="text--secondary caption pt-2">
                      建議尺寸100 x 100像素，PNG格式
                    </p>
                    <validation-provider
                      v-slot="{ errors }"
                      name="未觀看圖示連結"
                      rules="required"
                    >
                      <v-text-field
                        v-model="data_.point_img_off"
                        :error-messages="errors"
                        type="text"
                        class="px-8 pt-8"
                        placeholder="未觀看圖示連結"
                        outlined
                        dense
                        clearable
                        append-icon="mdi-folder-multiple-image"
                        @click:append="SET_MEDIA_MODAL(true); selected = 'off'"
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          未觀看圖示連結
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  
                  <div class="">
                    <p>已觀看圖示連結</p>
                    <div class="w-25 mx-auto">
                      <div class="image-square pt-29">
                        <img
                          class="object-fit-cover"
                          :src="data_.point_img_on"
                          alt=""
                        />
                      </div>
                    </div>
                    <p class="text--secondary caption pt-2">
                      建議尺寸100 x 100像素，PNG格式
                    </p>
                    <validation-provider
                      v-slot="{ errors }"
                      name="已觀看圖示連結"
                      rules="required"
                    >
                      <v-text-field
                        v-model="data_.point_img_on"
                        :error-messages="errors"
                        type="text"
                        class="px-8 pt-8"
                        placeholder="已觀看圖示連結"
                        outlined
                        dense
                        clearable
                        append-icon="mdi-folder-multiple-image"
                        @click:append="SET_MEDIA_MODAL(true); selected = 'on'"
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          已觀看圖示連結
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="`${queck_token}?path=venue`">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "pointLecturePage",
  props: {
    data: {},
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    update() {
      const vm = this;

      vm.updateVenue({ id: vm.id, data: this.data_ }).then(() => {
        this.$emit("update");
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    resetData() {
      this.$emit("update");
    },
    ...mapActions("updateVenue", ["updateVenue"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR","SET_MEDIA_MODAL"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`,
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data))
    },
    "$store.state.media.selected": function() {
      switch(this.selected){
        case 'on': {
          this.data_.point_img_on = this.$store.state.media.selected.link;
          return;
        }
        case 'off': {
          this.data_.point_img_off = this.$store.state.media.selected.link;
          return;
        }
      }
    }
  }
};
</script>