<template>
  <div class="Account">
    <v-card class="rounded-lg" v-if="data">
      <v-card-text>
        <dl class="row gy-2">
          <dt class="col-12 mt-4 font-weight-bold text-h6">資訊</dt>
          <dt class="col-4 col-md-2 text--secondary">組織：</dt>
          <dd class="col-8 col-md-10">
            {{ data.org }}
          </dd>
          <dt class="col-4 col-md-2 text--secondary">統一編號：</dt>
          <dd class="col-8 col-md-10">
            {{ data.serial ? data.serial : "-" }}
          </dd>
          <dt class="col-4 col-md-2 text--secondary">帳號：</dt>
          <dd class="col-8 col-md-10">
            {{ data.email }}
          </dd>
          <dt class="col-4 col-md-2 text--secondary">啟用日期：</dt>
          <dd class="col-8 col-md-10">
            {{ data.start_at }} ~ {{ data.end_at }}
          </dd>
          <dt class="col-4 col-md-2 text--secondary">創建日期：</dt>
          <dd class="col-8 col-md-10">
            {{ data.created_at | date }}
          </dd>
          <dt class="col-12 mt-4 font-weight-bold text-h6">權限</dt>
          <!-- <dt class="col-4 col-md-2 text--secondary">資訊管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.info">啟用</v-chip>
            <v-chip small v-else>停用</v-chip>
          </dd> -->
          <dt class="col-4 col-md-2 text--secondary">入口大廳管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.entrance"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>

          <dt class="col-4 col-md-2 text--secondary">選單管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.tab"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>

          <dt class="col-4 col-md-2 text--secondary">登入畫面管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.login"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>

          <dt class="col-4 col-md-2 text--secondary">演講廳管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.lecture"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>

          <dt class="col-4 col-md-2 text--secondary">分會場管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.venue"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>

          <dt class="col-4 col-md-2 text--secondary">展示區管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.area"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>
        </dl>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mx-1" @click="accountsUpdateModal = true">
          <v-icon left dark> mdi-pencil </v-icon>
          編輯帳號
        </v-btn>
      </v-card-actions>
    </v-card>
    <AccountsUpdateModal
      :dialog.sync="accountsUpdateModal"
      @updateData="updateData"
      v-if="data.email"
      :data_="data"
    ></AccountsUpdateModal>
  </div>
</template>

<script>
import AccountsUpdateModal from "@/components/modals/accounts/update.vue";

import { mapActions } from "vuex";

export default {
  name: "Account",
  components: {
    AccountsUpdateModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      data: "",
      accountsUpdateModal: false,
    };
  },
  methods: {
    updateData() {
      this.getAccount({ id: this.id }).then((res) => {
        this.data = res.data;
      });
    },
    ...mapActions("account", ["getAccount"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
    this.updateData();
  },
  computed: {},
  watch: {},
};
</script>
