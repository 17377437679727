import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth/auth.module'
import user from './auth/user.module'
import updateUser from './auth/update.module'
import exhibitions from './exhibition/exhibitions.module'
import exhibition from './exhibition/exhibition.module'
import tab from './tab/tab.module'
import area from './area/area.module'
import updateArea from './area/update.module'
import lecture from './lecture/lecture.module'
import updateLecture from './lecture/update.module'
import venue from './venue/venue.module'
import updateVenue from './venue/update.module'
import updateTab from './tab/update.module'
import createExhibition from './exhibition/create.module'
import deleteExhibition from './exhibition/delete.module'
import updateExhibition from './exhibition/update.module'
import accounts from './account/accounts.module'
import account from './account/account.module'
import updateAccount from './account/update.module'
import createAccount from './account/create.module'
import deleteAccount from './account/delete.module'
import entrance from './entrance/entrance.module'
import updateEntrance from './entrance/update.module'
import media from './media/media.module'
import createMedia from './media/create.module'
import deleteMedia from './media/delete.module'
import members from './member/members.module'
import member from './member/member.module'
import importMember from './member/import.module'
import createMember from './member/create.module'
import deleteMember from './member/delete.module'
import exportMember from './member/export.module'
import updateMember from './member/update.module'
import login from './login/login.module'
import updateLogin from './login/update.module'
import exportStats from './stats/export.module'
import close from './close/close.module'
import updateClose from './close/update.module'


Vue.use(Vuex)

export default new Vuex.Store({
  // strict: true,
  state: {
    layout: 'empty-layout',
    isLoading: false,
    partialLoding: false,
    mediaLoding: false,
    isRefresh: false,
    mediaModal: false,
    snackbar: {
      show: false,
      text: "",
    },
    successSnackbar: {
      show: false,
      text: "",
    }
  },
  getters: {
    layout(state) {
      return state.layout
    },
    snackbar(state) {
      return state.snackbar
    },
    successSnackbar(state) {
      return state.successSnackbar
    },
  },
  actions: {
    setLoading(context, payload) {
      context.commit('SET_LOADING', payload)
    },
    setPartialLoding(context, payload) {
      context.commit('SET_PARTIAL_LOADING', payload)
    },
    setSnackbar(context, payload) {
      context.commit('SET_SNACKBAR', payload)
    }
  },
  mutations: {
    SET_LAYOUT(state, payload) {
      state.layout = payload
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload
    },
    SET_PARTIAL_LOADING(state, payload){
      state.partialLoding = payload
    },
    SET_MEDIA_LOADING(state, payload){
      state.mediaLoding = payload
    },
    SET_SNACKBAR(state, payload) {
      state.snackbar.show = payload.show
      state.snackbar.text = payload.text
    },
    SET_SUCCESS_SNACKBAR(state, payload) {
      state.successSnackbar.show = payload.show
      state.successSnackbar.text = payload.text
    },
    SET_MEDIA_MODAL(state, payload) {
      state.mediaModal = payload
    }
  },
  modules: {
    auth,
    user,
    updateUser,
    exhibitions,
    exhibition,
    createExhibition,
    deleteExhibition,
    updateExhibition,
    accounts,
    account,
    createAccount,
    updateAccount,
    deleteAccount,
    entrance,
    updateEntrance,
    media,
    createMedia,
    deleteMedia,
    member,
    members,
    importMember,
    createMember,
    deleteMember,
    exportMember,
    updateMember,
    tab,
    updateTab,
    lecture,
    updateLecture,
    area,
    updateArea,
    venue,
    updateVenue,
    login,
    updateLogin,
    exportStats,
    close,
    updateClose
  }
})
