export default function (value) {
    var totelMin = value * 5

    var hour = Math.floor(totelMin/60)
    var hourStr = String(hour).length == 1 ? `0${hour}` : hour
    var min = totelMin%60
    var minStr = String(min).length == 1 ? `0${min}` : min
    
    var res = hourStr + ':' + minStr

    return res
}