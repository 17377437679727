<template>
  <v-card flat class="videoLecturePage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <v-btn
                class="mb-4"
                color="primary"
                @click.stop="categoryVenueCreateModal = true"
              >
                <v-icon left dark> mdi-plus </v-icon>
                新增分類
              </v-btn>
            </v-col>
            <template v-for="(value, index) in data_.theme_data">
              <v-col cols="12" class="justify-space-between mb-8" :key="index">
                <v-row>
                  <v-col cols="12">
                    <p class="text-h5 mb-2 float-left">
                      {{ value.theme_title }}
                    </p>
                    <div class="float-right">
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            @click="
                              categoryVenueUpdateModal = true;
                              activeCategoryId = index;
                              activeCategoryItem = value;
                            "
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>編輯</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="removeCategory(index)">
                            <v-list-item-icon>
                              <v-icon>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>刪除</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <div class="clearfix"></div>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color=""
                      @click.stop="
                        videoVenueCreateModal = true;
                        category = value;
                        category_index = index;
                      "
                    >
                      <v-icon left dark> mdi-plus </v-icon>
                      新增影片
                    </v-btn>
                  </v-col>
                  <template v-for="(subValue, subIndex) in value.video_data">
                    <v-col cols="6" md="4" :key="subIndex">
                      <v-card class="mx-auto" max-width="344">
                        <v-img :src="subValue.video_banner" height="200px">
                          <v-app-bar flat color="rgba(0, 0, 0, 0.25)">
                            <v-spacer></v-spacer>
                            <v-btn
                              color="white"
                              icon
                              @click="showVideo(subValue)"
                            >
                              <v-icon>mdi-play</v-icon>
                            </v-btn>
                            <v-menu bottom left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="white" icon v-bind="attrs" v-on="on">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  @click="
                                    videoVenueUpdateModal = true;
                                    category = value;
                                    category_index = index;
                                    activeItem = subValue;
                                    activeId = subIndex
                                  "
                                >
                                  <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>編輯</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                  @click="removeVideo(index, subIndex)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>mdi-close</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>刪除</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-app-bar>
                        </v-img>

                        <v-card-title class="pb-0">
                          <p class="text-truncate mb-0">
                            {{ subValue.video_title }}
                          </p>
                        </v-card-title>
                        <v-card-text>
                          <p class="text-truncate mb-0">
                            {{ subValue.video_description }}
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="`${queck_token}?path=venue`">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
    <VideoVenueCreateModal
      :dialog.sync="videoVenueCreateModal"
      :category="category"
      :category_index="category_index"
      @createData="createVideo"
    ></VideoVenueCreateModal>
    <VideoVenueUpdateModal
      :dialog.sync="videoVenueUpdateModal"
      :category="category"
      :category_index="category_index"
      :data="activeItem"
      :id="activeId"
      @updateData="updateVideo"
    ></VideoVenueUpdateModal>

    <CategoryVenueCreateModal
      :dialog.sync="categoryVenueCreateModal"
      @createData="createCategory"
    ></CategoryVenueCreateModal>
    <CategoryVenueUpdateModal
      :dialog.sync="categoryVenueUpdateModal"
      :data="activeCategoryItem"
      :id="activeCategoryId"
      @updateData="updateCategory"
    ></CategoryVenueUpdateModal>

    <PlayerVenueModal
      :dialog.sync="playerVenueModal"
      :video="video"
    ></PlayerVenueModal>
  </v-card>
</template>

<script>
import VideoVenueCreateModal from "@/components/modals/venue/video/create.vue";
import VideoVenueUpdateModal from "@/components/modals/venue/video/update.vue";
import CategoryVenueCreateModal from "@/components/modals/venue/video/category.vue";
import CategoryVenueUpdateModal from "@/components/modals/venue/video/categoryUpdate.vue";
import PlayerVenueModal from "@/components/modals/venue/video/player.vue";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "videoLecturePage",
  props: {
    data: {},
  },
  components: {
    VideoVenueCreateModal,
    VideoVenueUpdateModal,
    CategoryVenueCreateModal,
    CategoryVenueUpdateModal,
    PlayerVenueModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      videoVenueCreateModal: false,
      videoVenueUpdateModal: false,
      categoryVenueUpdateModal: false,
      categoryVenueCreateModal: false,
      playerVenueModal: false,
      video: "",
      category: "",
      category_index: "",
      activeCategoryId: "",
      activeCategoryItem: "",
      activeId: "",
      activeItem: "",
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    resetData() {
      this.$emit("update");
    },
    removeCategory(index) {
      this.data_.theme_data = this.data_.theme_data.filter(function (v, i) {
        return i !== index;
      });
    },
    removeVideo(index, subindex) {
      let data = "";
      data = this.data_.theme_data[index].video_data.filter(function (v, i) {
        return i !== subindex;
      });
      this.data_.theme_data[index].video_data = data;
    },
    showVideo(data) {
      this.video = data;
      this.playerVenueModal = true;
    },
    update() {
      const vm = this;

      vm.updateVenue({ id: vm.id, data: this.data_ }).then(() => {
        this.$emit("update");
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    createVideo(data) {
      let index = Object.keys(data)[0];

      this.data_.theme_data[index] = JSON.parse(JSON.stringify(data[index]));
    },
    updateVideo(index,value) {
      let id = Object.keys(value)[0];

      this.data_.theme_data[id].video_data[index] = {...value[id].video_data[index]}
    },
    createCategory(data) {
      let index = "";

      if (this.data_.theme_data == undefined) {
        index = 0;
        this.data_.theme_data = [];
      } else {
        index = this.data_.theme_data.length;
      }

      this.data_.theme_data[index] = { ...data };
    },
    updateCategory(index, value) {
      this.data_.theme_data[index] = { ...value };
    },
    ...mapActions("updateVenue", ["updateVenue"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => {
        return state.exhibition.data.custom_backend ? `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}` : ''
      }
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>