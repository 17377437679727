<template>
  <v-card flat class="carouselEntrancePage">
    <v-card-text>
      <v-row class="py-8 justify-center">
        <v-col cols="7" class="text-center">
          <v-btn
            class="mb-4"
            color="primary"
            :disabled="data_.slider && data_.slider.length >= 10"
            @click.stop="carouselCreateModal = true"
          >
            <v-icon left dark> mdi-plus </v-icon>
            新增輪播
          </v-btn>
          <p>最多新增10張頁籤</p>
        </v-col>

        <template v-if="data_.slider">
          <v-col
            cols="7"
            class="text-center"
            v-for="(item, index) in data_.slider"
            :key="index"
          >
            <v-card>
              <v-card-title class="text-h6 justify-space-between">
                {{ `第${index + 1}張` }}

                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="
                        carouselUpdateModal = true;
                        activeId = index;
                        activeItem = item;
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>編輯</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="removeSlider(index)">
                      <v-list-item-icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>刪除</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="moveSlider(index, true)">
                      <v-list-item-icon>
                        <v-icon>mdi-arrow-up</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>上移</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="moveSlider(index, false)">
                      <v-list-item-icon>
                        <v-icon>mdi-arrow-down</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>下移</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <video
                  class="w-50 d-block mx-auto"
                  v-if="item.type === 'video'"
                  :src="item.url"
                  :poster="item.poster"
                  controls
                  loop
                  playsinline
                  muted
                />
                <v-img
                  class="w-50 d-block mx-auto"
                  v-else-if="item.type === 'image'"
                  :src="item.poster"
                />
                <iframe
                  class="w-50 d-block mx-auto"
                  v-else-if="item.type === 'iframe'"
                  :src="item.url"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="queck_token">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="update"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
    <CarouselCreateModal
      :dialog.sync="carouselCreateModal"
      @createData="createSlider"
    ></CarouselCreateModal>
    <CarouselUpdateModal
      :dialog.sync="carouselUpdateModal"
      :data="activeItem"
      :id="activeId"
      @updateData="updateSlider"
    ></CarouselUpdateModal>
  </v-card>
</template>

<script>
import CarouselCreateModal from "@/components/modals/exhibition/carousel/create.vue";
import CarouselUpdateModal from "@/components/modals/exhibition/carousel/update.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "carouselEntrancePage",
  components: {
    CarouselCreateModal,
    CarouselUpdateModal,
  },
  props: {
    data: {},
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      selected: "",
      activeItem: "",
      activeId: "",
      carouselCreateModal: false,
      carouselUpdateModal: false,
    };
  },
  methods: {
    createSlider(data) {
      let index = "";

      if (this.data_.slider == undefined) {
        index = 0;
        this.data_.slider = [];
      } else {
        index = this.data_.slider.length;
      }

      this.data_.slider[index] = {
        name: index,
        poster: data.poster,
        type: data.type,
        url: data.type !== "image" ? data.url : "",
        external: false,
      };
    },
    updateSlider(index,value){
      this.data_.slider[index] = value
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    moveSlider(id, direction) {
      if (direction) {
        if (id == 0) {
          return;
        }
        this.data_.slider = this.swapItems(this.data_.slider, id, id - 1);
      } else {
        if (id == this.data_.slider.length - 1) {
          return;
        }
        this.data_.slider = this.swapItems(this.data_.slider, id, id + 1);
      }
    },
    removeSlider(id) {
      this.data_.slider = this.data_.slider.filter(function (value, index) {
        return index !== id;
      });
    },
    update() {
      const vm = this;

      vm.updateEntrance({ id: vm.id, data: this.data_ }).then(() => {
        vm.resetData();
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    resetData() {
      this.$emit("update");
    },
    ...mapActions("updateEntrance", ["updateEntrance"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`,
    }),
  },
  mounted() {},
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>