<template>
  <div class="videoVenueCreateModal">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5">
          新增影片至{{ category.theme_title }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <v-col cols="12" class="py-0">
                  <div v-if="video.video_data.video_url || youtube" class="py-4">
                    <video
                      class="w-50 d-block mx-auto"
                      v-if="video.video_data.video_type === 'video'"
                      :src="video.video_data.video_url"
                      controls
                      loop
                      autoplay
                      playsinline
                      muted
                    />
                    <iframe
                      class="w-50 d-block mx-auto"
                      v-else-if="video.video_data.video_type === 'iframe'"
                      :src="youtube | youtubeUrl"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  </div>
                  
                  <validation-provider
                    v-slot="{ errors }"
                    name="影片名稱"
                    rules="required"
                  >
                    <v-text-field
                      v-model="video.video_data.video_title"
                      :error-messages="errors"
                      type="text"
                      placeholder="影片名稱"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        影片名稱
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <v-select
                    v-model="video.video_data.video_type"
                    :items="type"
                    item-text="name"
                    item-value="value"
                    label="內容類型"
                    outlined
                    dense
                  ></v-select>

                  <div v-if="video.video_data.video_type == 'video'">
                    <validation-provider
                      v-slot="{ errors }"
                      name="影片連結"
                      rules="required"
                    >
                      <v-text-field
                        v-model="video.video_data.video_url"
                        :error-messages="errors"
                        type="text"
                        placeholder="影片連結"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          影片連結
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div v-if="video.video_data.video_type == 'iframe'">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Youtube連結key"
                      rules="required"
                    >
                      <v-text-field
                        v-model="youtube"
                        :error-messages="errors"
                        type="text"
                        placeholder="Youtube連結key"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          Youtube連結key
                        </template>
                      </v-text-field>
                    </validation-provider>

                    <v-switch
                      class="mt-0 ms-2"
                      v-model="youtubeAutoPlay"
                      inset
                      :label="'自動播放'"
                    ></v-switch>
                  </div>

                  <validation-provider
                    v-slot="{ errors }"
                    name="縮圖連結"
                    rules="required"
                  >
                    <v-text-field
                      v-model="video.video_data.video_banner"
                      :error-messages="errors"
                      type="text"
                      placeholder="縮圖連結"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'video';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        縮圖連結
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <validation-provider v-slot="{ errors }" name="影片敘述">
                    <v-text-field
                      v-model="video.video_data.video_description"
                      :error-messages="errors"
                      type="text"
                      placeholder="影片敘述"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label> 影片敘述 </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="validateForm"
            color="primary"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            <v-icon left dark> mdi-content-save </v-icon>
            新增
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "videoVenueCreateModal",
  props: {
    dialog: Boolean,
    category: {},
    category_index: {},
  },
  data() {
    return {
      video: {
        theme_title: this.category.theme_title,
        video_data: {
          video_url: "",
          video_title: "",
          video_banner: "",
          video_description: "",
          video_type: 'video',
        },
      },
      type: [
        {
          name: "影片",
          value: "video",
        },
        {
          name: "Youtube影片",
          value: "iframe",
        },
      ],
      selected: "",
      youtube: "",
      youtubeAutoPlay: false,
    };
  },
  methods: {
    async validateForm() {
      const valid = await this.$refs.observer.validate();
      let data = this.transformData();

      
      if (valid) {

        this.$emit("createData", data);
        this.isDialog = false;
        this.resetData();
      }
    },
    transformData() {
      
      // 如果為youtebe
      if(this.video.video_data.video_type == 'iframe'){
        this.video.video_data.video_url = this.$options.filters.youtubeUrl(
          this.youtube,
          this.youtubeAutoPlay
        );
      }

      let index = "";
      let data = {};

      if (this.category.video_data == undefined) {
        index = 0;
        data = {
          [this.category_index]: {
            theme_title: this.category.theme_title,
            video_data: [],
          },
        };
      } else {
        index = this.category.video_data.length;
        data = {
          [this.category_index]: {
            theme_title: this.category.theme_title,
            video_data: this.category.video_data,
          },
        };
      }

      data[this.category_index].video_data[index] = this.video.video_data;

      return data;
    },
    resetData() {
      this.video = {
        theme_title: this.category.theme_title,
        video_data: {
          video_url: "",
          video_title: "",
          video_banner: "",
          video_description: "",
          video_type: 'video',
        },
      };
      this.youtube = "";
      this.youtubeAutoPlay = false;
      // 清空驗證錯誤訊息
      this.$refs.observer.reset();
    },
    ...mapMutations(["SET_MEDIA_MODAL"]),
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  watch: {
    "$store.state.media.selected": function () {
      switch (this.selected) {
        case "video": {
          this.video.video_data.video_banner =
            this.$store.state.media.selected.link;
        }
      }
    },
  },
};
</script>