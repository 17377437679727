<template>
  <v-card flat class="buttonEntrancePage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <div class="w-25 mx-auto">
                <div class="image-square pt-29">
                  <img
                    class="object-fit-cover cursor-pointer"
                    :src="data_.forum.cta.hover ? data_.forum.cta.imageActive : data_.forum.cta.image"
                    :alt="data_.forum.cta.name"
                    @mouseover="data_.forum.cta.hover = true"
                    @mouseleave="data_.forum.cta.hover = false"
                  />
                </div>
              </div>
              <p class="text--secondary caption pt-2">建議尺寸600 x 168像素，PNG格式</p>
            </v-col>
            <v-col cols="12" md="7" class="text-center">
              <validation-provider
                v-slot="{ errors }"
                name="按鈕樣式"
                rules="required"
              >
                <v-text-field
                  v-model="data_.forum.cta.image"
                  :error-messages="errors"
                  type="text"
                  class="pt-8"
                  placeholder="按鈕樣式"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'basic';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    按鈕樣式
                  </template>
                </v-text-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="按鈕樣式(滑入)"
                rules="required"
              >
                <v-text-field
                  v-model="data_.forum.cta.imageActive"
                  :error-messages="errors"
                  type="text"
                  placeholder="按鈕樣式(滑入)"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'hover';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    按鈕樣式(滑入)
                  </template>
                </v-text-field>
              </validation-provider>

              <v-select
                v-model="type"
                :items="linkType"
                item-text="name"
                item-value="value"
                label="連結"
                outlined
                dense
              ></v-select>

              <validation-provider
                v-slot="{ errors }"
                name="自訂連結"
                rules="required"
                v-if="type == 'order'"
              >
                <v-text-field
                  v-model="data_.forum.cta.url"
                  :error-messages="errors"
                  type="text"
                  placeholder="自訂連結"
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    自訂連結
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="queck_token">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LinkTypeModel from "@/models/linkType.js";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "buttonEntrancePage",
  props: {
    data: {},
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      selected: "",
      type: "",
      linkType: LinkTypeModel,
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    update() {
      const vm = this
      let data = this.transformData()
      
      vm.updateEntrance({ id: vm.id, data: data }).then(() => {
        this.resetData()
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    transformData() {
      if(this.type == "order"){
        this.data_.forum.cta.external = true;

      }else{
        this.data_.forum.cta.external = false;
        this.data_.forum.cta.url = this.type
      }

      return this.data_
    },
    resetData() {
      this.$emit("update");
    },
    initView() {
      if (this.data_.forum.cta.external) {
        this.type = "order";
      } else {
        this.type = this.data_.forum.cta.url;
        this.data_.forum.cta.url = ''
      }
    },
    ...mapActions("updateEntrance", ["updateEntrance"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR","SET_MEDIA_MODAL"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`,
    }),
  },
  mounted() {
    this.initView()
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data))
      this.initView()
    },
    "$store.state.media.selected": function() {
      switch(this.selected){
        case 'basic': {
          this.data_.forum.cta.image = this.$store.state.media.selected.link;
          return;
        }
        case 'hover': {
          this.data_.forum.cta.imageActive = this.$store.state.media.selected.link;
          return;
        }
      }
    }
  },
};
</script>

<style scoped>
.pt-29 {
  padding-top: 29%;
}
</style>