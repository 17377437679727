<template>
  <v-footer class="justify-center" padless>
    <v-col class="text-center" cols="12">
      Copyright ©{{ new Date().getFullYear() }} Produced by Kingtel
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
