<template>
  <v-card flat class="marqueeLecturePage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <v-btn
                class="mb-4"
                color="primary"
                @click.stop="lectureMarqueeCreateModal = true"
              >
                <v-icon left dark> mdi-plus </v-icon>
                新增跑馬燈
              </v-btn>
            </v-col>
            <template v-for="(value, index) in data_.top.marquee.marquee_text">
              <v-col cols="12" md="7" :key="index">
                <v-card>
                  <v-card-title class="text-h6 justify-space-between">
                    {{ index + 1 }}
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          @click="
                            lectureMarqueeUpdateModal = true;
                            activeId = index;
                            activeItem = value;
                          "
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>編輯</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="removeScreen(index)">
                          <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>刪除</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-title>
                  <v-card-text>
                    <div v-if="value">
                      跑馬燈文字：
                      <a target="_blank" :href="value.url" v-if="value.url">
                        <p class="text-h6">{{ value.text }}</p>
                      </a>
                      <p class="text-h6" v-else>{{ value.text }}</p>
                      顯示時間：
                      <p class="mb-0">
                        {{ `${value.start_at} ~ ${value.end_at}` }}
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="`${queck_token}?path=lecture`">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
    <LectureMarqueeCreateModal
      :data_="data_"
      :dialog.sync="lectureMarqueeCreateModal"
      @createData="createMarquee"
    ></LectureMarqueeCreateModal>
    <LectureMarqueeUpdateModal
      :data_="data_"
      :dialog.sync="lectureMarqueeUpdateModal"
      :data="activeItem"
      :id="activeId"
      @updateData="updateMarquee"
    ></LectureMarqueeUpdateModal>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LectureMarqueeCreateModal from "@/components/modals/lecture/marquee/create.vue";
import LectureMarqueeUpdateModal from "@/components/modals/lecture/marquee/update.vue";

export default {
  name: "marqueeLecturePage",
  props: {
    data: {},
  },
  components: {
    LectureMarqueeCreateModal,
    LectureMarqueeUpdateModal
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      lectureMarqueeCreateModal: false,
      lectureMarqueeUpdateModal: false,
      activeItem: "",
      activeId: "",
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    update() {
      const vm = this;

      vm.updateLecture({ id: vm.id, data: this.data_ }).then(() => {
        this.$emit("update");

        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    resetData() {
      this.$emit("update");
    },
    createMarquee(data) {
      let index = 0;

      if (this.data_.top.marquee.marquee_text == undefined) {
        index = 0;
        this.data_.top.marquee.marquee_text = [];
      } else {
        index = this.data_.top.marquee.marquee_text.length;
      }
      this.data_.top.marquee.marquee_text[index] = data;
    },
    updateMarquee(index,value) {
      this.data_.top.marquee.marquee_text[index] = value
    },
    removeScreen(id) {
      this.data_.top.marquee.marquee_text =
        this.data_.top.marquee.marquee_text.filter(function (value, index) {
          return index !== id;
        });
    },
    ...mapActions("updateLecture", ["updateLecture"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`,
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>