<template>
  <div class="Outside">
    <v-card left>
      <v-tabs v-model="tab" class="border-bottom px-3">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Before :data="data" v-if="data" @update="update"></Before>
        </v-tab-item>
        <v-tab-item>
          <After :data="data" v-if="data" @update="update"></After>
        </v-tab-item>
      </v-tabs-items>
      <MediaModal :dialog.sync="$store.state.mediaModal"></MediaModal>
    </v-card>
  </div>
</template>

<script>
import MediaModal from "@/components/modals/media.vue";
import Before from "@/components/pages/outside/before.vue";
import After from "@/components/pages/outside/after.vue";


import { mapActions, mapState } from "vuex";

export default {
  name: "Outside",
  components: {
    MediaModal,
    After,
    Before
  },
  data() {
    return {
      tab: null,
      items: ["未開始", "已結束"],
      id: this.$route.params.id,
    };
  },
  methods: {
    update() {
      this.getClose({ id: this.id });
    },
    ...mapActions("close", ["getClose"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    this.getClose({ id: this.id });
  },
  computed: {
    ...mapState({
      data: (state) => state.close.data,
    }),
    ...mapState({
      user: (state) => state.user.data,
    })
  },
};
</script>