<template>
  <div class="Lecture">
    <v-alert
      prominent
      v-if="user.permission_info && !user.permission_info.entrance"
      type="warning"
    >您的權限不足</v-alert>
    <v-card left v-else>
      <v-tabs v-model="tab" class="border-bottom px-3">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Screen :data="data" v-if="data.lecture_bg" @update="update"></Screen>
        </v-tab-item>
        <v-tab-item>
          <Unstart :data="data" v-if="data.lecture_bg" @update="update"></Unstart>
        </v-tab-item>
        <v-tab-item>
          <Poster :data="data" v-if="data.lecture_bg" @update="update"></Poster>
        </v-tab-item>
        <v-tab-item>
          <Marquee :data="data" v-if="data.lecture_bg" @update="update"></Marquee>
        </v-tab-item>
        <v-tab-item>
          <Stage :data="data" v-if="data.lecture_bg" @update="update"></Stage>
        </v-tab-item>
        <v-tab-item>
          <Background :data="data" v-if="data.lecture_bg" @update="update"></Background>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <MediaModal :dialog.sync="$store.state.mediaModal"></MediaModal>
  </div>
</template>

<script>
import MediaModal from "@/components/modals/media.vue";
import Screen from "@/components/pages/lecture/screen.vue";
import Unstart from "@/components/pages/lecture/unstart.vue";
import Poster from "@/components/pages/lecture/poster.vue";
import Marquee from "@/components/pages/lecture/marquee.vue";
import Stage from "@/components/pages/lecture/stage.vue";
import Background from "@/components/pages/lecture/background.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "Lecture",
  components: {
    MediaModal,
    Screen,
    Unstart,
    Poster,
    Marquee,
    Stage,
    Background
  },
  data() {
    return {
      tab: null,
      items: ["影片", "未開始畫面", "側螢幕", "跑馬燈", "舞台場景", "背景"],
      id: this.$route.params.id,
    };
  },
  methods: {
    update() {
      this.getLecture({ id: this.id });
    },
    ...mapActions("lecture", ["getLecture"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    this.getLecture({ id: this.id });
  },
  computed: {
    ...mapState({
      data: (state) => state.lecture.data,
      user: (state) => state.user.data,
    }),
  },
};
</script>