<template>
  <v-card flat class="outsideAfter">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <p class="text-left">已結束畫面</p>
              <ckeditor
                v-model="data_.after_value"
                :config="editorConfig"
              ></ckeditor>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="`${queck_token}?path=after`">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "outsideAfter",
  props: {
    data: {},
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      after_value: '',
      mediaModal: false,
      editorConfig: {
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "links", groups: ["links"] },
        ],
      },
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    resetData() {
      this.$emit("update");
    },
    update() {
      const vm = this;

      vm.updateClose({ id: vm.id, data: this.data_ }).then(() => {
        this.$emit("update");
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    ...mapActions("updateClose", ["updateClose"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR","SET_MEDIA_MODAL"]),
  },
  computed: {
    ...mapState({
      dev_url: (state) => state.exhibition.data.frontend_dev_url,
      queck_token: (state) => {
        return state.exhibition.data.custom_backend ? `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}` : ''
      },
    }),
  },
  watch: {
    data() {
      if(this.data['after_value']){
        this.data_ = JSON.parse(JSON.stringify(this.data));
      }else{
        this.data_.after_value = ''
      }
    }
  },
};
</script>