<template>
  <div class="Area">
    <v-alert
      prominent
      v-if="user.permission_info && !user.permission_info.area"
      type="warning"
    >您的權限不足</v-alert>
    <v-card left v-else>
      <v-tabs v-model="tab" class="border-bottom px-3">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Stall :data="data" v-if="data.background" @update="update"></Stall>
        </v-tab-item>
        <v-tab-item>
          <Banner :data="data" v-if="data.background" @update="update"></Banner>
        </v-tab-item>
        <v-tab-item>
          <Background :data="data" v-if="data.background" @update="update"></Background>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <MediaModal :dialog.sync="$store.state.mediaModal"></MediaModal>
  </div>
</template>

<script>
import MediaModal from "@/components/modals/media.vue";
import Stall from "@/components/pages/area/stall.vue";
import Background from "@/components/pages/area/background.vue";
import Banner from "@/components/pages/area/banner.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "Area",
  components: {
    MediaModal,
    Stall,
    Background,
    Banner
  },
  data() {
    return {
      tab: null,
      items: ["攤位", "Banner", "背景"],
      id: this.$route.params.id,
    };
  },
  methods: {
    update() {
      this.getArea({ id: this.id });
    },
    ...mapActions("area", ["getArea"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    this.getArea({ id: this.id });
  },
  computed: {
    ...mapState({
      data: (state) => state.area.data,
      user: (state) => state.user.data,
    }),
  },
};
</script>