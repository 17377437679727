import Axios from 'axios'

export default {
  namespaced: true,
  strict: true,
  state: {
    data: {
    },
    url: ''
  },
  getters: {},
  actions: {
    getArea(context, payload) {
      let api = `${process.env.VUE_APP_API}/api/manage/exhibition/${payload.id}/area`
      const config = {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem('user')).accessToken,
        },
        actionUrl: 'area/getArea',
        actionPayload: payload
      }

      context.commit('SET_LOADING', true, { root: true })

      Axios.get(api, config)
        .then((response) => {
          context.commit('GET_AREA', response.data)
          context.commit('SET_LOADING', false, { root: true })
        })
        .catch((error) => {
          if (error.response.status !== 401) {
            if (error.response.status == 400) {
              context.commit('SET_SNACKBAR', {
                show: true,
                text: error.response.data
              }, { root: true })
            }

            context.commit('SET_LOADING', false, { root: true })
          }
        });
    },
  },
  mutations: {
    GET_AREA(state, payload) {
      state.data = payload,
        state.url = `https://${payload.code}-digievent.ictsage.com/`
    },
  },
  modules: {
  }
}