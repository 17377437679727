<template>
  <v-card flat class="posterLecturePage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8">
            <v-col cols="12" md="6" class="text-center">
              <p>螢幕(左)</p>
              <div class="w-50 w-md-25 mx-auto">
                <div class="image-square ">
                  <img class="object-fit-cover" :src="data_.middle.poster_left" alt="" />
                </div>
              </div>
              <p class="text--secondary caption pt-2">
                建議尺寸500 x 500像素，PNG格式
              </p>
              <validation-provider
                v-slot="{ errors }"
                name="螢幕(左)"
                rules="required"
              >
                <v-text-field
                  v-model="data_.middle.poster_left"
                  :error-messages="errors"
                  type="text"
                  class="px-8 pt-8"
                  placeholder="螢幕(左)"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'left';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    螢幕(左)
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6" class="text-center">
              <p>螢幕(右)</p>
              <div class="w-50 w-md-25 mx-auto">
                <div class="image-square ">
                  <img class="object-fit-cover" :src="data_.middle.poster_right" alt="" />
                </div>
              </div>
              <p class="text--secondary caption pt-2">
                建議尺寸500 x 500像素，PNG格式
              </p>
              <validation-provider
                v-slot="{ errors }"
                name="螢幕(右)"
                rules="required"
              >
                <v-text-field
                  v-model="data_.middle.poster_right"
                  :error-messages="errors"
                  type="text"
                  class="px-8 pt-8"
                  placeholder="螢幕(右)"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'right';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    螢幕(右)
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="`${queck_token}?path=lecture`">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "posterLecturePage",
  props: {
    data: {},
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      mediaModal: false,
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    update() {
      const vm = this;

      vm.updateLecture({ id: vm.id, data: this.data_ }).then(() => {
        this.$emit("update");

        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    resetData() {
      this.$emit("update");
    },
    ...mapActions("updateLecture", ["updateLecture"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR","SET_MEDIA_MODAL"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`,
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data))
    },
    "$store.state.media.selected": function() {
      switch(this.selected){
        case 'left': {
          this.data_.middle.poster_left = this.$store.state.media.selected.link;
          return;
        }
        case 'right': {
          this.data_.middle.poster_right = this.$store.state.media.selected.link;
          return;
        }
      }
    }
  }
};
</script>

<style scoped>
</style>