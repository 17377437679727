<template>
  <div class="Statistics">
    <v-card left>
      <v-card-text class="text-center">
        <v-btn @click="exportFile">下載數據總表</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Statistics",
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      data_: {},
    };
  },
  methods: {
    exportFile() {
      this.exportStats({ id: this.id }).then((res) => {
        location.href = res.data.url
      });
    },
    ...mapActions("exportStats", ["exportStats"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    // this.getExhibition({ id: this.id })
  },
  computed: {
  },
  watch: {
  }
};
</script>