<template>
  <div>
    <v-breadcrumbs :items="items" class="pa-3"></v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  data: () => ({
    items: [],
  }),
  methods: {
    getBreadcrumb(){
      this.items = this.$route.meta.breadcrumb
    }
  },
  created() {
    this.getBreadcrumb()
  },
  watch: {
    '$route' () {
      this.getBreadcrumb()
    }
  }
};
</script>
