<template>
  <div class="Entrance">
    <v-alert
      prominent
      v-if="user.permission_info && !user.permission_info.entrance"
      type="warning"
    >您的權限不足
    </v-alert>
    <v-card left v-else>
      <v-tabs v-model="tab" class="border-bottom px-3">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <MainScreen :data="data" v-if="data.background" @update="update"></MainScreen>
        </v-tab-item>
        <v-tab-item>
          <Flags :data="data" v-if="data.background" @update="update"></Flags>
        </v-tab-item>
        <v-tab-item>
          <Button :data="data" v-if="data.background" @update="update"></Button>
        </v-tab-item>
        <v-tab-item>
          <Background :data="data" v-if="data.background" @update="update"></Background>
        </v-tab-item>
        <v-tab-item>
          <Scenes :data="data" v-if="data.background" @update="update"></Scenes>
        </v-tab-item>
        <v-tab-item>
          <Message :data="data" v-if="data.background" @update="update"></Message>
        </v-tab-item>
        <v-tab-item>
          <Carousel :data="data" v-if="data.background" @update="update"></Carousel>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <MediaModal :dialog.sync="$store.state.mediaModal"></MediaModal>
  </div>
</template>

<script>
import MediaModal from "@/components/modals/media.vue";
import MainScreen from "@/components/pages/entrance/mainScreen.vue"
import Flags from "@/components/pages/entrance/flags.vue"
import Button from "@/components/pages/entrance/button.vue"
import Background from "@/components/pages/entrance/background.vue"
import Scenes from "@/components/pages/entrance/scenes.vue"
import Message from "@/components/pages/entrance/message.vue"
import Carousel from "@/components/pages/entrance/carousel.vue"

import { mapActions, mapState } from "vuex";

export default {
  name: "Entrance",
  components: {
    MediaModal,
    MainScreen,
    Flags,
    Button,
    Background,
    Scenes,
    Message,
    Carousel
  },
  data() {
    return {
      tab: null,
      items: ["大螢幕", "掛軸", "按鈕", "背景", "場景", "歡迎訊息", "小輪播圖"],
      id: this.$route.params.id
    };
  },
  methods: {
    update() {
      this.getEntrance({id: this.id})
    },
    ...mapActions("entrance", ["getEntrance"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    this.getEntrance({id: this.id})
  },
  computed: {
    ...mapState({
      data: (state) => state.entrance.data,
      user: (state) => state.user.data,
    })
  },
  watch: {},
};
</script>
