import Axios from 'axios'

export default {
  namespaced: true,
  strict: true,
  state: {
    data: {
      page: 1,
      last_page: 1,
    }
  },
  getters: {},
  actions: {
    createMember(context,payload = null) {
      let api = `${process.env.VUE_APP_API}/api/manage/exhibition/${payload.id}/member`
      const config = {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem('user')).accessToken,
        },
        actionUrl: 'createMember/createMember',
        actionPayload: payload
      }
      const parameter = payload.data;

      context.commit('SET_LOADING', true, { root: true })

      return new Promise((resolve, reject) => {

      Axios.put(api, parameter, config)
        .then((response) => {
          context.commit('GET_MEMBER', response.data)
          context.commit('SET_LOADING', false, { root: true })
          resolve(response);

        })
        .catch((error) => {
          if (error.response.status !== 401) {
            if (error.response.status == 400) {
              context.commit('SET_SNACKBAR', {
                show: true,
                text: error.response.data
              }, { root: true })
            }

            context.commit('SET_LOADING', false, { root: true })
          }
          reject(error);

        });
      })
    },
  },
  mutations: {
    GET_MEMBER(state, payload) {
      state.data = payload
    },
    SET_PAGE(state, payload) {
      state.data.page = payload
    },
  },
  modules: {
  }
}