<template>
  <v-app>
    <ProgressLinear></ProgressLinear>

    <ExhibitionSide v-if="inExhibitionPage"></ExhibitionSide>
    <Side v-else></Side>

    <Header></Header>
    <v-main style="background: #fafafa">
      <Breadcrumb></Breadcrumb>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <Snackbar></Snackbar>
    <SuccessSnackbar></SuccessSnackbar>
  </v-app>
</template>

<script>
import Header from "@/components/layouts/headers/basic.vue";
import Breadcrumb from "@/components/layouts/breadcrumbs/basic.vue";
import Side from "@/components/layouts/sides/basic.vue";
import ExhibitionSide from "@/components/layouts/sides/exhibition.vue";
import ProgressLinear from "@/components/layouts/progress/linear.vue"
import Snackbar from "@/components/snackbars/basic.vue"
import SuccessSnackbar from "@/components/snackbars/success.vue"
import { mapActions } from "vuex";

export default {
  name: "basic",
  components: {
    ProgressLinear,
    Header,
    Breadcrumb,
    Side,
    Snackbar,
    SuccessSnackbar,
    ExhibitionSide
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("user", ["getUser"]),
  },
  created() {
    this.getUser()
  },
  computed: {
    inExhibitionPage() {
      return this.$route.fullPath.split('/').length > 3
    }
  }
};
</script>