var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scenesEntrancePage"},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8 justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-card',[_c('v-card-title',{staticClass:"text--body justify-space-between py-0"},[_vm._v(" 接待處 "),_c('v-switch',{attrs:{"inset":"","label":'顯示'},model:{value:(_vm.data_.table.display),callback:function ($$v) {_vm.$set(_vm.data_.table, "display", $$v)},expression:"data_.table.display"}})],1),_c('v-divider'),(_vm.data_.table.display)?_c('v-card-text',{staticClass:"text-center"},[_c('div',{staticClass:"w-50 mx-auto position-relative od_table"},[_c('div',{staticClass:"image-square pt-61"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.data_.table.tableImage,"alt":""}})]),_c('img',{staticClass:"position-absolute od_table__logo mw-100",attrs:{"src":_vm.data_.table.logoImage,"alt":""}})]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v(" 建議尺寸"),_c('br'),_vm._v(" 桌子：1024 x 626像素，PNG格式"),_c('br'),_vm._v(" logo：931 x 345像素，PNG格式 ")]),_c('validation-provider',{attrs:{"name":"接待處圖片連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"接待處圖片連結","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                      _vm.selected = 'table_image';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 接待處連結 ")]},proxy:true}],null,true),model:{value:(_vm.data_.table.tableImage),callback:function ($$v) {_vm.$set(_vm.data_.table, "tableImage", $$v)},expression:"data_.table.tableImage"}})]}}],null,false,910216484)}),_c('validation-provider',{attrs:{"name":"接待處logo連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"接待處logo連結","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                      _vm.selected = 'table_logo';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 接待處logo連結 ")]},proxy:true}],null,true),model:{value:(_vm.data_.table.logoImage),callback:function ($$v) {_vm.$set(_vm.data_.table, "logoImage", $$v)},expression:"data_.table.logoImage"}})]}}],null,false,539982525)})],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-card',[_c('v-card-title',{staticClass:"text--body justify-space-between py-0"},[_vm._v(" 攤位區 "),_c('v-switch',{attrs:{"inset":"","label":'顯示'},model:{value:(_vm.data_.session.display),callback:function ($$v) {_vm.$set(_vm.data_.session, "display", $$v)},expression:"data_.session.display"}})],1),_c('v-divider'),(_vm.data_.session.display)?_c('v-card-text',{staticClass:"text-center"},[_c('div',{staticClass:"w-50 mx-auto position-relative od_session mb-4"},[_c('div',{staticClass:"image-square od_session__img pt-38 mx-auto"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.data_.session.entrance,"alt":""}})]),_c('img',{staticClass:"position-absolute od_session__logo mw-100",attrs:{"src":_vm.data_.session.deco,"alt":""}})]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v(" 建議尺寸"),_c('br'),_vm._v(" 攤位圖：1024 x 675像素，PNG格式"),_c('br'),_vm._v(" 人群：512 x 182像素，PNG格式 ")]),_c('validation-provider',{attrs:{"name":"攤位區圖片連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"攤位區圖片連結","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                      _vm.selected = 'session_entrance';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 攤位區圖片連結 ")]},proxy:true}],null,true),model:{value:(_vm.data_.session.entrance),callback:function ($$v) {_vm.$set(_vm.data_.session, "entrance", $$v)},expression:"data_.session.entrance"}})]}}],null,false,3344709643)}),_c('validation-provider',{attrs:{"name":"接待處logo連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"接待處logo連結","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                      _vm.selected = 'session_deco';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 接待處logo連結 ")]},proxy:true}],null,true),model:{value:(_vm.data_.session.deco),callback:function ($$v) {_vm.$set(_vm.data_.session, "deco", $$v)},expression:"data_.session.deco"}})]}}],null,false,2413834362)}),_c('v-select',{attrs:{"items":_vm.linkType,"item-text":"name","item-value":"value","label":"連結","outlined":"","dense":""},model:{value:(_vm.sessionType),callback:function ($$v) {_vm.sessionType=$$v},expression:"sessionType"}}),(_vm.sessionType == 'order')?_c('validation-provider',{attrs:{"name":"自訂連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"自訂連結","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 自訂連結 ")]},proxy:true}],null,true),model:{value:(_vm.data_.session.url),callback:function ($$v) {_vm.$set(_vm.data_.session, "url", $$v)},expression:"data_.session.url"}})]}}],null,false,3923122746)}):_vm._e()],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-card',[_c('v-card-title',{staticClass:"text--body justify-space-between py-0"},[_vm._v(" 指示牌 "),_c('v-switch',{attrs:{"inset":"","label":'顯示'},model:{value:(_vm.data_.sign.display),callback:function ($$v) {_vm.$set(_vm.data_.sign, "display", $$v)},expression:"data_.sign.display"}})],1),_c('v-divider'),(_vm.data_.sign.display)?_c('v-card-text',{staticClass:"text-center"},[_c('div',{staticClass:"w-50 mx-auto position-relative od_sign mb-4"},[_c('div',{staticClass:"image-square od_sign__back mx-auto w-50"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.data_.sign.bgImage,"alt":""}})]),_c('img',{staticClass:"position-absolute od_sign__img mw-100",attrs:{"src":_vm.data_.sign.screenImage,"alt":""}})]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v(" 建議尺寸"),_c('br'),_vm._v(" 指示牌座：512 x 1028像素，PNG格式"),_c('br'),_vm._v(" 指示牌圖：512 x 796像素，PNG格式 ")]),_c('validation-provider',{attrs:{"name":"指示牌底座圖片","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"指示牌底座圖片","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                      _vm.selected = 'sign_bgImage';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 指示牌底座圖片 ")]},proxy:true}],null,true),model:{value:(_vm.data_.sign.bgImage),callback:function ($$v) {_vm.$set(_vm.data_.sign, "bgImage", $$v)},expression:"data_.sign.bgImage"}})]}}],null,false,2522939339)}),_c('validation-provider',{attrs:{"name":"指示牌螢幕圖片","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"指示牌螢幕圖片","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                      _vm.selected = 'sign_screenImage';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 指示牌螢幕圖片 ")]},proxy:true}],null,true),model:{value:(_vm.data_.sign.screenImage),callback:function ($$v) {_vm.$set(_vm.data_.sign, "screenImage", $$v)},expression:"data_.sign.screenImage"}})]}}],null,false,4243187386)}),_c('v-select',{attrs:{"items":_vm.linkType,"item-text":"name","item-value":"value","label":"上半部連結","outlined":"","dense":""},model:{value:(_vm.signUpType),callback:function ($$v) {_vm.signUpType=$$v},expression:"signUpType"}}),(_vm.signUpType == 'order')?_c('validation-provider',{attrs:{"name":"上半部自訂連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"上半部自訂連結","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 上半部自訂連結 ")]},proxy:true}],null,true),model:{value:(_vm.data_.sign.link1.url),callback:function ($$v) {_vm.$set(_vm.data_.sign.link1, "url", $$v)},expression:"data_.sign.link1.url"}})]}}],null,false,2358069544)}):_vm._e(),_c('v-select',{attrs:{"items":_vm.linkType,"item-text":"name","item-value":"value","label":"下半部連結","outlined":"","dense":""},model:{value:(_vm.signDownType),callback:function ($$v) {_vm.signDownType=$$v},expression:"signDownType"}}),(_vm.signDownType == 'order')?_c('validation-provider',{attrs:{"name":"下半部自訂連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"下半部自訂連結","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 下半部自訂連結 ")]},proxy:true}],null,true),model:{value:(_vm.data_.sign.link2.url),callback:function ($$v) {_vm.$set(_vm.data_.sign.link2, "url", $$v)},expression:"data_.sign.link2.url"}})]}}],null,false,2361713163)}):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.resetData}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cached ")]),_vm._v(" 復原 ")],1),_c('v-btn',{attrs:{"target":"_blank","href":_vm.queck_token}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-card-search ")]),_vm._v(" 預覽 ")],1),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 儲存 ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }