<template>
  <div class="lectureMarqueeCreateModal">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 新增跑馬燈 </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="跑馬燈文字"
                    rules="required"
                  >
                    <v-text-field
                      v-model="marquee.text"
                      :error-messages="errors"
                      type="text"
                      placeholder="跑馬燈文字"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        跑馬燈文字
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider v-slot="{ errors }" name="連結" rules="">
                    <v-text-field
                      v-model="marquee.url"
                      :error-messages="errors"
                      type="text"
                      placeholder="連結"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label> 連結 </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-dialog
                    ref="strDatePickerDialog"
                    v-model="strDateModal"
                    :return-value.sync="strDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="開始日期"
                        rules="required"
                      >
                        <v-text-field
                          v-model="strDate"
                          append-icon="mdi-calendar"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            開始日期
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker v-model="strDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="strDateModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.strDatePickerDialog.save(strDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="開始時間"
                    rules="required"
                  >
                    <v-text-field
                      label="開始時間"
                      v-model="strTime"
                      type="time"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>

                  <!-- <v-dialog
                    ref="strTimePickerdialog"
                    v-model="strTimeModal"
                    :return-value.sync="strTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="開始時間"
                        rules="required"
                      >
                        <v-text-field
                          v-model="strTime"
                          label="開始時間"
                          append-icon="mdi-clock-time-four-outline"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            開始時間
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-time-picker
                      v-if="strTimeModal"
                      v-model="strTime"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="strTimeModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.strTimePickerdialog.save(strTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog> -->
                </v-col>
                <!-- <v-col cols="6" class="py-0">
                  <v-dialog
                    ref="endDatePickerDialog"
                    v-model="endDateModal"
                    :return-value.sync="endDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="結束日期"
                        rules="required"
                      >
                        <v-text-field
                          v-model="endDate"
                          append-icon="mdi-calendar"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            結束日期
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endDateModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endDatePickerDialog.save(endDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col> -->
                <v-col cols="6" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="結束時間"
                    rules="required"
                  >
                    <v-text-field
                      label="結束時間"
                      v-model="endTime"
                      type="time"
                      min="05:00"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                  <!-- <v-dialog
                    ref="endTimePickerdialog"
                    v-model="endTimeModal"
                    :return-value.sync="endTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="結束時間"
                        rules="required"
                      >
                        <v-text-field
                          v-model="endTime"
                          label="結束時間"
                          append-icon="mdi-clock-time-four-outline"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            結束時間
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-time-picker
                      v-if="endTimeModal"
                      v-model="endTime"
                      :min="strTime"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endTimeModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endTimePickerdialog.save(endTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog> -->
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="validateForm"
            color="primary"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            <v-icon left dark> mdi-content-save </v-icon>
            新增
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "lectureMarqueeCreateModal",
  props: {
    dialog: Boolean,
    data_: {},
  },
  data() {
    return {
      marquee: {
        text: "",
        url: "",
        start_at: "",
        end_at: "",
      },
      strDate: "",
      strTime: "",
      strDateModal: false,
      strTimeModal: false,
      endDate: "",
      endTime: "",
      endDateModal: false,
      endTimeModal: false,
    };
  },
  methods: {
    async validateForm() {
      const valid = await this.$refs.observer.validate();
      let data = this.transformData();

      if (valid) {
        // 判斷時間是否已經有跑馬燈
        // if (this.timeRepeatGate(data)) {
        //   this.SET_SNACKBAR({
        //     show: true,
        //     text: { text: `該時段已經有排程了，請確認時間`},
        //   });
        //   return;
        // }
        if (this.timeOrderCheck(data)) {
          this.SET_SNACKBAR({
            show: true,
            text: { text: `結束時間必須在開始時間之後`},
          });
          return;
        }

        this.$emit("createData", data);
        this.isDialog = false;
        this.resetData();
      }
    },
    timeOrderCheck(data) {
      let gate = false
      let strTimeStamp = new Date(data.start_at).getTime();
      let endTimeStamp = new Date(data.end_at).getTime();

      if(strTimeStamp> endTimeStamp){
        gate = true
      }
      return gate
    },
    transformData() {
      const vm = this;

      vm.marquee.start_at = `${vm.strDate} ${vm.strTime}`;
      vm.marquee.end_at = `${vm.strDate} ${vm.endTime}`;

      return this.marquee;
    },
    selectedImage(image) {
      this.marquee.poster = image.link;
    },
    resetData() {
      this.marquee = {
        text: "",
        url: "",
        start_at: "",
        end_at: "",
      };
      this.strDate = "";
      this.strTime = "";
      this.strDateModal = false;
      this.strTimeModal = false;
      this.endDate = "";
      this.endTime = "";
      this.endDateModal = false;
      this.endTimeModal = false;
      // 清空驗證錯誤訊息
      this.$refs.observer.reset();
    },
    // 判斷時間有沒有重複 true 有重複 false 沒重複
    timeRepeatGate(data) {
      let strTimeStamp = new Date(data.start_at).getTime();
      let endTimeStamp = new Date(data.end_at).getTime();
      var gate = true;

      this.data_.top.marquee.marquee_text.forEach((element) => {
        let str = new Date(element.start_at).getTime();
        let end = new Date(element.end_at).getTime();

        // 判斷時間不同疊

        // 沒在時間區間內
        let lessThanStr = !(str < strTimeStamp && strTimeStamp < end);
        let moreThanEnd = !(str < endTimeStamp && endTimeStamp < end);

        // 比時間大或小
        let afterStamp = end < strTimeStamp && end < endTimeStamp;
        let beforeStamp = str > strTimeStamp && str > endTimeStamp;

        if (!(lessThanStr && moreThanEnd && (afterStamp || beforeStamp))) {
          gate = false;
        }
      });

      return !gate;
    },
    ...mapMutations(["SET_SNACKBAR"]),
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  mounted() {},
  watch: {},
};
</script>