import Axios from 'axios'

export default {
  namespaced: true,
  strict: true,
  state: {
    data: {},
  },
  getters: {},
  actions: {
    getClose(context,payload = null) {
      let api = `${process.env.VUE_APP_API}/api/manage/exhibition/${payload.id}/close`
      const config = {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem('user')).accessToken,
        },
        actionUrl: 'close/getClose',
        actionPayload: payload
      }


      context.commit('SET_LOADING', true, { root: true })

      Axios.get(api, config)
        .then((response) => {
          context.commit('GET_CLOSE', response.data)
          context.commit('SET_LOADING', false, { root: true })
        })
        .catch((error) => {
          if (error.response.status !== 401) {
            if (error.response.status == 400) {
              context.commit('SET_SNACKBAR', {
                show: true,
                text: error.response.data
              }, { root: true })
            }

            context.commit('SET_LOADING', false, { root: true })
          }
        });
    },
  },
  mutations: {
    GET_CLOSE(state, payload) {
      state.data = payload
    }
  },
  modules: {
  }
}