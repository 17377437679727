<template>
  <div class="Exhibitions">
    <v-card class="rounded-lg">
      <div class="pa-3">
        <v-row>
          <v-col cols="6">
            <v-text-field
              type="text"
              dense
              outlined
              clearable
              append-icon="mdi-magnify"
              v-model="search"
              @click:append="searchAction(false)"
              @keyup.enter="searchAction(false)"
              @click:clear="searchAction(true)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="text-end" v-if="this.$store.state.user.data.permission == 99">
            <v-btn color="primary" @click.stop="exhibitionsCreateModal = true">
              <v-icon left dark> mdi-plus </v-icon>
              建立展會
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="pa-3">
        <v-simple-table fixed-header max-height="600px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left od-th" @click="setSort('name')">
                  名稱
                  <v-icon small v-if="sort == 'name'" :class="{ reverse: isReverse }">mdi-chevron-down</v-icon>
                </th>
                <th class="text-left">管理帳號</th>
                <th class="text-left">活動日期</th>
                <th class="text-left">容納人數</th>
                <th class="text-left od-th" @click="setSort('updated_at')">
                  更新時間
                  <v-icon small v-if="sort == 'updated_at'" :class="{ reverse: isReverse }">mdi-chevron-down</v-icon>
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in data" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.user.email }}</td>
                <td>{{ item.start_at | date }}~{{ item.end_at | date }}</td>
                <td>{{ item.capacity }}</td>
                <td>{{ item.updated_at | time }}</td>
                <td class="text-end">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item :to="`/exhibition/${item.id}`">
                        <v-list-item-icon>
                          <v-icon>mdi-magnify</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>詳細資訊</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :to="`/exhibition/${item.id}/entrance`">
                        <v-list-item-icon>
                          <v-icon>mdi-home-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>展會布置</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <template v-if="$store.state.user.data.permission == 99">
                        <v-list-item @click.stop="activeItem = item; exhibitionsDeleteModal = true">
                          <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>刪除</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>

    <div class="text-center py-4">
      <v-row justify="center">
        <v-col cols="8">
          <v-pagination
            circle
            v-model="isPage"
            class="my-4"
            :length="pageLength"
            @next=" ( isPage + 1 ) ? ( isPage + 1 ) : 1"
            @previous="( isPage - 1 ) ? ( isPage - 1 ) : 1"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
    <template v-if="$store.state.user.data.permission == 99">
      <ExhibitionsDeleteModal :dialog.sync="exhibitionsDeleteModal" :item="activeItem" @updateData="updateData"></ExhibitionsDeleteModal>
      <ExhibitionsCreateModal :dialog.sync="exhibitionsCreateModal"></ExhibitionsCreateModal>
    </template>
  </div>
</template>

<script>
import ExhibitionsDeleteModal from "@/components/modals/exhibitions/delete.vue";
import ExhibitionsCreateModal from "@/components/modals/exhibitions/create.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Exhibitions",
  components: {
    ExhibitionsDeleteModal,
    ExhibitionsCreateModal,
  },
  data() {
    return {
      exhibitionsDeleteModal: false,
      exhibitionsCreateModal: false,
      sort: '',
      search: '',
      isPage: 1,
      isReverse: false,
      activeItem: {}
    };
  },
  methods: {
    searchAction(isClear) {

      if(isClear){
        this.search = ''
      }
      this.getExhibitions({sort: this.sort, isReverse: this.isReverse, search: this.search})
    },
    setSort(mode) {
      const vm = this

      // 判斷是否第一次排序
      if (!vm.sort) {
        vm.sort = mode;
      } else {
        if (vm.sort == mode) {
          vm.isReverse = !vm.isReverse;
        } else {
          vm.sort = mode;
        }
      }
    },
    updateData() {
      this.getExhibitions({sort: this.sort, isReverse: this.isReverse, search: this.search})
    },
    ...mapActions("exhibitions", ["getExhibitions"]),
    ...mapMutations("exhibitions", ["SET_PAGE"])
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    this.getExhibitions({sort: this.sort, isReverse: this.isReverse, search: this.search})
  },
  computed: {
    ...mapState({
      data: state => state.exhibitions.data.items,
      page: state => state.exhibitions.data.page,
      pageLength: state => state.exhibitions.data.last_page
    })
  },
  watch: {
    isPage() {
      this.SET_PAGE(this.isPage)
      this.getExhibitions({sort: this.sort, isReverse: this.isReverse, search: this.search})
    },
    sort() {
      this.getExhibitions({sort: this.sort, isReverse: this.isReverse, search: this.search})
    },
    isReverse() {
      this.getExhibitions({sort: this.sort, isReverse: this.isReverse, search: this.search})
    }
  }
};
</script>

<style scoped>


</style>

