<template>
  <div class="Menu">
    <v-alert
      prominent
      v-if="user.permission_info && !user.permission_info.tab"
      type="warning"
      >您的權限不足</v-alert
    >
    <v-card left v-else>
      <v-card-text>
        <v-row class="py-8 justify-center">
          <v-col cols="12" md="7" class="text-center">
            <v-btn
              class="mb-4"
              color="primary"
              :disabled="data_.length >= 10"
              @click.stop="menuCreateModal = true"
            >
              <v-icon left dark> mdi-plus </v-icon>
              新增選單
            </v-btn>
            <p>最多新增10個選單</p>
            <template v-if="data_">
              <v-card v-for="(value, index) in data_" :key="index" class="mb-4">
                <v-card-title class="text-h6 justify-space-between">
                  {{ `${index + 1}` }}
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="
                          menuUpdateModal = true;
                          activeId = index;
                          activeItem = value;
                        "
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>編輯</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="removeMenu(index)">
                        <v-list-item-icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>刪除</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="moveSlider(index, true)">
                        <v-list-item-icon>
                          <v-icon>mdi-arrow-up</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>上移</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="moveSlider(index, false)">
                        <v-list-item-icon>
                          <v-icon>mdi-arrow-down</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>下移</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
                <v-card-text>
                  <div class="w-10 mx-auto" v-if="value.img || value.imgActive">
                    <div class="image-square">
                      <img
                        class="object-fit-cover cursor-pointer"
                        :src="hover[index] ? value.imgActive : value.img"
                        @mouseover="hover[index] = true"
                        @mouseleave="hover[index] = false"
                      />
                    </div>
                  </div>
                  <a
                    target="_blank"
                    :href="
                      value.external
                        ? value.url
                        : `${queck_token + '#' + value.url}`
                    "
                  >
                    <p class="mt-4">{{ value.name }}</p>
                  </a>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="resetData">
          <v-icon left dark> mdi-cached </v-icon>
          復原
        </v-btn>
        <v-btn target="_blank" :href="queck_token">
          <v-icon left dark> mdi-card-search </v-icon>
          預覽
        </v-btn>
        <v-btn
          @click="update"
          color="primary"
          :loading="this.$store.state.partialLoding"
          :disabled="this.$store.state.partialLoding"
        >
          <v-icon left dark> mdi-content-save </v-icon>
          儲存
        </v-btn>
      </v-card-actions>
      <MenuCreateModal
        :dialog.sync="menuCreateModal"
        @createData="createMenu"
      ></MenuCreateModal>
      <MenuUpdateModal
        :dialog.sync="menuUpdateModal"
        :data="activeItem"
        :id="activeId"
        @updateData="updateMenu"
      ></MenuUpdateModal>
      <MediaModal :dialog.sync="$store.state.mediaModal"></MediaModal>
    </v-card>
  </div>
</template>

<script>
import MediaModal from "@/components/modals/media.vue";
import MenuCreateModal from "@/components/modals/menu/create.vue";
import MenuUpdateModal from "@/components/modals/menu/update.vue";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "Menu",
  components: {
    MediaModal,
    MenuCreateModal,
    MenuUpdateModal,
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  data() {
    return {
      id: this.$route.params.id,
      menuCreateModal: false,
      menuUpdateModal: false,
      hover: [],
      data_: {},
      activeItem: "",
      activeId: "",
    };
  },
  methods: {
    resetData() {
      this.getTab({ id: this.id });
    },
    createMenu(item) {
      let index = this.data_.length ? this.data_.length : 0;
      this.data_[index] = item;
    },
    updateMenu(index, value) {
      this.data_[index] = value;
    },
    removeMenu(id) {
      this.data_ = this.data_.filter(function (value, index) {
        return index !== id;
      });
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    moveSlider(id, direction) {
      if (direction) {
        if (id == 0) {
          return;
        }
        this.data_ = this.swapItems(this.data_, id, id - 1);
      } else {
        if (id == this.data_.length - 1) {
          return;
        }
        this.data_ = this.swapItems(this.data_, id, id + 1);
      }
    },
    update() {
      const vm = this;

      vm.updateTab({ id: vm.id, data: this.data_ }).then(() => {
        this.getTab({ id: this.id });

        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    ...mapActions("tab", ["getTab"]),
    ...mapActions("updateTab", ["updateTab"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
  mounted() {
    this.getTab({ id: this.id });
  },
  computed: {
    ...mapState({
      data: (state) => state.tab.data,
      queck_token: (state) => {
        return state.exhibition.data.custom_backend ? `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}` : ''
      },
      user: (state) => state.user.data,
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>


<style scoped>
.w-10 {
  width: 10%;
}
</style>