<template>
  <div class="membersUpdatePasswordModal">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 變更密碼 </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <v-col cols="12" class="py-0">
                  <!-- <validation-provider
                    v-slot="{ errors }"
                    name="名稱"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data.name"
                      :error-messages="errors"
                      type="text"
                      placeholder="名稱"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        名稱
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="data.email"
                      :error-messages="errors"
                      type="email"
                      placeholder="Email"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        Email
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="電話"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data.phone"
                      :error-messages="errors"
                      type="tel"
                      placeholder="電話"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        電話
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="公司"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data.org"
                      :error-messages="errors"
                      type="text"
                      placeholder="公司"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        公司
                      </template>
                    </v-text-field>
                  </validation-provider> -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="密碼"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data.password"
                      :error-messages="errors"
                      type="password"
                      placeholder="密碼"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        密碼
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="密碼確認"
                    :rules="'required|is:' + data.password"
                  >
                    <v-text-field
                      v-model="passwordCheck"
                      :error-messages="errors"
                      type="password"
                      placeholder="密碼確認"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        密碼確認
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="isDialog = false"> 取消 </v-btn>

          <v-btn
            color="primary"
            @click="validateForm"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import MemberModel from "@/models/member";

export default {
  name: "membersUpdatePasswordModal",
  data() {
    return {
      id: this.$route.params.id,
      member_id: "",
      passwordCheck: '',
      data: new MemberModel("", "", "", "", ""),
    };
  },
  props: {
    dialog: Boolean,
    item: {},
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.updateMember({
          id: vm.id,
          member_id: vm.member_id,
          data: vm.data,
        }).then(() => {
          vm.successTodo();
        });
      }
    },
    successTodo() {
      this.isDialog = false;
      this.$emit("updateData");
      this.resetData()
      this.SET_SUCCESS_SNACKBAR({
        show: true,
        text: `成功儲存 ${this.item.email}`,
      });
    },
    resetData(){
      this.data = new MemberModel("", "", "", "", ""),
      // 清空驗證錯誤訊息
      this.$refs.observer.reset()
    },
    ...mapActions("updateMember", ["updateMember"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
  watch: {
    item() {
      this.data.name = this.item.name;
      this.data.email = this.item.email;
      this.data.password = this.item.password;
      this.data.org = this.item.org;
      this.data.phone = this.item.phone;
      this.member_id = this.item.id;
    },
  },
};
</script>