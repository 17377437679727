<template>
  <v-navigation-drawer permanent app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6 mb-2"
          >線上展會管理平台</v-list-item-title
        >
        <v-list-item-subtitle> {{ data.name }} </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list>
      <v-list-group
        v-for="(item, index) in items"
        :key="index"
        :value="checkMenuActive(item)"
        :prepend-icon="item.icon"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(child, index) in item.items"
          :key="index"
          :disabled="child.disabled"
          @click="goToRoute(child.link)"
          :input-value="checkRouteActive(child.link)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <template v-slot:append>
      <v-divider></v-divider>
      <div class="pa-2">
        <v-btn block depressed to="/exhibition">
          <v-icon left > mdi-chevron-left </v-icon>
          回展會管理
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      items: [
        {
          icon: "mdi-home-variant",
          title: "會場佈置",
          items: [
            {
              title: "入口大廳",
              link: `/exhibition/${this.$route.params.id}/entrance`,
              // disabled: this.$store.state.user.data.permission_info.entrance ? false : true
            },
            {
              title: "選單",
              link: `/exhibition/${this.$route.params.id}/menu`,
              // disabled: this.$store.state.user.data.permission_info.tab ? false : true
            },
            {
              title: "非會期畫面",
              link: `/exhibition/${this.$route.params.id}/outside`,
              // disabled: this.$store.state.user.data.permission_info.login ? false : true
            },
            {
              title: "登入畫面",
              link: `/exhibition/${this.$route.params.id}/login`,
              // disabled: this.$store.state.user.data.permission_info.login ? false : true
            },
            {
              title: "FBMessage設定",
              link: `/exhibition/${this.$route.params.id}/fbMessage`,
              // disabled: this.$store.state.user.data.permission_info.login ? false : true
            },
          ],
        },
        {
          icon: "mdi-view-dashboard",
          title: "展會模組",
          items: [
            {
              title: "演講廳",
              link: `/exhibition/${this.$route.params.id}/lecture`,
              // disabled: this.$store.state.user.data.permission_info.lecture ? false : true
            },
            {
              title: "分會場",
              link: `/exhibition/${this.$route.params.id}/venue`,
              // disabled: this.$store.state.user.data.permission_info.venue ? false : true
            },
            {
              title: "展示區",
              link: `/exhibition/${this.$route.params.id}/area`,
              // disabled: this.$store.state.user.data.permission_info.area ? false : true
            },
          ],
        },
        {
          icon: "mdi-account-multiple",
          title: "名單管理",
          items: [
            {
              title: "名單管理",
              link: `/exhibition/${this.$route.params.id}/member`,
              disabled: false
            },
          ],
        },
        // {
        //   icon: "mdi-email",
        //   title: "活動邀請",
        //   items: [
        //     {
        //       title: "郵件發送",
        //       link: `/exhibition/${this.$route.params.id}/entrancex`,
        //     },
        //     {
        //       title: "簡訊發送",
        //       link: `/exhibition/${this.$route.params.id}/entrancex`,
        //     },
        //     {
        //       title: "發送紀錄",
        //       link: `/exhibition/${this.$route.params.id}/entrancex`,
        //     },
        //   ],
        // },
        {
          icon: "mdi-chart-bar",
          title: "數據分析",
          items: [
            {
              title: "數據儀錶板",
              link: `/exhibition/${this.$route.params.id}/statistics`,
            },
          ],
        },
      ],
    };
  },
  methods: {
    goToRoute(link) {
      if (this.$route.fullPath !== link) {
        this.$router.push(link);
      }
    },
    checkRouteActive(link) {
      return this.$route.fullPath == link;
    },
    checkMenuActive(item) {
      let active = false;

      item.items.forEach((element) => {
        if (this.$route.fullPath == element.link) {
          active = true;
          return;
        }
      });

      return active;
    },
    ...mapActions("exhibition", ["getExhibition","getUser"]),
  },
  mounted() {
    // 獲得展會資訊
    this.getExhibition({ id: this.$route.params.id });
  },
  computed: {
    ...mapState({
      data: (state) => state.exhibition.data,
    }),
  },
};
</script>
