<template>
  <v-container class="h-100">
    <v-row class="justify-center align-content-center h-100">
      <v-col cols="12">
        <v-card elevation="2" class="mx-auto" max-width="375">
          <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
            height="200px"
          ></v-img>
          <v-card-text>
            <validation-observer ref="observer">
              <v-form @submit.prevent="validateForm">
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="account"
                      rules="required"
                    >
                      <v-text-field
                        v-model="data.username"
                        :error-messages="errors"
                        type="帳號"
                        placeholder="帳號"
                        outlined
                        dense
                        clearable
                        append-icon="mdi-account"
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          帳號
                        </template>
                      </v-text-field>
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      rules="required"
                    >
                      <v-text-field
                        v-model="data.password"
                        type="password"
                        :error-messages="errors"
                        placeholder="請輸入電話末三碼"
                        outlined
                        dense
                        clearable
                        append-icon="mdi-lock"
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          密碼
                        </template>
                      </v-text-field>
                    </validation-provider>
                    <v-checkbox
                      class="mt-0"
                      v-model="data.remember"
                      label="記住我"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      large
                      type="submit"
                      color="primary"
                      :loading="this.$store.state.partialLoding"
                      :disabled="this.$store.state.partialLoding"
                    >
                      登入
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="text-end">
                    <v-btn text> 忘記密碼 </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      data: {
        username: "",
        password: "",
        remember: false,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "empty-layout");

    
    // 網址直接導入該頁面 確認有無登入
    if(this.$localStorage !== undefined && this.$localStorage.getWithExpiry('user')){
      this.$router.push(`/${process.env.VUE_APP_HOME_ROUTE}`)
    }
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.login(vm.data).then(res => (
          res ? this.$router.push(`/${process.env.VUE_APP_HOME_ROUTE}`) : ''
        ))
      }
    },
    ...mapActions("auth", ["login"]),
  },
};
</script>