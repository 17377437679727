<template>
  <v-row class="justify-center align-content-center h-100">
    <v-col cols="12">
      <div class="pa-2 text-center">
        <h1 class="font-weight-black accent--text" style="font-size: 10rem">404</h1>
        <v-btn class="mx-2 mt-10" fab dark small color="primary" to="/login">
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Other",
  created() {
    this.$store.commit("SET_LAYOUT", "empty-layout");
  },
};
</script>
