<template>
  <div class="exhibitionsUpdateModal">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 編輯展會 </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="活動名稱"
                    rules="required|max:20"
                  >
                    <v-text-field
                      v-model="data.name"
                      :error-messages="errors"
                      type="text"
                      placeholder="20字以內名稱"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        活動名稱
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <template v-if="this.$store.state.user.data.permission == 99">
                  <v-col cols="12" class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="容納人數"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="data.capacity"
                        :error-messages="errors"
                        type="number"
                        placeholder="容納人數*"
                        min="0"
                        max="999"
                        outlined
                        dense
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          容納人數
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-dialog
                      ref="strDatePickerDialog"
                      v-model="strDateModal"
                      :return-value.sync="strDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="開始日期"
                          rules="required"
                        >
                          <v-text-field
                            v-model="strDate"
                            append-icon="mdi-calendar"
                            :error-messages="errors"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template v-slot:label>
                              <v-icon x-small color="error" class="align-middle"
                                >mdi-asterisk</v-icon
                              >
                              開始日期
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker v-model="strDate" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="strDateModal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.strDatePickerDialog.save(strDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <!-- <v-col cols="6" class="py-0">
                    <v-dialog
                      ref="strTimePickerdialog"
                      v-model="strTimeModal"
                      :return-value.sync="strTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="開始時間"
                          rules="required"
                        >
                          <v-text-field
                            v-model="strTime"
                            label="開始時間"
                            append-icon="mdi-clock-time-four-outline"
                            :error-messages="errors"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template v-slot:label>
                              <v-icon x-small color="error" class="align-middle"
                                >mdi-asterisk</v-icon
                              >
                              開始時間
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </template>
                      <v-time-picker
                        v-if="strTimeModal"
                        v-model="strTime"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="strTimeModal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.strTimePickerdialog.save(strTime)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col> -->
                  <v-col cols="6" class="py-0">
                    <v-dialog
                      ref="endDatePickerDialog"
                      v-model="endDateModal"
                      :return-value.sync="endDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="結束日期"
                          rules="required"
                        >
                          <v-text-field
                            v-model="endDate"
                            append-icon="mdi-calendar"
                            :error-messages="errors"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template v-slot:label>
                              <v-icon x-small color="error" class="align-middle"
                                >mdi-asterisk</v-icon
                              >
                              結束日期
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        scrollable
                        :min="strDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="endDateModal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.endDatePickerDialog.save(endDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <!-- <v-col cols="6" class="py-0">
                    <v-dialog
                      ref="endTimePickerdialog"
                      v-model="endTimeModal"
                      :return-value.sync="endTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="結束時間"
                          rules="required"
                        >
                          <v-text-field
                            v-model="endTime"
                            label="結束時間"
                            append-icon="mdi-clock-time-four-outline"
                            :error-messages="errors"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template v-slot:label>
                              <v-icon x-small color="error" class="align-middle"
                                >mdi-asterisk</v-icon
                              >
                              結束時間
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </template>
                      <v-time-picker
                        v-if="endTimeModal"
                        v-model="endTime"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="endTimeModal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.endTimePickerdialog.save(endTime)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col> -->

                  <v-col cols="12" class="py-0">
                    <v-select
                      v-model="data.uid"
                      :items="accounts"
                      item-text="email"
                      item-value="id"
                      label="管理帳號"
                      outlined
                      dense
                    ></v-select>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="展會網址"
                      rules="required|alpha_dash"
                    >
                      <v-text-field
                        v-model="data.code"
                        :error-messages="errors"
                        type="email"
                        placeholder="展會網址"
                        suffix="-digievent.ictsage.com"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          展會網址
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="isDialog = false"> 取消 </v-btn>
          <v-btn
            @click="validateForm"
            color="primary"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import ExhibitionModel from "@/models/exhibition";

export default {
  name: "exhibitionsUpdateModal",
  data() {
    return {
      id: this.$route.params.id,
      data: new ExhibitionModel("", "", "", "", "", ""),
      strDate: "",
      strDateModal: false,
      endDate: "",
      endDateModal: false,
    };
  },
  props: {
    data_: Object,
    dialog: Boolean,
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
    ...mapState({
      accounts: (state) => state.accounts.data.items,
    }),
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.transformData();
        vm.updateExhibition({ id: vm.id, data: vm.data }).then((response) => {
          vm.successTodo(response);
        });
      }
    },
    transformData() {
      const vm = this;
      vm.data.start_at = `${vm.strDate} 00:00:00`;
      vm.data.end_at = `${vm.endDate} 23:59:00`;
    },
    successTodo() {
      this.isDialog = false;
      this.SET_SUCCESS_SNACKBAR({
        show: true,
        text: `成功儲存 ${this.data.name}`,
      });
      this.$emit("updateData");
    },
    transformOrignalData() {
      this.data.name = this.data_.name;
      this.data.capacity = this.data_.capacity;
      this.data.uid = this.data_.user.id;
      this.data.code = this.data_.code;
      this.strDate = this.data_.start_at.substring(0, 10);
      this.endDate = this.data_.end_at.substring(0, 10);
    },
    ...mapActions("updateExhibition", ["updateExhibition"]),
    ...mapActions("accounts", ["getAccounts"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
  mounted() {
    if (this.$store.state.user.data.permission == 99) {
      this.getAccounts();
    }
    this.transformOrignalData();
  },
};
</script>