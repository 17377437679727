var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"buttonALoginPage",attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8 justify-center"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"w-25 mx-auto"},[_c('div',{staticClass:"image-square pt-29"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.data_.loginBtn.hover
                      ? _vm.data_.loginBtn.imageActive
                      : _vm.data_.loginBtn.image,"alt":_vm.data_.loginBtn.name},on:{"mouseover":function($event){_vm.data_.loginBtn.hover = true},"mouseleave":function($event){_vm.data_.loginBtn.hover = false}}})])]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v(" 建議尺寸510 x 152像素，PNG格式 ")]),_c('validation-provider',{attrs:{"name":"登入按鈕","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"Banner","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'login';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 登入按鈕 ")]},proxy:true}],null,true),model:{value:(_vm.data_.loginBtn.image),callback:function ($$v) {_vm.$set(_vm.data_.loginBtn, "image", $$v)},expression:"data_.loginBtn.image"}})]}}])}),_c('validation-provider',{attrs:{"name":"登入按鈕(滑入)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"Banner","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'loginActive';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 登入按鈕(滑入) ")]},proxy:true}],null,true),model:{value:(_vm.data_.loginBtn.imageActive),callback:function ($$v) {_vm.$set(_vm.data_.loginBtn, "imageActive", $$v)},expression:"data_.loginBtn.imageActive"}})]}}])})],1),_c('v-col',{staticClass:"text-center mt-4",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"w-25 mx-auto"},[_c('div',{staticClass:"image-square pt-29"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.data_.resetBtn.hover
                      ? _vm.data_.resetBtn.imageActive
                      : _vm.data_.resetBtn.image,"alt":_vm.data_.resetBtn.name},on:{"mouseover":function($event){_vm.data_.resetBtn.hover = true},"mouseleave":function($event){_vm.data_.resetBtn.hover = false}}})])]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v(" 建議尺寸510 x 152像素，PNG格式 ")]),_c('validation-provider',{attrs:{"name":"驗證按鈕","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"Banner","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'reset';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 驗證按鈕 ")]},proxy:true}],null,true),model:{value:(_vm.data_.resetBtn.image),callback:function ($$v) {_vm.$set(_vm.data_.resetBtn, "image", $$v)},expression:"data_.resetBtn.image"}})]}}])}),_c('validation-provider',{attrs:{"name":"驗證按鈕(滑入)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"Banner","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'resetActive';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 驗證按鈕(滑入) ")]},proxy:true}],null,true),model:{value:(_vm.data_.resetBtn.imageActive),callback:function ($$v) {_vm.$set(_vm.data_.resetBtn, "imageActive", $$v)},expression:"data_.resetBtn.imageActive"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.resetData}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cached ")]),_vm._v(" 復原 ")],1),_c('v-btn',{attrs:{"target":"_blank","href":(_vm.dev_url + "#/login")}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-card-search ")]),_vm._v(" 預覽 ")],1),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 儲存 ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }