<template>
  <v-card flat class="stallAreaPage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-row class="pt-8 justify-center">
          <v-col cols="12" md="7" class="text-center">
            <v-btn
              class="mb-4"
              color="primary"
              @click.stop="stallAreaCreateModal = true"
            >
              <v-icon left dark> mdi-plus </v-icon>
              新增攤位
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pb-8">
          <template v-if="data_.area">
            <v-col
              cols="12"
              md="4"
              class="text-center"
              v-for="(value, index) in data_.area"
              :key="index"
            >
              <v-card>
                <v-card-title class="text-h6 justify-space-between">
                  {{ value.name }}

                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="
                          stallAreaUpdateModal = true;
                          activeId = index;
                          activeItem = value;
                        "
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>編輯</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="removeStall(index)">
                        <v-list-item-icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>刪除</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="moveStall(index, true)">
                        <v-list-item-icon>
                          <v-icon>mdi-arrow-up</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>上移</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="moveStall(index, false)">
                        <v-list-item-icon>
                          <v-icon>mdi-arrow-down</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>下移</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
                <v-card-text class="position-relative od-card">
                  <p class="mb-0">桌機</p>
                  <div class="image-square pt-67 mb-8">
                    <img
                      class="object-fit-cover"
                      :src="value.stall_img"
                      alt=""
                    />
                  </div>
                  <p class="mb-0">手機</p>
                  <div class="image-square pt-30">
                    <img class="object-fit-cover" :src="value.logo" alt="" />
                  </div>
                  <div class="position-absolute od-back pa-3" v-if="value.text">
                    <p class="text-body">{{ value.text }}</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="`${queck_token}?path=area`">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
    <StallAreaCreateModal
      :dialog.sync="stallAreaCreateModal"
      @createData="createStall"
    ></StallAreaCreateModal>
    <StallAreaUpdateModal
      :dialog.sync="stallAreaUpdateModal"
      :data="activeItem"
      :id="activeId"
      @updateData="updateStall"
    ></StallAreaUpdateModal>
  </v-card>
</template>

<script>
import StallAreaCreateModal from "@/components/modals/area/stall/create.vue";
import StallAreaUpdateModal from "@/components/modals/area/stall/update.vue";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "stallAreaPage",
  props: {
    data: {},
  },
  components: {
    StallAreaCreateModal,
    StallAreaUpdateModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      stallAreaCreateModal: false,
      stallAreaUpdateModal: false,
      activeItem: "",
      activeId: "",
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    resetData() {
      this.$emit("update");
    },
    update() {
      const vm = this;

      vm.updateArea({ id: vm.id, data: this.data_ }).then(() => {
        this.$emit("update");
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    removeStall(index) {
      this.data_.area = this.data_.area.filter(function (v, i) {
        return index !== i;
      });
    },
    createStall(data) {
      let index = 0;
      if (this.data_.area == undefined) {
        index = 0;
        this.data_.area = [];
      } else {
        index = this.data_.area.length;
      }
      this.data_.area[index] = { ...data };
    },
    updateStall(index, value) {
      this.data_.area[index] = { ...value };
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    moveStall(id, direction) {
      if (direction) {
        if (id == 0) {
          return;
        }
        this.data_.area = this.swapItems(this.data_.area, id, id - 1);
      } else {
        if (id == this.data_.area.length - 1) {
          return;
        }
        this.data_.area = this.swapItems(this.data_.area, id, id + 1);
      }
    },
    ...mapActions("updateArea", ["updateArea"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => {
        return state.exhibition.data.custom_backend ? `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}` : ''
      }
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style lang="scss" scoped>
.pt-67 {
  padding-top: 67%;
}
.pt-30 {
  padding-top: 30%;
}
.od-back {
  color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
  display: none;
}
.od-card:hover {
  .od-back {
    display: block !important;
  }
}
</style>