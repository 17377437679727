var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"stageLecturePage",attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8 justify-center"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('p',[_vm._v("舞台立板")]),_c('div',{staticClass:"w-50 mx-auto"},[_c('div',{staticClass:"image-square pt-15"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.data_.bottom.sign_img,"alt":""}})])]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v(" 建議尺寸1680 x 409像素，PNG格式 ")]),_c('validation-provider',{attrs:{"name":"舞台立板","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-8 pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"舞台立板","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'sign_img';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 舞台立板 ")]},proxy:true}],null,true),model:{value:(_vm.data_.bottom.sign_img),callback:function ($$v) {_vm.$set(_vm.data_.bottom, "sign_img", $$v)},expression:"data_.bottom.sign_img"}})]}}])})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('p',[_vm._v("舞台圖片")]),_c('div',{staticClass:"w-50 mx-auto"},[_c('div',{staticClass:"image-square pt-12"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.data_.bottom.stage_img,"alt":""}})])]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v(" 建議尺寸5760 x 732像素，PNG格式 ")]),_c('validation-provider',{attrs:{"name":"舞台圖片","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-8 pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"舞台圖片","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'stage_img';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 舞台圖片 ")]},proxy:true}],null,true),model:{value:(_vm.data_.bottom.stage_img),callback:function ($$v) {_vm.$set(_vm.data_.bottom, "stage_img", $$v)},expression:"data_.bottom.stage_img"}})]}}])})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('p',[_vm._v("演講者圖片")]),_c('div',{staticClass:"w-50 mx-auto"},[_c('div',{staticClass:"image-square pt-28"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.data_.bottom.speaker_img,"alt":""}})])]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v(" 建議尺寸3610 x 1016像素，PNG格式 ")]),_c('validation-provider',{attrs:{"name":"演講者圖片","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-8 pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"演講者圖片","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'speaker_img';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 演講者圖片 ")]},proxy:true}],null,true),model:{value:(_vm.data_.bottom.speaker_img),callback:function ($$v) {_vm.$set(_vm.data_.bottom, "speaker_img", $$v)},expression:"data_.bottom.speaker_img"}})]}}])})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('p',[_vm._v("觀眾圖片")]),_c('div',{staticClass:"w-50 mx-auto"},[_c('div',{staticClass:"image-square pt-15"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.data_.bottom.audience_img,"alt":""}})])]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v(" 建議尺寸5769 x 534像素，PNG格式 ")]),_c('validation-provider',{attrs:{"name":"觀眾圖片","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-8 pt-8",attrs:{"error-messages":errors,"type":"text","placeholder":"觀眾圖片","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'audience_img';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 觀眾圖片 ")]},proxy:true}],null,true),model:{value:(_vm.data_.bottom.audience_img),callback:function ($$v) {_vm.$set(_vm.data_.bottom, "audience_img", $$v)},expression:"data_.bottom.audience_img"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.resetData}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cached ")]),_vm._v(" 復原 ")],1),_c('v-btn',{attrs:{"target":"_blank","href":(_vm.queck_token + "?path=lecture")}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-card-search ")]),_vm._v(" 預覽 ")],1),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 儲存 ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }