<template>
  <div class="backgroundEntrancePage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <p>桌機背景</p>
              <img
                class="mw-100 hauto"
                :src="data_.background.desktop"
                alt=""
              />
              <p class="text--secondary caption pt-2">建議尺寸2000 x 1125像素，PNG格式</p>

              <validation-provider
                v-slot="{ errors }"
                name="桌機背景"
                rules="required"
              >
                <v-text-field
                  v-model="data_.background.desktop"
                  :error-messages="errors"
                  type="text"
                  class="pt-8"
                  placeholder="桌機背景"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'desktop';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    桌機背景
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="7" class="text-center">
              <p>手機背景</p>
              <img
                class="mw-100 hauto"
                :src="data_.background.mobile"
                alt=""
              />
              <p class="text--secondary caption pt-2">建議尺寸1200 x 154 像素，PNG格式</p>


              <validation-provider
                v-slot="{ errors }"
                name="手機背景"
                rules="required"
              >
                <v-text-field
                  v-model="data_.background.mobile"
                  :error-messages="errors"
                  type="text"
                  class="pt-8"
                  placeholder="手機背景"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'mobile';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    手機背景
                  </template>
                </v-text-field>
              </validation-provider>

            </v-col>
            <v-col cols="12" md="7" class="text-center">
              <p>手機背景顏色</p>
              <v-color-picker v-model="data_.background.bgcolor" class="mx-auto" mode="hexa"></v-color-picker>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="queck_token">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "backgroundEntrancePage",
  props: {
    data: {},
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      selected: ''
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    update() {
      const vm = this;

      vm.updateEntrance({ id: vm.id, data: this.data_ }).then(() => {
        this.resetData();
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    resetData() {
      this.$emit("update");
    },
    ...mapActions("updateEntrance", ["updateEntrance"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR","SET_MEDIA_MODAL"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`,
    }),
  },
  mounted() {},
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
    "$store.state.media.selected": function() {
      switch(this.selected){
        case 'desktop': {
          this.data_.background.desktop = this.$store.state.media.selected.link;
          return;
        }
        case "mobile": {
          this.data_.background.mobile = this.$store.state.media.selected.link;
          return;
        }
      }
    }
  },
};
</script>

<style scoped>
.pt-40px{
  padding-top: 40px !important;
}
.object-position-top{
  object-position: top !important;
}
</style>