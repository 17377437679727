<template>
  <v-card flat class="messageLoginPage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <validation-provider
                v-slot="{ errors }"
                name="帳號輸入提示"
                rules="required"
              >
                <v-text-field
                  v-model="data_.username_hint"
                  :error-messages="errors"
                  type="text"
                  placeholder="帳號輸入提示"
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    帳號輸入提示
                  </template>
                </v-text-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="密碼輸入提示"
                rules="required"
              >
                <v-text-field
                  v-model="data_.password_hint"
                  :error-messages="errors"
                  type="text"
                  placeholder="密碼輸入提示"
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    密碼輸入提示
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="`${dev_url}#/login`">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "messageLoginPage",
  props: {
    data: {},
  },
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    resetData() {
      this.$emit("update");
    },
    update() {
      const vm = this;

      vm.updateLogin({ id: vm.id, data: this.data_ }).then(() => {
        this.$emit("update");
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    selectedImage(image) {
      this.data_.banner = image.link;
    },
    ...mapActions("updateLogin", ["updateLogin"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
  computed: {
    ...mapState({
      dev_url: (state) => state.exhibition.data.frontend_dev_url,
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style scoped>
.pt-29 {
  padding-top: 29%;
}
</style>