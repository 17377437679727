import Axios from 'axios'

export default {
  namespaced: true,
  strict: true,
  state: {
    data: {
    },
  },
  getters: {},
  actions: {
    exportStats(context, payload) {
      let api = `${process.env.VUE_APP_API}/api/manage/exhibition/${payload.id}/stats/export`
      const config = {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem('user')).accessToken,
        },
        actionUrl: 'exportStats/exportStats',
        actionPayload: payload
      }

      context.commit('SET_LOADING', true, { root: true })

      return new Promise((resolve, reject) => {
        Axios.get(api, config)
          .then((response) => {
            context.commit('SET_LOADING', false, { root: true })
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status !== 401) {
              if (error.response.status == 400) {
                context.commit('SET_SNACKBAR', {
                  show: true,
                  text: error.response.data
                }, { root: true })
              }

              context.commit('SET_LOADING', false, { root: true })
            }
            reject(error);
          });

      })
    },
  },
  mutations: {
  },
  modules: {
  }
}