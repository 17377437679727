<template>
  <div class="">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 編輯會員資訊 </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <template v-if="type == 'value'">
                  <v-col cols="12" class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="組織"
                      rules="required|max:20"
                    >
                      <v-text-field
                        v-model="data.org"
                        :error-messages="errors"
                        type="text"
                        placeholder="組織"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          組織
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <validation-provider v-slot="{ errors }" name="統一編號">
                      <v-text-field
                        v-model="data.serial"
                        :error-messages="errors"
                        type="text"
                        placeholder="統一編號"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label> 統一編號 </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </template>

                <template v-if="type == 'password'">
                  <v-col cols="12" class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="新密碼"
                      rules="required"
                    >
                      <v-text-field
                        v-model="data.password"
                        :error-messages="errors"
                        type="password"
                        placeholder="新密碼"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          新密碼
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="新密碼確認"
                      :rules="'required|is:' + data.password"
                    >
                      <v-text-field
                        v-model="passwordCheck"
                        :error-messages="errors"
                        type="password"
                        placeholder="新密碼確認"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          新密碼確認
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="isDialog = false"> 取消 </v-btn>
          <v-btn
            color="primary"
            @click="validateForm"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
// import AccountModel from "@/models/account";

export default {
  name: "profileUpdateModal",
  data() {
    return {
      id: this.$route.params.id,
      strDateModal: false,
      endDateModal: false,
      passwordCheck: "",
      data: { ...this.data_ },
    };
  },
  props: {
    dialog: Boolean,
    data_: {},
    type: {},
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.updateUser({ data: vm.data }).then((response) => {
          vm.successTodo(response);
        });
      }
    },
    successTodo(res) {
      this.isDialog = false;
      this.SET_SUCCESS_SNACKBAR({
        show: true,
        text: `成功儲存會員資訊 ${res.data.org}`,
      });
      this.$emit("updateData");
    },
    ...mapActions("updateUser", ["updateUser"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
};
</script>