import Axios from 'axios'

export default {
  namespaced: true,
  strict: true,
  state: {
    data: {},
  },
  getters: {},
  actions: {
    getExhibition(context, payload) {
      let api = `${process.env.VUE_APP_API}/api/manage/exhibition/${payload.id}`
      const config = {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem('user')).accessToken,
        },
        actionUrl: 'exhibition/getExhibition',
        actionPayload: payload
      }

      context.commit('SET_LOADING', true, { root: true })

      return new Promise((resolve, reject) => {
        Axios.get(api, config)
          .then((response) => {

            context.commit('GET_EXHIBITION', response.data)
            context.commit('SET_LOADING', false, { root: true })
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status !== 401) {
              if (error.response.status == 400) {
                context.commit('SET_SNACKBAR', {
                  show: true,
                  text: error.response.data
                }, { root: true })
              }

              context.commit('SET_LOADING', false, { root: true })
            }
            reject(error);
          });
      })
    },
  },
  mutations: {
    GET_EXHIBITION(state, payload) {
      state.data = payload
    },
  },
  modules: {
  }
}