<template>
  <div class="scenesEntrancePage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7">
              <v-card>
                <v-card-title class="text--body justify-space-between py-0">
                  接待處
                  <v-switch
                    v-model="data_.table.display"
                    inset
                    :label="'顯示'"
                  ></v-switch>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-center" v-if="data_.table.display">
                  <div class="w-50 mx-auto position-relative od_table">
                    <div class="image-square pt-61">
                      <img
                        class="object-fit-cover"
                        :src="data_.table.tableImage"
                        alt=""
                      />
                    </div>
                    <img
                      class="position-absolute od_table__logo mw-100"
                      :src="data_.table.logoImage"
                      alt=""
                    />
                  </div>
                  <p class="text--secondary caption pt-2">
                    建議尺寸<br />
                    桌子：1024 x 626像素，PNG格式<br />
                    logo：931 x 345像素，PNG格式
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="接待處圖片連結"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data_.table.tableImage"
                      :error-messages="errors"
                      type="text"
                      class="pt-8"
                      placeholder="接待處圖片連結"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'table_image';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        接待處連結
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="接待處logo連結"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data_.table.logoImage"
                      :error-messages="errors"
                      type="text"
                      placeholder="接待處logo連結"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'table_logo';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        接待處logo連結
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="7">
              <v-card>
                <v-card-title class="text--body justify-space-between py-0">
                  攤位區
                  <v-switch
                    v-model="data_.session.display"
                    inset
                    :label="'顯示'"
                  ></v-switch>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-center" v-if="data_.session.display">
                  <div class="w-50 mx-auto position-relative od_session mb-4">
                    <div class="image-square od_session__img pt-38 mx-auto">
                      <img
                        class="object-fit-cover"
                        :src="data_.session.entrance"
                        alt=""
                      />
                    </div>
                    <img
                      class="position-absolute od_session__logo mw-100"
                      :src="data_.session.deco"
                      alt=""
                    />
                  </div>
                  <p class="text--secondary caption pt-2">
                    建議尺寸<br />
                    攤位圖：1024 x 675像素，PNG格式<br />
                    人群：512 x 182像素，PNG格式
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="攤位區圖片連結"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data_.session.entrance"
                      :error-messages="errors"
                      type="text"
                      class="pt-8"
                      placeholder="攤位區圖片連結"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'session_entrance';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        攤位區圖片連結
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="接待處logo連結"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data_.session.deco"
                      :error-messages="errors"
                      type="text"
                      placeholder="接待處logo連結"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'session_deco';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        接待處logo連結
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <v-select
                    v-model="sessionType"
                    :items="linkType"
                    item-text="name"
                    item-value="value"
                    label="連結"
                    outlined
                    dense
                  ></v-select>

                  <validation-provider
                    v-slot="{ errors }"
                    name="自訂連結"
                    rules="required"
                    v-if="sessionType == 'order'"
                  >
                    <v-text-field
                      v-model="data_.session.url"
                      :error-messages="errors"
                      type="text"
                      placeholder="自訂連結"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        自訂連結
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="7">
              <v-card>
                <v-card-title class="text--body justify-space-between py-0">
                  指示牌
                  <v-switch
                    v-model="data_.sign.display"
                    inset
                    :label="'顯示'"
                  ></v-switch>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-center" v-if="data_.sign.display">
                  <div class="w-50 mx-auto position-relative od_sign mb-4">
                    <div class="image-square od_sign__back mx-auto w-50">
                      <img
                        class="object-fit-cover"
                        :src="data_.sign.bgImage"
                        alt=""
                      />
                    </div>
                    <img
                      class="position-absolute od_sign__img mw-100"
                      :src="data_.sign.screenImage"
                      alt=""
                    />
                  </div>
                  <p class="text--secondary caption pt-2">
                    建議尺寸<br />
                    指示牌座：512 x 1028像素，PNG格式<br />
                    指示牌圖：512 x 796像素，PNG格式
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="指示牌底座圖片"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data_.sign.bgImage"
                      :error-messages="errors"
                      type="text"
                      class="pt-8"
                      placeholder="指示牌底座圖片"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'sign_bgImage';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        指示牌底座圖片
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="指示牌螢幕圖片"
                    rules="required"
                  >
                    <v-text-field
                      v-model="data_.sign.screenImage"
                      :error-messages="errors"
                      type="text"
                      placeholder="指示牌螢幕圖片"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'sign_screenImage';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        指示牌螢幕圖片
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <v-select
                    v-model="signUpType"
                    :items="linkType"
                    item-text="name"
                    item-value="value"
                    label="上半部連結"
                    outlined
                    dense
                  ></v-select>

                  <validation-provider
                    v-slot="{ errors }"
                    name="上半部自訂連結"
                    rules="required"
                    v-if="signUpType == 'order'"
                  >
                    <v-text-field
                      v-model="data_.sign.link1.url"
                      :error-messages="errors"
                      type="text"
                      placeholder="上半部自訂連結"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        上半部自訂連結
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <v-select
                    v-model="signDownType"
                    :items="linkType"
                    item-text="name"
                    item-value="value"
                    label="下半部連結"
                    outlined
                    dense
                  ></v-select>

                  <validation-provider
                    v-slot="{ errors }"
                    name="下半部自訂連結"
                    rules="required"
                    v-if="signDownType == 'order'"
                  >
                    <v-text-field
                      v-model="data_.sign.link2.url"
                      :error-messages="errors"
                      type="text"
                      placeholder="下半部自訂連結"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        下半部自訂連結
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="queck_token">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LinkTypeModel from "@/models/linkType.js";

export default {
  name: "scenesEntrancePage",
  props: {
    data: {},
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      linkType: LinkTypeModel,
      sessionType: "",
      signUpType: "",
      signDownType: "",
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    update() {
      const vm = this;
      let data = this.transformData();

      console.log(data);

      vm.updateEntrance({ id: vm.id, data: data }).then(() => {
        this.resetData();
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    resetData() {
      this.$emit("update");
    },
    transformData() {
      if (this.sessionType == "order") {
        this.data_.session.external = true;
      } else {
        this.data_.session.external = false;
        this.data_.session.url = this.sessionType;
      }

      if (this.signUpType == "order") {
        this.data_.sign.link1.external = true;
      } else {
        this.data_.sign.link1.external = false;
        this.data_.sign.link1.url = this.signUpType;
      }

      if (this.signDownType == "order") {
        this.data_.sign.link2.external = true;
      } else {
        this.data_.sign.link2.external = false;
        this.data_.sign.link2.url = this.signDownType;
      }

      return this.data_;
    },
    initView() {
      if (this.data_.session.external) {
        this.sessionType = "order";
      } else {
        this.sessionType = this.data_.session.url;
        this.data_.session.url = "";
      }

      if (this.data_.sign.link1.external) {
        this.signUpType = "order";
      } else {
        this.signUpType = this.data_.sign.link1.url;
        this.data_.sign.link1.url = "";
      }

      if (this.data_.sign.link2.external) {
        this.signDownType = "order";
      } else {
        this.signDownType = this.data_.sign.link2.url;
        this.data_.sign.link2.url = "";
      }
    },
    ...mapActions("updateEntrance", ["updateEntrance"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR", "SET_MEDIA_MODAL"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) =>
        `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`,
    }),
  },
  mounted() {
    this.initView();
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
      this.initView();
    },
    "$store.state.media.selected": function () {
      switch (this.selected) {
        case "table_image": {
          this.data_.table.tableImage = this.$store.state.media.selected.link;
          return;
        }
        case "table_logo": {
          this.data_.table.logoImage = this.$store.state.media.selected.link;
          return;
        }
        case "session_entrance": {
          this.data_.session.entrance = this.$store.state.media.selected.link;
          return;
        }
        case "session_deco": {
          this.data_.session.deco = this.$store.state.media.selected.link;
          return;
        }
        case "sign_bgImage": {
          this.data_.sign.bgImage = this.$store.state.media.selected.link;
          return;
        }
        case "sign_screenImage": {
          this.data_.sign.screenImage = this.$store.state.media.selected.link;
          return;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pt-38 {
  padding-top: 38%;
}
.pt-61 {
  padding-top: 61%;
}
.od_table {
  &__logo {
    top: 35%;
    left: 0;
  }
}
.od_session {
  &__img {
    width: 60%;
  }
  &__logo {
    bottom: -20%;
    left: 0;
  }
}
.od_sign {
  &__img {
    width: 36%;
    top: 2.5%;
    left: 32%;
  }
}
</style>