<template>
  <div class="Exhibition">
    <v-card class="rounded-lg">
      <v-card-text>
        <dl class="row">
          <dt class="col-4 col-md-2 text--secondary">名稱：</dt>
          <dd class="col-8 col-md-10">
            {{ data.name }}
          </dd>
          <dt class="col-4 col-md-2 text--secondary">管理帳號：</dt>
          <dd class="col-8 col-md-10">
            {{ data.user.email }}
          </dd>
          <dt class="col-4 col-md-2 text--secondary">展會日期：</dt>
          <dd class="col-8 col-md-10">{{ data.start_at }} ~ {{ data.end_at }}</dd>
          <dt class="col-4 col-md-2 text--secondary">容納人數：</dt>
          <dd class="col-8 col-md-10">
            {{ data.capacity }}
          </dd>
          <dt class="col-4 col-md-2 text--secondary">展會網址：</dt>
          <dd class="col-8 col-md-10">
            <a target="_black" :href="data.frontend_url">{{ data.frontend_url }}</a>
          </dd>
          <dt class="col-4 col-md-2 text--secondary">展會測試網址：</dt>
          <dd class="col-8 col-md-10">
            <a target="_black" :href="data.frontend_dev_url">{{ data.frontend_dev_url }}</a>
          </dd>
        </dl>
      </v-card-text>
      <v-card-actions v-if="$store.state.user.data.permission_info.info">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-1"
          @click="exhibitionUpdateModal = true"
        >
          <v-icon left dark> mdi-pencil </v-icon>
          編輯
        </v-btn>
      </v-card-actions>
    </v-card>
    <ExhibitionUpdateModal
      :dialog.sync="exhibitionUpdateModal"
      @updateData="updateData"
      v-if="data.name"
      :data_="data"
    ></ExhibitionUpdateModal>
  </div>
</template>

<script>
import ExhibitionUpdateModal from "@/components/modals/exhibition/update.vue";
import { mapActions } from "vuex";

export default {
  name: "Exhibition",
  components: {
    ExhibitionUpdateModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      exhibitionUpdateModal: false,
      data: ''
    };
  },
  methods: {
    updateData() {
      this.getExhibition({ id: this.id }).then(res => {
        this.data = res.data
      });
    },
    ...mapActions("exhibition", ["getExhibition"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
    this.updateData()
  }
};
</script>

<style scoped>
</style>

