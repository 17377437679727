import Vue from 'vue'
// import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import tw from "vee-validate/dist/locale/zh_TW.json";
import CKEditor from 'ckeditor4-vue';

import setupInterceptors from './services/setupInterceptors.js'
import timeFilter from './filters/time.js'
import dateFilter from './filters/date.js'
import permissionFilter from './filters/permission.js'
import youtubeUrlFilter from './filters/youtubeUrl.js'
import youtubeKeyFilter from './filters/youtubeKey.js'
import fiveMinFilter from './filters/fiveMin.js'

// 安裝所有 VeeValidate 規則
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("zh_TW", tw);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.filter('time',timeFilter)
Vue.filter('date',dateFilter)
Vue.filter('youtubeUrl',youtubeUrlFilter)
Vue.filter('youtubeKey',youtubeKeyFilter)
Vue.filter('permission',permissionFilter)
Vue.filter('fiveMin',fiveMinFilter)
Vue.use( CKEditor )

Vue.config.productionTip = false

setupInterceptors(store);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')