var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"unstartLecturePage",attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8 justify-center"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('p',[_vm._v("banner")]),_c('div',{staticClass:"w-50 mx-auto"},[_c('div',{staticClass:"image-square pt-56"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.data_.unstart.mask_bg,"alt":""}})])]),_c('p',{staticClass:"text--secondary caption pt-2"},[_vm._v("建議尺寸1200 × 675像素")]),_c('validation-provider',{attrs:{"name":"banner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-8 px-8",attrs:{"error-messages":errors,"type":"text","placeholder":"banner","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'unstart';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" banner ")]},proxy:true}],null,true),model:{value:(_vm.data_.unstart.mask_bg),callback:function ($$v) {_vm.$set(_vm.data_.unstart, "mask_bg", $$v)},expression:"data_.unstart.mask_bg"}})]}}])})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('validation-provider',{attrs:{"name":"未開始顯示文字","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-8",attrs:{"error-messages":errors,"type":"text","placeholder":"未開始顯示文字","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 未開始顯示文字 ")]},proxy:true}],null,true),model:{value:(_vm.data_.unstart.mask_text),callback:function ($$v) {_vm.$set(_vm.data_.unstart, "mask_text", $$v)},expression:"data_.unstart.mask_text"}})]}}])}),_c('validation-provider',{attrs:{"name":"未開始返回文字","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-8",attrs:{"error-messages":errors,"type":"text","placeholder":"未開始返回文字","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 未開始返回文字 ")]},proxy:true}],null,true),model:{value:(_vm.data_.unstart.back_text),callback:function ($$v) {_vm.$set(_vm.data_.unstart, "back_text", $$v)},expression:"data_.unstart.back_text"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.resetData}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cached ")]),_vm._v(" 復原 ")],1),_c('v-btn',{attrs:{"target":"_blank","href":(_vm.queck_token + "?path=lecture")}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-card-search ")]),_vm._v(" 預覽 ")],1),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 儲存 ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }