<template>
  <div class="Profile">
    <v-card>
      <v-card-text>
        <dl class="row" v-if="data.permission_info">
          <dt class="col-12 mt-4 font-weight-bold text-h6">資訊</dt>
          <dt class="col-4 col-md-2 text--secondary">組織：</dt>
          <dd class="col-8 col-md-10">
            {{ data.org ? data.org : "-" }}
          </dd>
          <dt class="col-4 col-md-2 text--secondary">帳號：</dt>
          <dd class="col-8 col-md-10">{{ data.email }}</dd>
          <dt class="col-4 col-md-2 text--secondary">會員權限：</dt>
          <dd class="col-8 col-md-10">
            {{ data.permission | permission }}
          </dd>
          <dt class="col-4 col-md-2 text--secondary">統一編號：</dt>
          <dd class="col-8 col-md-10">
            {{ data.serial ? data.serial : "-" }}
          </dd>
          <dt class="col-4 col-md-2 text--secondary">帳號有效日期：</dt>
          <dd class="col-8 col-md-10">
            {{ `${data.start_at} ~ ${data.end_at}` }}
          </dd>

          <dt class="col-12 mt-4 font-weight-bold text-h6">權限</dt>
          <!-- <dt class="col-4 col-md-2 text--secondary">資訊管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.info"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd> -->
          
          <dt class="col-4 col-md-2 text--secondary">入口大廳管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.entrance"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>

          <dt class="col-4 col-md-2 text--secondary">選單管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.tab"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>

          <dt class="col-4 col-md-2 text--secondary">登入畫面管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.login"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>

          <dt class="col-4 col-md-2 text--secondary">演講廳管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.lecture"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>

          <dt class="col-4 col-md-2 text--secondary">分會場管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.venue"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>

          <dt class="col-4 col-md-2 text--secondary">展示區管理：</dt>
          <dd class="col-8 col-md-10">
            <v-chip color="success" small v-if="data.permission_info.area"
              >啟用</v-chip
            >
            <v-chip small v-else>停用</v-chip>
          </dd>
        </dl>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mx-1" @click="profileUpdateModal = true; type = 'password'">
          <v-icon left dark> mdi-lock </v-icon>
          變更密碼
        </v-btn>
        <v-btn class="mx-1" @click="profileUpdateModal = true; type = 'value'">
          <v-icon left dark> mdi-pencil </v-icon>
          編輯會員
        </v-btn>
      </v-card-actions>
    </v-card>
    <ProfileUpdateModal
      :dialog.sync="profileUpdateModal"
      @updateData="updateData"
      v-if="data.email"
      :data_="data"
      :type="type"
    ></ProfileUpdateModal>
  </div>
</template>

<script>
import ProfileUpdateModal from "@/components/modals/profile/update.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "Profile",
  components: {
    ProfileUpdateModal
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  data(){
    return{
      profileUpdateModal: false,
      type: 'value'
    }
  },
  methods: {
    updateData() {
      this.getUser()
    },
    ...mapActions("user", ["getUser"])
  },
  computed: {
    ...mapState({
      data: (state) => state.user.data,
    }),
  },
};
</script>

