<template>
  <v-card class="messageEntrancePage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <div class="mx-auto d-flex od_message">
                <img
                  class="mw-100 h-auto w-50"
                  :src="data_.greeting.image"
                  alt=""
                />
                <div class="od_message__box">
                  <span class="od_message__text">
                    {{ data_.greeting.wording }}
                  </span>
                </div>
              </div>
              <p class="text--secondary caption pt-2">建議尺寸500 x 320 像素，PNG格式</p>
            </v-col>
            <v-col cols="12" md="7" class="text-center">
              <validation-provider
                v-slot="{ errors }"
                name="圖片"
                rules="required"
              >
                <v-text-field
                  v-model="data_.greeting.image"
                  :error-messages="errors"
                  type="text"
                  class="pt-8"
                  placeholder="圖片"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'message';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    圖片
                  </template>
                </v-text-field>
              </validation-provider>

              <div class="text-right">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mb-4"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      插入參數
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in items"
                      :key="index"
                      link
                    >
                      <v-list-item-title
                        @click.stop="addParameter(item.name)"
                        >{{ item.title }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <validation-provider
                v-slot="{ errors }"
                name="問候語"
                rules="required|max:25"
              >
                <v-textarea
                  class="js-textarea"
                  outlined
                  rows="3"
                  maxlength="25"
                  counter="25"
                  name="問候語"
                  label="問候語"
                  auto-grow
                  :error-messages="errors"
                  v-model="data_.greeting.wording"
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    問候語
                  </template>
                </v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="queck_token">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "messageEntrancePage",
  props: {
    data: {},
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      mediaModal: false,
      selected: "",
      items: [
        {
          title: "姓名",
          name: "name",
        },
        {
          title: "Email",
          name: "email",
        },
        {
          title: "電話",
          name: "phone",
        },
        {
          title: "公司",
          name: "org",
        },
      ],
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    update() {
      const vm = this;

      vm.updateEntrance({ id: vm.id, data: this.data_ }).then(() => {
        this.resetData();
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    resetData() {
      this.$emit("update");
    },
    addParameter(name) {
      this.data_.greeting.wording =
        this.data_.greeting.wording + "${" + name + "}";
    },
    ...mapActions("updateEntrance", ["updateEntrance"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR", "SET_MEDIA_MODAL"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`,
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
    "$store.state.media.selected": function () {
      switch (this.selected) {
        case "message": {
          this.data_.greeting.image = this.$store.state.media.selected.link;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.od_message {
  width: 25vw;
  &__box {
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex: 1;
    justify-content: center;
    margin: -2em 0 2em;
    padding: 1em;
    position: relative;
    width: 50%;
    font-size: 1vw;
    margin-top: 0;
    &::before {
      clip-path: polygon(125% 0, 0% 100%, 100% 75%);
      background: rgba(0, 0, 0, 0.1);
      content: "";
      height: 20px;
      left: -19.5px;
      position: absolute;
      width: 20px;
    }
    &__text {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      max-width: 100%;
      overflow: hidden;
      text-align: left;
      white-space: pre;
    }
  }
}
</style>