var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stallAreaUpdateModal"},[_c('v-dialog',{attrs:{"max-width":"560","scrollable":""},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 編輯攤位 ")]),_c('v-divider'),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(_vm.area.stall_img)?_c('div',{staticClass:"mx-auto mb-4 w-50 position-relative"},[_c('p',{staticClass:"mb-0"},[_vm._v("桌機")]),_c('div',{staticClass:"image-square pt-67"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.area.stall_img,"alt":""}})])]):_vm._e(),(_vm.area.logo)?_c('div',{staticClass:"mx-auto mb-4 w-50 position-relative"},[_c('p',{staticClass:"mb-0"},[_vm._v("手機")]),_c('div',{staticClass:"image-square pt-67"},[_c('img',{staticClass:"object-fit-cover",attrs:{"src":_vm.area.logo,"alt":""}})])]):_vm._e(),_c('validation-provider',{attrs:{"name":"攤位名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"攤位名稱","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 攤位名稱 ")]},proxy:true}],null,true),model:{value:(_vm.area.name),callback:function ($$v) {_vm.$set(_vm.area, "name", $$v)},expression:"area.name"}})]}}])}),_c('v-textarea',{attrs:{"clearable":"","dense":"","placeholder":"攤位描述","label":"攤位描述","auto-grow":"","outlined":"","clear-icon":"mdi-close-circle","rows":"4"},model:{value:(_vm.area.text),callback:function ($$v) {_vm.$set(_vm.area, "text", $$v)},expression:"area.text"}}),_c('validation-provider',{attrs:{"name":"攤位連結"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"攤位連結","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 攤位連結 ")]},proxy:true}],null,true),model:{value:(_vm.area.url),callback:function ($$v) {_vm.$set(_vm.area, "url", $$v)},expression:"area.url"}})]}}])}),_c('validation-provider',{attrs:{"name":"攤位圖片連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"攤位圖片連結","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                      _vm.selected = 'stall';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 攤位圖片連結 ")]},proxy:true}],null,true),model:{value:(_vm.area.stall_img),callback:function ($$v) {_vm.$set(_vm.area, "stall_img", $$v)},expression:"area.stall_img"}})]}}])}),_c('validation-provider',{attrs:{"name":"攤位LOGO連結(手機)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"攤位LOGO連結(手機)","outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                      _vm.selected = 'logo';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 攤位LOGO連結(手機) ")]},proxy:true}],null,true),model:{value:(_vm.area.logo),callback:function ($$v) {_vm.$set(_vm.area, "logo", $$v)},expression:"area.logo"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 儲存 ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }