<template>
  <div class="ExhibitionLogin">
    <v-alert
      prominent
      v-if="user.permission_info && !user.permission_info.login"
      type="warning"
    >您的權限不足</v-alert>
    <v-card left v-else>
      <v-tabs v-model="tab" class="border-bottom px-3">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Banner :data="data" v-if="data.banner" @update="update"></Banner>
        </v-tab-item>
        <v-tab-item>
          <Button :data="data" v-if="data.banner" @update="update"></Button>
        </v-tab-item>
        <v-tab-item>
          <Message :data="data" v-if="data.banner" @update="update"></Message>
        </v-tab-item>
      </v-tabs-items>
      <MediaModal :dialog.sync="$store.state.mediaModal"></MediaModal>
    </v-card>
  </div>
</template>

<script>
import MediaModal from "@/components/modals/media.vue";
import Banner from "@/components/pages/login/banner.vue";
import Message from "@/components/pages/login/message.vue";
import Button from "@/components/pages/login/button.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "ExhibitionLogin",
  components: {
    MediaModal,
    Banner,
    Message,
    Button
  },
  data() {
    return {
      tab: null,
      items: ["Banner", "按鈕", "提示訊息"],
      id: this.$route.params.id,
    };
  },
  methods: {
    update() {
      this.getLogin({ id: this.id });
    },
    ...mapActions("login", ["getLogin"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    this.getLogin({ id: this.id });
  },
  computed: {
    ...mapState({
      data: (state) => state.login.data,
    }),
    ...mapState({
      user: (state) => state.user.data,
    })
  },
};
</script>