<template>
  <div class="Members">
    <v-card class="rounded-lg">
      <div class="pa-3">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              type="text"
              dense
              outlined
              clearable
              append-icon="mdi-magnify"
              v-model="search"
              @click:append="searchAction(false)"
              @keyup.enter="searchAction(false)"
              @click:clear="searchAction(true)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="text-end">
            <input type="file" class="js-import-excel d-none" />
            <v-btn @click="downloadUrl" class="mx-1">
              <v-icon left dark> mdi-download </v-icon>
              範例
            </v-btn>
            <v-btn color="secondary" class="mx-1" @click="uploadShow">
              <v-icon left dark> mdi-import </v-icon>
              匯入
            </v-btn>
            <v-btn color="secondary" class="mx-1" @click="exportFile">
              <v-icon left dark> mdi-export </v-icon>
              匯出
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="pa-3">
        <v-simple-table fixed-header max-height="600px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left od-th" @click="setSort('org')">
                  公司名稱
                  <v-icon
                    small
                    v-if="sort == 'org'"
                    :class="{ reverse: isReverse }"
                    >mdi-chevron-down</v-icon
                  >
                </th>
                <th class="text-left od-th" @click="setSort('name')">
                  名稱
                  <v-icon
                    small
                    v-if="sort == 'name'"
                    :class="{ reverse: isReverse }"
                    >mdi-chevron-down</v-icon
                  >
                </th>
                <th class="text-left od-th" @click="setSort('email')">
                  帳號
                  <v-icon
                    small
                    v-if="sort == 'email'"
                    :class="{ reverse: isReverse }"
                    >mdi-chevron-down</v-icon
                  >
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <template v-if="item.email !== 'exhibition_defult@kingtel.com.tw'">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.org }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td class="text-end">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click.stop="
                            activeItem = item;
                            membersUpdateModal = true;
                          "
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>編輯</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click.stop="
                            activeItem = item;
                            membersUpdatePasswordModal = true;
                          "
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-lock</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>變更密碼</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click.stop="
                            activeItem = item;
                            membersDeleteModal = true;
                          "
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>刪除</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>
    <div class="text-center py-4">
      <v-row justify="center">
        <v-col cols="8">
          <v-pagination
            circle
            v-model="isPage"
            class="my-4"
            :length="pageLength"
            @next="isPage + 1 ? isPage + 1 : 1"
            @previous="isPage - 1 ? isPage - 1 : 1"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
    <MembersUpdateModal
      :dialog.sync="membersUpdateModal"
      :item="activeItem"
      @updateData="updateData">
    </MembersUpdateModal>
    <MembersUpdatePasswordModal
      :dialog.sync="membersUpdatePasswordModal"
      :item="activeItem"
      @updateData="updateData">
    </MembersUpdatePasswordModal>
    <MembersDeleteModal
      :dialog.sync="membersDeleteModal"
      :item="activeItem"
      @updateData="updateData"
    ></MembersDeleteModal>
  </div>
</template>

<script>
import MembersDeleteModal from "@/components/modals/members/delete.vue";
import MembersUpdateModal from "@/components/modals/members/update.vue";
import MembersUpdatePasswordModal from "@/components/modals/members/updatePassword.vue";


import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Members",
  components: {
    MembersDeleteModal,
    MembersUpdateModal,
    MembersUpdatePasswordModal
  },
  data() {
    return {
      membersDeleteModal: false,
      membersUpdateModal: false,
      membersUpdatePasswordModal: false,
      id: this.$route.params.id,
      sort: "",
      search: "",
      isPage: 1,
      isReverse: false,
      activeItem: {},
    };
  },
  methods: {
    exportFile() {
      this.exportMember({ id: this.id }).then((res) => {
        location.href = res.data.url
      });
    },
    uploadShow() {
      const input = document.querySelector(".js-import-excel");
      input.click();
    },
    listenInput() {
      const vm = this;
      const input = document.querySelector(".js-import-excel");

      input.addEventListener("change", (e) => {
        let form = new FormData();

        if (e.target.files.length == 0) {
          return;
        }

        e.target.files.forEach(function (i) {
          form.append("file", i);
        });

        vm.importMember({ id: vm.id, form: form }).then(() => {
          vm.getMembers({
            id: this.id,
            sort: this.sort,
            isReverse: this.isReverse,
            search: this.search,
          }).then(() => {
            vm.SET_SUCCESS_SNACKBAR({
              show: true,
              text: "匯入成功",
            });
          });
        });
      });
    },
    searchAction(isClear) {
      if (isClear) {
        this.search = "";
      }
      this.getMembers({
        id: this.id,
        sort: this.sort,
        isReverse: this.isReverse,
        search: this.search,
      });
    },
    downloadUrl() {
      location.href = `${process.env.VUE_APP_API}/storage/exhibition-member-sample.xlsx`;
    },
    setSort(mode) {
      const vm = this;

      // 判斷是否第一次排序
      if (!vm.sort) {
        vm.sort = mode;
      } else {
        if (vm.sort == mode) {
          vm.isReverse = !vm.isReverse;
        } else {
          vm.sort = mode;
        }
      }
    },
    updateData() {
      this.getMembers({
        id: this.id,
        sort: this.sort,
        isReverse: this.isReverse,
        search: this.search,
      });
    },
    ...mapActions("members", ["getMembers"]),
    ...mapActions("importMember", ["importMember"]),
    ...mapActions("exportMember", ["exportMember"]),
    ...mapMutations("members", ["SET_PAGE"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "basic-layout");
  },
  mounted() {
    this.getMembers({
      id: this.id,
      sort: this.sort,
      isReverse: this.isReverse,
      search: this.search,
    });
    this.listenInput();
  },
  computed: {
    ...mapState({
      data: (state) => state.members.data.items,
      page: (state) => state.members.data.page,
      pageLength: (state) => state.members.data.last_page,
    }),
  },
  watch: {
    isPage() {
      this.SET_PAGE(this.isPage);
      this.getMembers({
        id: this.id,
        sort: this.sort,
        isReverse: this.isReverse,
        search: this.search,
      });
    },
    sort() {
      this.getMembers({
        id: this.id,
        sort: this.sort,
        isReverse: this.isReverse,
        search: this.search,
      });
    },
    isReverse() {
      this.getMembers({
        id: this.id,
        sort: this.sort,
        isReverse: this.isReverse,
        search: this.search,
      });
    },
  },
};
</script>
