<template>
  <div class="mediaModal">
    <input
      type="file"
      multiple="multiple"

      class="js-file-input d-none"
    />
    <v-dialog
      v-model="isDialog"
      max-width="665"
      scrollable
      content-class="od-modal"
    >
      <v-card>
        <v-card-title class="text-h5 justify-space-between">
          媒體庫
          <v-icon @click="isDialog = false"> mdi-close </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            class="py-4 align-center h-100"
            v-if="this.$store.state.mediaLoding"
          >
            <v-col class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row class="py-4" v-else>
            <v-col cols="auto">
              <v-sheet
                color="white box"
                elevation="1"
                width="100"
                height="100"
                @click.stop="uploadShow"
              >
                <v-icon class="box__icon"> mdi-tray-arrow-up </v-icon>
              </v-sheet>
            </v-col>
            <div class="w-100"></div>
            <template v-for="(item, index) in media">
              <v-col cols="auto mb-8" :key="index">
                <div
                  class="image-square box-hover box-100 position-relative"
                  :title="`
檔名: ${item.name}
大小: ${item.size}
修改日期: ${item.time}
                  `"
                  @click="selectedTodo(item)"
                  :class="{ selected: selected.id == item.id }"
                >
                  <img class="" :src="item.thumbnailLink" alt="" />
                  <div class="position-absolute selected-icon box-100 d-none">
                    <v-icon class="pa-1" color="white">
                      mdi-check-circle
                    </v-icon>
                  </div>
                  <p class="text-truncate py-2">{{ item.id }}</p>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            v-if="selected"
            :disabled="this.$store.state.mediaLoding"
            @click="
              deleteMedia(selected.id).then(() => {
                getMedia();
              })
            "
          >
            刪除
          </v-btn>
          <v-btn
            :disabled="this.$store.state.mediaLoding"
            text
            @click="returnLink"
          >
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "mediaModal",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      thisPath: "",
      selected: "",
      newFile: {}
    };
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
    ...mapState({
      media: (state) => state.media.data,
    }),
  },
  methods: {
    uploadShow() {
      const input = document.querySelector(".js-file-input");
      input.click();
    },
    setNewFile() {
      const vm = this;

      // 如果有新檔案
      if(vm.newFile){
        // 找出新的
        let arr = []
        
        vm.media.filter(function(value, index){
          
          // 新增複數檔案
          if(vm.newFile.constructor === Object){
            for (var prop in vm.newFile) {
              if(vm.newFile[prop] == value.link){
                arr.push(value.id)
              }
            }
          }  
          console.log(index)
        })
        console.log(arr)
      }
    },
    updateData(){
      const vm = this;

      vm.getMedia().then(() => {

        vm.setNewFile()
      })
      
    },
    listenInput() {
      const vm = this;
      const input = document.querySelector(".js-file-input");

      input.addEventListener("change", (e) => {
        let form = new FormData();
        form.append("path", vm.thisPath);

        e.target.files.forEach(function (i) {
          form.append(vm.RemoveExtension(i.name), i);
        });

        vm.createMedia(form).then(res => {
          this.newFile = res.data
          vm.getMedia();
        });
      });
    },
    RemoveExtension(text) {
      let extIndex = text.lastIndexOf(".");
      if (extIndex != -1) {
        return text.substr(0, extIndex);
      }
    },
    selectedTodo(item) {
      if (this.selected == item) {
        this.selected = "";
      } else {
        this.selected = item;
      }
    },
    returnLink() {
      if (this.selected) {
        // this.$emit("getLink", this.selected);
        this.SET_SELECTED(this.selected);
      }
      this.isDialog = false;
    },
    ...mapActions("media", ["getMedia"]),
    ...mapActions("createMedia", ["createMedia"]),
    ...mapActions("deleteMedia", ["deleteMedia"]),
    ...mapMutations("media", ["SET_SELECTED"]),
  },
  mounted() {
    // 監聽檔案上傳
    this.getMedia();
    this.listenInput();
  },
};
</script>

<style lang="scss">
.box {
  cursor: pointer;
  text-align: center;
  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    width: 0;
    vertical-align: middle;
  }
  &__icon {
    font-size: 2rem;
    display: inline-block;
    vertical-align: middle;
  }
}
.box-100 {
  height: 100px;
  width: 100px;
}
.box-hover {
  cursor: pointer;
  &:hover {
    background: #eee;
  }
}
.selected {
  .selected-icon {
    background: rgba(0, 0, 0, 0.5);
    display: block !important;
    top: 0;
    left: 0;
  }
}
.od-modal {
  height: 100% !important;
}
</style>
