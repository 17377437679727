<template>
  <v-snackbar v-model="isDialog" color="success" timeout="-1" :vertical="true">
    <v-icon small class="mr-2">mdi-check</v-icon>成功建立展會

    <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
        >
          即將前往...
        </v-btn>
      </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'successExhibitionSnackbar',
  props: {
    dialog: Boolean,
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
};
</script>