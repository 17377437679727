<template>
<div v-if="this.$store.state.isLoading" style="z-index: 999">
  <v-progress-linear indeterminate color="primary" style="z-index: 99" fixed top></v-progress-linear>
  <v-overlay z-index="9" opacity=".1"></v-overlay>
</div>
</template>

<script>
export default {
  name: "ProgressLinear",
};
</script>