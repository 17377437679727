
<template>
  <div>
    <v-app-bar color="white" elevation="3" app>
      <v-toolbar-title>{{ meta.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <span class="mr-2">{{ data.email }}</span>

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="/profile">
            <v-list-item-title>會員中心</v-list-item-title>
          </v-list-item>
          <v-list-item @click="isLogout">
            <v-list-item-title>登出</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Header",
  data: () => ({
    meta: {},
  }),
  methods: {
    getMeta(){
      this.meta = this.$route.meta
    },
    isLogout(){
      this.logout().then(res => res ? this.$router.push('login') : '')
    },
    ...mapActions("auth", ["logout"]),
  },
  created() {
    this.getMeta()
  },
  computed: {
    ...mapState({
      data: (state) => state.user.data,
    }),
  },
  watch: {
    '$route' () {
      this.getMeta()
    }
  }
};
</script>
