<template>
  <div class="">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 建立展會 </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="活動名稱"
                    rules="required|max:20"
                  >
                    <v-text-field
                      v-model="data.name"
                      :error-messages="errors"
                      type="text"
                      placeholder="活動名稱"
                      counter
                      maxlength="20"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        活動名稱
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="容納人數"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="data.capacity"
                      :error-messages="errors"
                      type="number"
                      placeholder="容納人數*"
                      min="0"
                      max="999"
                      outlined
                      dense
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        容納人數
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-dialog
                    ref="strDatePickerDialog"
                    v-model="strDateModal"
                    :return-value.sync="strDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="開始日期"
                        rules="required"
                      >
                        <v-text-field
                          v-model="strDate"
                          append-icon="mdi-calendar"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            開始日期
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker 
                      v-model="strDate" 
                      scrollable
                      :min="new Date().toISOString().split('T')[0]"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="strDateModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.strDatePickerDialog.save(strDate); endDate = ''"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- <v-col cols="6" class="py-0">
                  <v-dialog
                    ref="strTimePickerdialog"
                    v-model="strTimeModal"
                    :return-value.sync="strTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="開始時間"
                        rules="required"
                      >
                        <v-text-field
                          v-model="strTime"
                          label="開始時間"
                          append-icon="mdi-clock-time-four-outline"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            開始時間
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-time-picker
                      v-if="strTimeModal"
                      v-model="strTime"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="strTimeModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.strTimePickerdialog.save(strTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col> -->
                <v-col cols="6" class="py-0">
                  <v-dialog
                    ref="endDatePickerDialog"
                    v-model="endDateModal"
                    :return-value.sync="endDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="結束日期"
                        rules="required"
                      >
                        <v-text-field
                          v-model="endDate"
                          append-icon="mdi-calendar"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            結束日期
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker 
                      v-model="endDate" 
                      scrollable
                      :min="strDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endDateModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endDatePickerDialog.save(endDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- <v-col cols="6" class="py-0">
                  <v-dialog
                    ref="endTimePickerdialog"
                    v-model="endTimeModal"
                    :return-value.sync="endTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="結束時間"
                        rules="required"
                      >
                        <v-text-field
                          v-model="endTime"
                          label="結束時間"
                          append-icon="mdi-clock-time-four-outline"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            結束時間
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-time-picker
                      v-if="endTimeModal"
                      v-model="endTime"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endTimeModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endTimePickerdialog.save(endTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col> -->

                <v-col cols="12" class="py-0">
                  <v-select
                    v-model="data.uid"
                    :items="accounts"
                    item-text="email"
                    item-value="id"
                    label="管理帳號"
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="展會網址"
                    rules="required|alpha_dash"
                  >
                    <v-text-field
                      v-model="data.code"
                      :error-messages="errors"
                      type="email"
                      placeholder="展會網址"
                      suffix="-digievent.ictsage.com"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        展會網址
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="isDialog = false"> 取消 </v-btn>
          <v-btn
            @click="validateForm"
            color="primary"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            建立
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SuccessExhibitionSnackbar :dialog.sync="successExhibitionSnackbar"></SuccessExhibitionSnackbar>
  </div>
</template>

<script>
import SuccessExhibitionSnackbar from "@/components/snackbars/exhibitions/success.vue";
import ExhibitionModel from "@/models/exhibition";
import MemberModel from "@/models/member";

import { mapActions, mapState } from "vuex";

export default {
  name: "exhibitionsCreateModal",
  components: {
    SuccessExhibitionSnackbar
  },
  data() {
    return {
      data: new ExhibitionModel('','','','','',''),
      strDate: "",
      strDateModal: false,
      endDate: "",
      endDateModal: false,
      successExhibitionSnackbar: false,
      defult_member: new MemberModel('defult','','exhibition_defult@kingtel.com.tw','','kingtel',''),
    };
  },
  props: {
    dialog: Boolean,
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
    ...mapState({
      accounts: (state) => state.accounts.data.items,
    }),
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        let data = vm.transformData();
  
        vm.createExhibition(data).then(response => {
          vm.successTodo(response)
        })
      }
    },
    transformData() {
      const vm = this;
      vm.data.start_at = `${vm.strDate} 00:00:00`;
      vm.data.end_at = `${vm.endDate} 23:59:00`;

      return vm.data
    },
    successTodo(res){
      // 創建展會成功後 還要創建一個deflut 會員
      this.createMember({data: this.defult_member, id: res.data.id}).then(member => {

        res.data.custom_backend = {
          passport: member.data.passport
        }

        // 創建完後會將 快速登入的token存回展會
        this.updateExhibition({data: res.data, id: res.data.id}).then(() => {

          this.isDialog = false
          this.successExhibitionSnackbar = true
          
          setTimeout(() => {
            this.$router.push(`/exhibition/${res.data.id}/entrance`)
          },3000)
        })
      })

    },
    ...mapActions("createExhibition", ["createExhibition"]),
    ...mapActions("updateExhibition", ["updateExhibition"]),
    ...mapActions("createMember", ["createMember"]),
    ...mapActions("accounts", ["getAccounts"]),
  },
  mounted() {
    this.getAccounts();
  },
};
</script>