import Axios from 'axios'

export default {
  namespaced: true,
  strict: true,
  state: {
    data: {
      page: 1,
      last_page: 1,
    }
  },
  getters: {},
  actions: {
    getMembers(context,payload = null) {
      let api = `${process.env.VUE_APP_API}/api/manage/exhibition/${payload.id}/member?page_size=${process.env.VUE_APP_PAGE_SIZE}&page=${context.state.data.page}`
      const config = {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem('user')).accessToken,
        },
        actionUrl: 'members/getMembers',
        actionPayload: payload
      }

      if (payload && payload.sort) {
        api = api + `&sort=${payload.isReverse ? payload.sort : "-" + payload.sort}`;
      }
      if (payload && payload.search) {
        api = api + `&search=${payload.search}`;
      }

      context.commit('SET_LOADING', true, { root: true })

      return new Promise((resolve, reject) => {

      Axios.get(api, config)
        .then((response) => {
          context.commit('GET_MEMBERS', response.data)
          context.commit('SET_LOADING', false, { root: true })
          resolve(response);

        })
        .catch((error) => {
          if (error.response.status !== 401) {
            if (error.response.status == 400) {
              context.commit('SET_SNACKBAR', {
                show: true,
                text: error.response.data
              }, { root: true })
            }

            context.commit('SET_LOADING', false, { root: true })
          }
          reject(error);

        });
      })
    },
  },
  mutations: {
    GET_MEMBERS(state, payload) {
      state.data = payload
    },
    SET_PAGE(state, payload) {
      state.data.page = payload
    },
  },
  modules: {
  }
}