export default [
    {
        name: "首頁",
        value: "/index",
    },
    {
        name: "演講廳",
        value: "/lecture",
    },
    {
        name: "分會場",
        value: "/venue",
    },
    {
        name: "展示區",
        value: "/area",
    },
    {
        name: "自訂連結",
        value: "order",
    },
]