var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Menu"},[(_vm.user.permission_info && !_vm.user.permission_info.tab)?_c('v-alert',{attrs:{"prominent":"","type":"warning"}},[_vm._v("您的權限不足")]):_c('v-card',{attrs:{"left":""}},[_c('v-card-text',[_c('v-row',{staticClass:"py-8 justify-center"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","disabled":_vm.data_.length >= 10},on:{"click":function($event){$event.stopPropagation();_vm.menuCreateModal = true}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" 新增選單 ")],1),_c('p',[_vm._v("最多新增10個選單")]),(_vm.data_)?_vm._l((_vm.data_),function(value,index){return _c('v-card',{key:index,staticClass:"mb-4"},[_c('v-card-title',{staticClass:"text-h6 justify-space-between"},[_vm._v(" "+_vm._s(("" + (index + 1)))+" "),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.menuUpdateModal = true;
                        _vm.activeId = index;
                        _vm.activeItem = value;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("編輯")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.removeMenu(index)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("刪除")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.moveSlider(index, true)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("上移")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.moveSlider(index, false)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-down")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("下移")])],1)],1)],1)],1)],1),_c('v-card-text',[(value.img || value.imgActive)?_c('div',{staticClass:"w-10 mx-auto"},[_c('div',{staticClass:"image-square"},[_c('img',{staticClass:"object-fit-cover cursor-pointer",attrs:{"src":_vm.hover[index] ? value.imgActive : value.img},on:{"mouseover":function($event){_vm.hover[index] = true},"mouseleave":function($event){_vm.hover[index] = false}}})])]):_vm._e(),_c('a',{attrs:{"target":"_blank","href":value.external
                      ? value.url
                      : ("" + (_vm.queck_token + '#' + value.url))}},[_c('p',{staticClass:"mt-4"},[_vm._v(_vm._s(value.name))])])])],1)}):_vm._e()],2)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.resetData}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cached ")]),_vm._v(" 復原 ")],1),_c('v-btn',{attrs:{"target":"_blank","href":_vm.queck_token}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-card-search ")]),_vm._v(" 預覽 ")],1),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.update}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 儲存 ")],1)],1),_c('MenuCreateModal',{attrs:{"dialog":_vm.menuCreateModal},on:{"update:dialog":function($event){_vm.menuCreateModal=$event},"createData":_vm.createMenu}}),_c('MenuUpdateModal',{attrs:{"dialog":_vm.menuUpdateModal,"data":_vm.activeItem,"id":_vm.activeId},on:{"update:dialog":function($event){_vm.menuUpdateModal=$event},"updateData":_vm.updateMenu}}),_c('MediaModal',{attrs:{"dialog":_vm.$store.state.mediaModal},on:{"update:dialog":function($event){return _vm.$set(_vm.$store.state, "mediaModal", $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }