<template>
  <div class="">
    <v-dialog v-model="isDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> 確定刪除展會？ </v-card-title>
        <v-card-text>
          這將永久刪除此展會活動的所有圖片、名單、數據。<br />若確定刪除，請輸入
          “刪除”。
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <validation-provider
                v-slot="{ errors }"
                name="確認刪除"
                rules="required|is:刪除"
              >
                <v-text-field
                  class="mt-8"
                  filled
                  v-model="chcekDelete"
                  :error-messages="errors"
                  type="text"
                  placeholder="請輸入刪除"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-alert-circle"
                >
                </v-text-field>
              </validation-provider>
            </v-form>
          </validation-observer>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="isDialog = false"> 取消 </v-btn>

          <v-btn
            color="error"
            @click="validateForm"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            刪除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "exhibitionsDeleteModal",
  data() {
    return {
      chcekDelete: "",
    };
  },
  props: {
    dialog: Boolean,
    item: {},
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        this.deleteExhibition({ id: this.item.id }).then(() => {
          vm.successTodo();
        });
      }
    },
    successTodo() {
      this.isDialog = false;
      this.SET_SUCCESS_SNACKBAR({
        show: true,
        text: `成功刪除 ${this.item.name}`
      })
      this.$emit("updateData");
      this.resetInput()
    },
    resetInput() {
      this.chcekDelete = "";
      this.$refs.observer.reset()
    },
    ...mapActions("deleteExhibition", ["deleteExhibition"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"])
  },
};
</script>