import Axios from 'axios'
import TokenService from '../../services/token.service';
import Router from '../../router/index.js'

export default {
  namespaced: true,
  strict: true,
  getters: {
  },
  actions: {
    login(context, payload) {
      const api = `${process.env.VUE_APP_API}/api/manage/account/login`
      const parameter = payload;

      context.commit('SET_PARTIAL_LOADING', true, { root: true })

      return Axios.post(api, parameter)
        .then((response) => {
          context.dispatch('setTokenExpiry', { token: response, remember: parameter.remember });

          context.commit('SET_PARTIAL_LOADING', false, { root: true })
          return true
        })
        .catch((error) => {

          context.commit('SET_SNACKBAR', {
            show: true,
            text: error.response.data
          }, { root: true })

          context.commit('SET_PARTIAL_LOADING', false, { root: true })
          return false
        });
    },
    setTokenExpiry(context, payload) {
      const data = {
        accessToken: payload.token.data.accessToken,
        refreshToken: payload.token.data.refreshToken,
        expiry: payload.remember ? new Date().getTime() + 86400000 * 30 : new Date().getTime() + 86400000 * 1,
      }

      TokenService.setUser(data)
    },
    logout(context) {
      const api = `${process.env.VUE_APP_API}/api/manage/account/logout`
      const config = {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem('user')).accessToken,
        },
        actionUrl: 'auth/logout'
      }

      context.commit('SET_LOADING', true, { root: true })

      Axios.get(api, config)
        .then(() => {
          context.commit('SET_LOADING', false, { root: true })

          // 清除token
          localStorage.removeItem('user')
          Router.push('/login')
        })
        .catch((error) => {
          if (error.response.status !== 401) {
            if (error.response.status == 400) {
              context.commit('SET_SNACKBAR', {
                show: true,
                text: error.response.data
              }, { root: true })
            }

            context.commit('SET_LOADING', false, { root: true })
          }
        });
    }
  },
  mutations: {
  },
  modules: {
  }
}