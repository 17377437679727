<template>
  <v-card flat class="screenLecturePage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <v-btn
                class="mb-4"
                color="primary"
                @click.stop="lectureScreenCreateModal = true"
              >
                <v-icon left dark> mdi-plus </v-icon>
                新增影片
              </v-btn>
            </v-col>
            <template v-for="(value, index) in data_.middle.screen">
              <v-col cols="12" md="7" class="text-center" :key="index">
                <v-card>
                  <v-card-title class="text-h6 justify-space-between">
                    {{ index + 1 }}
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          @click="
                            lectureScreenUpdateModal = true;
                            activeId = index;
                            activeItem = value;
                          "
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>編輯</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="removeScreen(index)">
                          <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>刪除</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-title>
                  <v-card-text>
                    <div v-if="value">
                      <video
                        class="w-50 d-block mx-auto"
                        v-if="value.type === 'video'"
                        :src="value.url"
                        :poster="value.poster"
                        controls
                        loop
                        playsinline
                        muted
                      />
                      <iframe
                        class="w-50 d-block mx-auto"
                        v-else-if="value.type === 'iframe'"
                        :src="value.url.replace('?autoplay=1','')"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      />
                      <div class="text-left pt-4">
                        <p class="mb-0">
                          影片名稱：
                          {{ value.name }}
                        </p>
                        <p class="mb-0">
                          顯示時間：
                          {{ `${value.start_at} ~ ${value.end_at}` }}
                        </p>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="`${queck_token}?path=lecture`">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
    <LectureScreenCreateModal
      :data_="data_"
      :dialog.sync="lectureScreenCreateModal"
      @createData="createScreen"
    ></LectureScreenCreateModal>
    <LectureScreenUpdateModal
      :dialog.sync="lectureScreenUpdateModal"
      :data_="data_"
      :data="activeItem"
      :id="activeId"
      @updateData="updateScreen"
    ></LectureScreenUpdateModal>
  </v-card>
</template>

<script>
import LectureScreenCreateModal from "@/components/modals/lecture/screen/create.vue";
import LectureScreenUpdateModal from "@/components/modals/lecture/screen/update.vue";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "screenLecturePage",
  props: {
    data: {},
  },
  components: {
    LectureScreenCreateModal,
    LectureScreenUpdateModal
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      lectureScreenCreateModal: false,
      lectureScreenUpdateModal: false,
      activeId: '',
      activeItem: ''
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    update() {
      const vm = this;

      vm.updateLecture({ id: vm.id, data: this.data_ }).then(() => {
        this.$emit("update");
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    createScreen(data) {
      let index = 0;
      if (this.data_.middle.screen == undefined) {
        index = 0;
        this.data_.middle.screen = [];
      } else {
        index = this.data_.middle.screen.length;
      }
      this.data_.middle.screen[index] = { ...data };
    },
    updateScreen(index,value){
      this.data_.middle.screen[index] = value
    },
    removeScreen(id) {
      this.data_.middle.screen = this.data_.middle.screen.filter(function (
        value,
        index
      ) {
        return index !== id;
      });
    },
    resetData() {
      this.$emit("update");
    },
    ...mapActions("updateLecture", ["updateLecture"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => {
        return state.exhibition.data.custom_backend ? `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}` : ''
      }
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>