<template>
  <div class="lectureScreenCreateModal">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 新增影片 </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <div v-if="screen.url || youtube" class="pt-4">
              <video
                class="w-50 d-block mx-auto"
                v-if="screen.type === 'video'"
                :src="screen.url"
                :poster="screen.poster"
                controls
                loop
                playsinline
              />
              <iframe
                class="w-50 d-block mx-auto"
                v-else-if="screen.type === 'iframe'"
                :src="youtube | youtubeUrl"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <v-col cols="12" class="pb-0">
                  <div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="影片名稱"
                      rules="required"
                    >
                      <v-text-field
                        v-model="screen.name"
                        :error-messages="errors"
                        type="text"
                        placeholder="影片名稱"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          影片名稱
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <v-select
                    v-model="screen.type"
                    :items="type"
                    item-text="name"
                    item-value="value"
                    label="內容類型"
                    outlined
                    dense
                  ></v-select>

                  <div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="縮圖連結"
                      rules="required"
                    >
                      <v-text-field
                        v-model="screen.poster"
                        :error-messages="errors"
                        type="text"
                        placeholder="縮圖連結"
                        outlined
                        dense
                        clearable
                        append-icon="mdi-folder-multiple-image"
                        @click:append="
                          SET_MEDIA_MODAL(true);
                          selected = 'poster';
                        "
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          縮圖連結
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div v-if="screen.type == 'video'">
                    <validation-provider
                      v-slot="{ errors }"
                      name="影片連結"
                      rules="required"
                    >
                      <v-text-field
                        v-model="screen.url"
                        :error-messages="errors"
                        type="text"
                        placeholder="影片連結"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          影片連結
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div v-if="screen.type == 'iframe'">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Youtube連結key"
                      rules="required"
                    >
                      <v-text-field
                        v-model="youtube"
                        :error-messages="errors"
                        type="text"
                        placeholder="Youtube連結key"
                        outlined
                        dense
                        clearable
                      >
                        <template v-slot:label>
                          <v-icon x-small color="error" class="align-middle"
                            >mdi-asterisk</v-icon
                          >
                          Youtube連結key
                        </template>
                      </v-text-field>
                    </validation-provider>
                    <v-switch
                      class="mt-0 ms-2"
                      v-model="youtubeAutoPlay"
                      inset
                      :label="'自動播放'"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-dialog
                    ref="strDatePickerDialog"
                    v-model="strDateModal"
                    :return-value.sync="strDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="日期"
                        rules="required"
                      >
                        <v-text-field
                          v-model="strDate"
                          append-icon="mdi-calendar"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            日期
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker v-model="strDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="strDateModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.strDatePickerDialog.save(strDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <!-- <v-col cols="12" class="py-0">
                  <v-range-slider
                    v-model="range"
                    :max="max"
                    :min="min"
                    step="1"
                    hide-details
                    class="align-center"
                  >
                    <template v-slot:prepend>
                      <v-text-field
                        :value="range[0] | fiveMin"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="text"
                        readonly
                        style="width: 40px"
                        @change="$set(range, 0, $event)"
                      ></v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                        :value="range[1] | fiveMin"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="text"
                        readonly
                        style="width: 40px"
                        @change="$set(range, 1, $event)"
                      ></v-text-field>
                    </template>
                  </v-range-slider>
                </v-col> -->
                <v-col cols="6" class="py-0">
                  <!-- <v-dialog
                    ref="strTimePickerdialog"
                    v-model="strTimeModal"
                    :return-value.sync="strTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="開始時間"
                        rules="required"
                      >
                        <v-text-field
                          v-model="strTime"
                          label="開始時間"
                          append-icon="mdi-clock-time-four-outline"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            開始時間
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-time-picker
                      v-if="strTimeModal"
                      v-model="strTime"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="strTimeModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.strTimePickerdialog.save(strTime);
                          endTime = '';
                        "
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog> -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="開始時間"
                    rules="required"
                  >
                    <v-text-field
                      label="開始時間"
                      v-model="strTime"
                      type="time"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <!-- <v-col cols="6" class="py-0">
                  <v-dialog
                    ref="endDatePickerDialog"
                    v-model="endDateModal"
                    :return-value.sync="endDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="結束日期"
                        rules="required"
                      >
                        <v-text-field
                          v-model="endDate"
                          append-icon="mdi-calendar"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            結束日期
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker v-model="endDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endDateModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endDatePickerDialog.save(endDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col> -->
                <v-col cols="6" class="py-0">
                  <!-- <v-dialog
                    ref="endTimePickerdialog"
                    v-model="endTimeModal"
                    :return-value.sync="endTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="結束時間"
                        rules="required"
                      >
                        <v-text-field
                          v-model="endTime"
                          label="結束時間"
                          append-icon="mdi-clock-time-four-outline"
                          :error-messages="errors"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <v-icon x-small color="error" class="align-middle"
                              >mdi-asterisk</v-icon
                            >
                            結束時間
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-time-picker
                      v-if="endTimeModal"
                      v-model="endTime"
                      :min="strTime"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endTimeModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endTimePickerdialog.save(endTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog> -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="結束時間"
                    rules="required"
                  >
                    <v-text-field
                      label="結束時間"
                      v-model="endTime"
                      type="time"
                      min="05:00"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="validateForm"
            color="primary"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            <v-icon left dark> mdi-content-save </v-icon>
            新增
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "lectureScreenCreateModal",
  props: {
    dialog: Boolean,
    data_: {},
  },
  data() {
    return {
      // min: '0',
      // max: (24*60 - 5)/5,
      // range: [0,(24*60 - 5)/5],
      type: [
        {
          name: "影片",
          value: "video",
        },
        {
          name: "Youtube影片",
          value: "iframe",
        },
      ],
      screen: {
        name: "",
        type: "video",
        url: "",
        poster: "",
        start_at: "",
        end_at: "",
      },
      youtube: "",
      youtubeAutoPlay: false,
      strDate: "",
      strTime: "",
      strDateModal: false,
      strTimeModal: false,
      endDate: "",
      endTime: "",
      endDateModal: false,
      endTimeModal: false,
      selected: "",
    };
  },
  methods: {
    async validateForm() {
      const valid = await this.$refs.observer.validate();
      let data = this.transformData();

      if (valid) {
        // 判斷開始時間 結束時間正確
        if (this.timeOrderCheck(data)) {
          this.SET_SNACKBAR({
            show: true,
            text: { text: `結束時間必須在開始時間之後`},
          });
          return;
        }
        // 判斷時間是否已經有影片
        if (this.timeRepeatGate(data)) {
          this.SET_SNACKBAR({
            show: true,
            text: { text: `該時段已經有排程了，請確認時間` },
          });
          return;
        }

        this.$emit("createData", data);
        this.isDialog = false;
        this.resetData();
      }
    },
    timeOrderCheck(data) {
      let gate = false
      let strTimeStamp = new Date(data.start_at).getTime();
      let endTimeStamp = new Date(data.end_at).getTime();

      if(strTimeStamp > endTimeStamp){
        gate = true
      }
      return gate
    },
    transformData() {
      const vm = this;

      vm.screen.start_at = `${vm.strDate} ${vm.strTime}`;
      vm.screen.end_at = `${vm.strDate} ${vm.endTime}`;

      if (vm.screen.type == "iframe") {
        vm.screen.url = this.$options.filters.youtubeUrl(
          this.youtube,
          this.youtubeAutoPlay
        );
      }

      return vm.screen;
    },
    youtubeHasAutoPlay(url) {
      let gate = url.indexOf("autoplay") > 0 ? true : false;
      return gate;
    },
    // 判斷時間有沒有重複 true 有重複 false 沒重複
    timeRepeatGate(data) {
      let strTimeStamp = new Date(data.start_at).getTime();
      let endTimeStamp = new Date(data.end_at).getTime();
      var gate = true;

      this.data_.middle.screen.forEach((element) => {
        let str = new Date(element.start_at).getTime();
        let end = new Date(element.end_at).getTime();

        // console.log(str,end,strTimeStamp,endTimeStamp)

        // 判斷時間不同疊

        // 沒在時間區間內
        let lessThanStr = !(str < strTimeStamp && strTimeStamp < end);
        let moreThanEnd = !(str < endTimeStamp && endTimeStamp < end);

        // 比時間大或小
        let afterStamp = end < strTimeStamp && end < endTimeStamp;
        let beforeStamp = str > strTimeStamp && str > endTimeStamp;

        // console.log('有無在區間內' + (lessThanStr && moreThanEnd))
        // console.log('是否在同一側' + (afterStamp || beforeStamp))
        // console.log('是否有通過' + ((lessThanStr && moreThanEnd) && (afterStamp || beforeStamp)))

        if (!(lessThanStr && moreThanEnd && (afterStamp || beforeStamp))) {
          gate = false;
        }
      });

      return !gate;
    },
    resetData() {
      (this.screen = {
        name: "",
        type: "video",
        url: "",
        poster: "",
        start_at: "",
        end_at: "",
      }),
        (this.strDate = ""),
        (this.strTime = ""),
        (this.endDate = ""),
        (this.endTime = ""),
        (this.youtubeAutoPlay = false);
      // 清空驗證錯誤訊息
      this.$refs.observer.reset();
    },
    ...mapMutations(["SET_MEDIA_MODAL", "SET_SNACKBAR"]),
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  watch: {
    "$store.state.media.selected": function () {
      switch (this.selected) {
        case "poster": {
          this.screen.poster = this.$store.state.media.selected.link;
        }
      }
    },
  },
};
</script>
