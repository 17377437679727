import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: colors.lightBlue.accent4,
        secondary: colors.lightBlue.accent2,
        accent: colors.blueGrey.darken4,
        danger: colors.red.dark1,
        info: colors.grey.lighten3,
        success: colors.green.accent3,
        warning: colors.amber.darken1,
      },
    },
  },
});
