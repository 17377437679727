<template>
  <div class="menuUpdateModal">
    <v-dialog v-model="isDialog" max-width="560" scrollable>
      <v-card>
        <v-card-title class="text-h5"> 編輯選單 </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="validateForm">
              <v-row class="py-8">
                <v-col cols="12">
                  <div
                    class="w-25 mx-auto mb-8"
                    v-if="menu.img || menu.imgActive"
                  >
                    <div class="image-square">
                      <img
                        class="object-fit-cover cursor-pointer"
                        :src="hover ? menu.imgActive : menu.img"
                        @mouseover="hover = true"
                        @mouseleave="hover = false"
                      />
                    </div>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="名稱"
                    rules="required"
                  >
                    <v-text-field
                      v-model="menu.name"
                      :error-messages="errors"
                      type="text"
                      placeholder="名稱"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        名稱
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="圖片"
                    rules="required"
                  >
                    <v-text-field
                      v-model="menu.img"
                      :error-messages="errors"
                      type="text"
                      placeholder="圖片"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'basic';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        圖片
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="圖片(滑入)"
                    rules="required"
                  >
                    <v-text-field
                      v-model="menu.imgActive"
                      :error-messages="errors"
                      type="text"
                      placeholder="圖片(滑入)"
                      outlined
                      dense
                      clearable
                      append-icon="mdi-folder-multiple-image"
                      @click:append="
                        SET_MEDIA_MODAL(true);
                        selected = 'active';
                      "
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        圖片(滑入)
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <v-select
                    v-model="type"
                    :items="linkType"
                    item-text="name"
                    item-value="value"
                    label="連結"
                    outlined
                    dense
                  ></v-select>

                  <validation-provider
                    v-slot="{ errors }"
                    name="自訂連結"
                    rules="required"
                    v-if="type == 'order'"
                  >
                    <v-text-field
                      v-model="menu.url"
                      :error-messages="errors"
                      type="text"
                      placeholder="自訂連結"
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:label>
                        <v-icon x-small color="error" class="align-middle"
                          >mdi-asterisk</v-icon
                        >
                        自訂連結
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="validateForm"
            color="primary"
            :loading="this.$store.state.partialLoding"
            :disabled="this.$store.state.partialLoding"
          >
            <v-icon left dark> mdi-content-save </v-icon>
            儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import LinkTypeModel from "@/models/linkType.js";

export default {
  name: "menuUpdateModal",
  props: {
    dialog: Boolean,
    data: {},
    id: {},
  },
  data() {
    return {
      menu: {
        name: "",
        img: "",
        imgActive: "",
        url: "",
        external: false,
      },
      hover: false,
      selected: "",
      type: "/lecture",
      linkType: LinkTypeModel,
    };
  },
  methods: {
    async validateForm() {
      const valid = await this.$refs.observer.validate();
      let data = this.transformData()
      
      if (valid) {
        this.$emit("updateData", this.id, data);
        this.isDialog = false;
        this.resetData();
      }
    },
    transformData() {
      const vm = this;

      if (vm.type == "order") {
        vm.menu.external = true;
      } else {
        vm.menu.external = false;
        vm.menu.url = vm.type;
      }

      return vm.menu;
    },
    resetData() {
      this.menu = {
        name: "",
        img: "",
        imgActive: "",
        url: "",
        external: false,
      };
      // 清空驗證錯誤訊息
      this.$refs.observer.reset();
    },
    initView() {
      if (this.data.external) {
        this.type = "order";
      } else {
        this.type = this.menu.url;
        this.menu.url = "";
      }
    },
    ...mapMutations(["SET_MEDIA_MODAL"]),
  },
  computed: {
    isDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  mounted() {},
  watch: {
    "$store.state.media.selected": function () {
      switch (this.selected) {
        case "basic": {
          this.menu.img = this.$store.state.media.selected.link;
          return;
        }
        case "active": {
          this.menu.imgActive = this.$store.state.media.selected.link;
          return;
        }
      }
    },
    data: function () {
      this.menu = JSON.parse(JSON.stringify(this.data));
      this.initView();
    },
  },
};
</script>
