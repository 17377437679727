<template>
  <v-card flat class="buttonALoginPage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <div class="w-25 mx-auto">
                <div class="image-square pt-29">
                  <img
                    class="object-fit-cover"
                    :src="
                      data_.loginBtn.hover
                        ? data_.loginBtn.imageActive
                        : data_.loginBtn.image
                    "
                    :alt="data_.loginBtn.name"
                    @mouseover="data_.loginBtn.hover = true"
                    @mouseleave="data_.loginBtn.hover = false"
                  />
                </div>
              </div>
              <p class="text--secondary caption pt-2">
                建議尺寸510 x 152像素，PNG格式
              </p>
              <validation-provider
                v-slot="{ errors }"
                name="登入按鈕"
                rules="required"
              >
                <v-text-field
                  v-model="data_.loginBtn.image"
                  :error-messages="errors"
                  type="text"
                  class="pt-8"
                  placeholder="Banner"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'login';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    登入按鈕
                  </template>
                </v-text-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="登入按鈕(滑入)"
                rules="required"
              >
                <v-text-field
                  v-model="data_.loginBtn.imageActive"
                  :error-messages="errors"
                  type="text"
                  placeholder="Banner"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'loginActive';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    登入按鈕(滑入)
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="7" class="text-center mt-4">
              <div class="w-25 mx-auto">
                <div class="image-square pt-29">
                  <img
                    class="object-fit-cover"
                    :src="
                      data_.resetBtn.hover
                        ? data_.resetBtn.imageActive
                        : data_.resetBtn.image
                    "
                    :alt="data_.resetBtn.name"
                    @mouseover="data_.resetBtn.hover = true"
                    @mouseleave="data_.resetBtn.hover = false"
                  />
                </div>
              </div>
              <p class="text--secondary caption pt-2">
                建議尺寸510 x 152像素，PNG格式
              </p>
              <validation-provider
                v-slot="{ errors }"
                name="驗證按鈕"
                rules="required"
              >
                <v-text-field
                  v-model="data_.resetBtn.image"
                  :error-messages="errors"
                  type="text"
                  class="pt-8"
                  placeholder="Banner"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'reset';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    驗證按鈕
                  </template>
                </v-text-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="驗證按鈕(滑入)"
                rules="required"
              >
                <v-text-field
                  v-model="data_.resetBtn.imageActive"
                  :error-messages="errors"
                  type="text"
                  placeholder="Banner"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'resetActive';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    驗證按鈕(滑入)
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="`${dev_url}#/login`">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "buttonALoginPage",
  props: {
    data: {},
  },
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      mediaModal: false,
      selected: "",
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    resetData() {
      this.$emit("update");
    },
    update() {
      const vm = this;

      vm.updateLogin({ id: vm.id, data: this.data_ }).then(() => {
        this.$emit("update");
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    selectedImage(image) {
      switch (this.selected) {
        case "login": {
          this.data_.loginBtn.image = image.link;
          return;
        }
        case "loginActive": {
          this.data_.loginBtn.imageActive = image.link;
          return;
        }
        case "reset": {
          this.data_.resetBtn.image = image.link;
          return;
        }
        case "resetActive": {
          this.data_.resetBtn.imageActive = image.link;
          return;
        }
      }
    },
    ...mapActions("updateLogin", ["updateLogin"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR","SET_MEDIA_MODAL"]),
  },
  computed: {
    ...mapState({
      dev_url: (state) => state.exhibition.data.frontend_dev_url,
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
    "$store.state.media.selected": function() {
      switch (this.selected) {
        case "login": {
          this.data_.loginBtn.image = this.$store.state.media.selected.link;
          return;
        }
        case "loginActive": {
          this.data_.loginBtn.imageActive = this.$store.state.media.selected.link;
          return;
        }
        case "reset": {
          this.data_.resetBtn.image = this.$store.state.media.selected.link;
          return;
        }
        case "resetActive": {
          this.data_.resetBtn.imageActive = this.$store.state.media.selected.link;
          return;
        }
      }
    }
  },
};
</script>

<style scoped>
.pt-29 {
  padding-top: 29%;
}
</style>