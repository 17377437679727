<template>
  <v-card flat class="unstartLecturePage">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent="validateForm">
          <v-row class="py-8 justify-center">
            <v-col cols="12" md="7" class="text-center">
              <p>banner</p>
              <div class="w-50 mx-auto">
                <div class="image-square pt-56">
                  <img
                    class="object-fit-cover"
                    :src="data_.unstart.mask_bg"
                    alt=""
                  />
                </div>
              </div>
              <p class="text--secondary caption pt-2">建議尺寸1200 × 675像素</p>
              <validation-provider
                v-slot="{ errors }"
                name="banner"
                rules="required"
              >
                <v-text-field
                  v-model="data_.unstart.mask_bg"
                  :error-messages="errors"
                  type="text"
                  class="pt-8 px-8"
                  placeholder="banner"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-folder-multiple-image"
                  @click:append="
                    SET_MEDIA_MODAL(true);
                    selected = 'unstart';
                  "
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    banner
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="7" class="text-center">
              <validation-provider
                v-slot="{ errors }"
                name="未開始顯示文字"
                rules="required"
              >
                <v-text-field
                  v-model="data_.unstart.mask_text"
                  :error-messages="errors"
                  type="text"
                  class="px-8"
                  placeholder="未開始顯示文字"
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    未開始顯示文字
                  </template>
                </v-text-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="未開始返回文字"
                rules="required"
              >
                <v-text-field
                  v-model="data_.unstart.back_text"
                  :error-messages="errors"
                  class="px-8"

                  type="text"
                  placeholder="未開始返回文字"
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:label>
                    <v-icon x-small color="error" class="align-middle"
                      >mdi-asterisk</v-icon
                    >
                    未開始返回文字
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn @click="resetData">
        <v-icon left dark> mdi-cached </v-icon>
        復原
      </v-btn>
      <v-btn target="_blank" :href="`${queck_token}?path=lecture`">
        <v-icon left dark> mdi-card-search </v-icon>
        預覽
      </v-btn>
      <v-btn
        @click="validateForm"
        color="primary"
        :loading="this.$store.state.partialLoding"
        :disabled="this.$store.state.partialLoding"
      >
        <v-icon left dark> mdi-content-save </v-icon>
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "unstartLecturePage",
  props: {
    data: {},
  },
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      data_: JSON.parse(JSON.stringify(this.data)),
      mediaModal: false,
      selected: "",
    };
  },
  methods: {
    async validateForm() {
      const vm = this;
      const valid = await this.$refs.observer.validate();

      if (valid) {
        vm.update();
      }
    },
    resetData() {
      this.$emit("update");
    },
    update() {
      const vm = this;

      vm.updateLecture({ id: vm.id, data: this.data_ }).then(() => {
        vm.SET_SUCCESS_SNACKBAR({
          show: true,
          text: "儲存成功",
        });
      });
    },
    ...mapActions("updateLecture", ["updateLecture"]),
    ...mapMutations(["SET_SUCCESS_SNACKBAR", "SET_MEDIA_MODAL"]),
  },
  computed: {
    ...mapState({
      queck_token: (state) => {
        return state.exhibition.data.custom_backend
          ? `${state.exhibition.data.frontend_dev_url}/#/quickAccess/${state.exhibition.data.custom_backend.passport}`
          : "";
      },
    }),
  },
  watch: {
    data() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
    "$store.state.media.selected": function () {
      switch (this.selected) {
        case "unstart": {
          this.data_.unstart.mask_bg = this.$store.state.media.selected.link;
        }
      }
    },
  },
};
</script>

<style scoped>
.pt-56 {
  padding-top: 56%;
}
</style>