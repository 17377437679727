var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mainScreenEntrancePage",attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"py-8"},[(_vm.data_.forum.type === 'video')?_c('video',{staticClass:"w-50 d-block mx-auto",attrs:{"src":_vm.data_.forum.url,"poster":_vm.data_.forum.poster,"controls":"","loop":"","playsinline":"","muted":""},domProps:{"muted":true}}):(_vm.data_.forum.type === 'image')?_c('v-img',{staticClass:"w-50 d-block mx-auto",attrs:{"src":_vm.data_.forum.poster}}):(_vm.data_.forum.type === 'iframe')?_c('iframe',{staticClass:"w-50 d-block mx-auto h-300",attrs:{"src":_vm._f("youtubeUrl")(_vm.youtube),"allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e()],1),_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"py-8 justify-center"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"7"}},[_c('v-select',{attrs:{"items":_vm.type,"item-text":"name","item-value":"value","label":"內容類型","outlined":"","dense":""},model:{value:(_vm.data_.forum.type),callback:function ($$v) {_vm.$set(_vm.data_.forum, "type", $$v)},expression:"data_.forum.type"}})],1),_c('div',{staticClass:"w-100"}),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"7"}},[_c('validation-provider',{attrs:{"name":_vm.data_.forum.type == 'image' ? '圖片連結' : '預覽圖片連結',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":_vm.data_.forum.type == 'image' ? '圖片連結' : '預覽圖片連結',"outlined":"","dense":"","clearable":"","append-icon":"mdi-folder-multiple-image"},on:{"click:append":function($event){_vm.SET_MEDIA_MODAL(true);
                  _vm.selected = 'main';}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" "+_vm._s(_vm.data_.forum.type == "image" ? "圖片連結" : "預覽圖片連結")+" ")]},proxy:true}],null,true),model:{value:(_vm.data_.forum.poster),callback:function ($$v) {_vm.$set(_vm.data_.forum, "poster", $$v)},expression:"data_.forum.poster"}})]}}])}),(_vm.data_.forum.type == 'image')?_c('v-select',{attrs:{"items":_vm.linkTypeData,"item-text":"name","item-value":"value","label":"連結","outlined":"","dense":""},model:{value:(_vm.linkType),callback:function ($$v) {_vm.linkType=$$v},expression:"linkType"}}):_vm._e(),(_vm.linkType == 'order' && _vm.data_.forum.type == 'image')?_c('validation-provider',{attrs:{"name":"自訂連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"自訂連結","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 自訂連結 ")]},proxy:true}],null,true),model:{value:(_vm.data_.forum.url),callback:function ($$v) {_vm.$set(_vm.data_.forum, "url", $$v)},expression:"data_.forum.url"}})]}}],null,false,1782310695)}):_vm._e()],1),(_vm.data_.forum.type == 'iframe')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"7"}},[_c('validation-provider',{attrs:{"name":"Youtube連結key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"Youtube連結key","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" Youtube連結key ")]},proxy:true}],null,true),model:{value:(_vm.youtube),callback:function ($$v) {_vm.youtube=$$v},expression:"youtube"}})]}}],null,false,1102041169)}),_c('v-switch',{staticClass:"mt-0 ms-2",attrs:{"inset":"","label":'自動播放'},model:{value:(_vm.youtubeAutoPlay),callback:function ($$v) {_vm.youtubeAutoPlay=$$v},expression:"youtubeAutoPlay"}})],1):_vm._e(),(_vm.data_.forum.type == 'video')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"7"}},[_c('validation-provider',{attrs:{"name":"影片連結","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","placeholder":"影片連結","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"align-middle",attrs:{"x-small":"","color":"error"}},[_vm._v("mdi-asterisk")]),_vm._v(" 影片連結 ")]},proxy:true}],null,true),model:{value:(_vm.data_.forum.url),callback:function ($$v) {_vm.$set(_vm.data_.forum, "url", $$v)},expression:"data_.forum.url"}})]}}],null,false,3268395879)})],1):_vm._e()],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.resetData}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cached ")]),_vm._v(" 復原 ")],1),(_vm.queck_token)?_c('v-btn',{attrs:{"target":"_blank","href":_vm.queck_token}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-card-search ")]),_vm._v(" 預覽 ")],1):_vm._e(),_c('v-btn',{attrs:{"color":"primary","loading":this.$store.state.partialLoding,"disabled":this.$store.state.partialLoding},on:{"click":_vm.validateForm}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" 儲存 ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }